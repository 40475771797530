import axios from "axios";
import {isLocalTestMode} from "./fetcher";

const {REACT_APP_NOTIFICATION_URL_TEST, REACT_APP_NOTIFICATION_URL} = process.env;

function _getBaseURL(url) {
  let currentPath = window.location.href;

  if (currentPath.includes("https://") && url.includes("http://")) {
    url = "https://" + new URL(currentPath).hostname + "/api/";
  } else {
  }
  return url;
}


const fetcher_notification_test = axios.create({
  baseURL: _getBaseURL(REACT_APP_NOTIFICATION_URL_TEST)
});
const fetcher_notification = axios.create({
  baseURL: _getBaseURL(REACT_APP_NOTIFICATION_URL)
});


export function getFetcherNotification() {
  return isLocalTestMode() ? fetcher_notification_test : fetcher_notification;
}
