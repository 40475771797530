import React, {Fragment, useEffect, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {MOBYDICK_ADMIN_TEST_LIST_URL} from "../../constants/constants";
import neverLandUtils from "../../utils/NeverLandUtils";
import {RiMovieFill} from "react-icons/ri";
import ExternalLinkModal from "./ExternalLinkModal";

const MainNoticeModal = ({setShowAddModal}) => {
  const [open, setOpen] = useState(true);
  const [textData, setTextData] = useState('');
  const [externalLink, setExternalLink] = useState()
  const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname.includes('192.168.1.');

  const closeModal = () => {
    setOpen(false);
    setShowAddModal(false);
  }

  const saveDataToLocal = () => {
    neverLandUtils.commons.save('noti_modal', {isOpen: false});
    closeModal();
  }

  useEffect(() => {
    getTextData();
  }, []);

  const getTextData = async () => {
    let targetUrl = MOBYDICK_ADMIN_TEST_LIST_URL;
    if (isLocalhost) {
      targetUrl = "http://teamneverland.com" + MOBYDICK_ADMIN_TEST_LIST_URL+"?time="+new Date().getTime();
    } else {
      targetUrl = targetUrl + "?" + new Date().getTime();
    }

    try {
      const response = await fetch(targetUrl);
      const data = await response.text();
      setTextData(data);
    } catch (err) {
      console.error("데이터를 읽어오는데 실패하였습니다.", err);
    }
  };

  function popupLink(link) {
    setExternalLink(link);
    return true;
  }

  return (
      <>
      {externalLink ? <ExternalLinkModal title={"업데이트 내용보기"} externalLink={externalLink} setExternalLink={setExternalLink}/>
        :
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={closeModal}>
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
          </Transition.Child>

          <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                    className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all m-10 w-96">

                  <div>
                    <p>{textData.split('\n').map((line, index) =>{

                      if(line.includes("<link>")){
                        return (<span className="inline-block align-middle"
                            onClick={()=>{popupLink(line.replace(/<\/?link>/g, ''))}}>
                          <RiMovieFill className="cursor-pointer h-6 w-auto text-red-600 ml-2"/>
                        </span>);
                      }
                      return (
                          <span key={index}>
                         {index>0&&<br/>} {line}
                        </span>
                      )}
                    )}</p>
                  </div>

                  <div className="flex justify-end">
                    <button className="m-3 font-semibold bg-blue-600 text-white py-2 px-5 rounded-lg text-md "
                            onClick={saveDataToLocal}
                    >
                      확인
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      }
      </>
  )
}
export default MainNoticeModal;
