import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getFetcher} from "../../utils/fetcher";
import Sidebar from "../../components/Sidebar";
import {BOARD_DELETE_API, GET_BOARD_DETAIL_API} from "../../constants/api_constants";
import {accountInfo} from "../../constants/constants";
import {BOARD_LIST_PAGE, BOARD_WRITE_PAGE} from "../../constants/page_constants";
import {getBoardInfo} from "../../constants/board_constants";
import NeverLandUtils from "../../utils/NeverLandUtils";
import {toast} from "react-toastify";
import {RiArrowRightSLine} from "react-icons/ri";

const BoardDetailPage = () => {
  const {boardId} = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [writer, setWriter] = useState('');
  const [reportingDate, setReportingDate] = useState('');
  const [isMyPost, setIsMyPost] = useState(true);
  const location = useLocation();

  useEffect(() => {
    getPostInfo();
  }, [])

  let cate_id = new URLSearchParams(location.search).get('cate_id')

  const getPostInfo = () => {
    getFetcher().get(accountInfo.makeGetRequest(GET_BOARD_DETAIL_API) + `&boardId=${boardId}`)
        .then((res) => {
          const type = res.data.header.type;
          if (type === 'success') {
            const {title, content, nickName, datetime, isMine} = res.data.result.boardContentDetail;
            if (isMine !== undefined) {
              setIsMyPost(isMine);
            }
            setTitle(title);
            setContent(content);
            setWriter(nickName);
            setReportingDate(NeverLandUtils.utils.getDisplayDateText(datetime, true));
          } else {
            console.log('데이터 읽어오기 실패')
          }
        })
        .catch((err) => toast.error(err));
  }

  const getCateUrlByCateId = (cate_id) => {
    return BOARD_LIST_PAGE + "/" + cate_id;
  }
  const navigateToBoardList = () => {
    navigate(getCateUrlByCateId(cate_id));
  }

  const navigateToEditPage = () => {
    navigate(`${BOARD_WRITE_PAGE}?cate_id=${cate_id}&board_id=${boardId}`);
  }
  const deletePost = () => {
    getFetcher().get(accountInfo.makeGetRequest(BOARD_DELETE_API) + `&boardId=${boardId}`)
        .then(res => {
          const {type, message} = res.data.header;
          if (type === 'success') {
            toast.success(message);
            navigateToBoardList();
          } else {
            toast.error(message);
          }
        });
  }

  return (
      <>
        <Sidebar/>
        <div className="lg:pl-64">
          <div className="sm:px-6 lg:px-8">
            <div className="mx-auto max-w-6xl px-6 pt-20">
              <div className="sm:px-6 lg:px-8 border border-gray-300 rounded-lg">
                <div className="p-3">
                  {/*콘텐츠 영역*/}
                  <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                    <div className="min-w-full py-5 align-middle px-6 lg:px-8">

                      <button className="flex flex-row items-center" onClick={() => navigateToBoardList()}>
                        <h1 className="text-sm leading-6 text-indigo-500">{getBoardInfo(cate_id).title}</h1>
                        <RiArrowRightSLine className="h-5 w-5 text-indigo-500" aria-hidden="true"/>
                      </button>

                      <div>
                        <p className="mt-3 mb-2 block text-2xl font-semibold">
                          {title}
                        </p>
                      </div>

                      <div className="flex items-baseline">
                        <p className="text-sm font-semibold"> {writer} </p>
                        <p className="ml-3 text-xs text-gray-500"> {reportingDate} </p>
                      </div>

                      <div className="border-b py-3"></div>

                      <div className="divide-y divide-black min-h-80 overflow-auto">
                        <p className="text-lg font-semibold mt-4">
                          {content}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*하단 버튼 영역*/}
              <div className="mt-5 flex flex-row justify-between">
                <div className="flex flex-row">
                  <button
                      onClick={() => navigateToBoardList()}
                      className="block rounded-md px-3 py-2 text-sm ring-1 ring-inset ring-gray-300 font-semibold hover:bg-gray-100">
                    목록으로
                  </button>
                </div>
                {/*TODO: 작성자 또는 관리자만 버튼보이게 - 백엔드 isMine 받아서 구현*/}
                {(isMyPost || accountInfo.isAdmin()) &&
                    <div className="flex flex-row">
                      <button
                          onClick={navigateToEditPage}
                          className="block rounded-md px-3 py-2 text-sm ring-1 ring-inset ring-gray-300 font-semibold hover:bg-gray-100"
                      >
                        수정
                      </button>
                      <button
                          onClick={deletePost}
                          className="ml-2 block rounded-md px-3 py-2 text-sm ring-1 ring-inset ring-gray-300 font-semibold text-red-500 hover:bg-gray-100"
                      >
                        삭제
                      </button>
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
      </>
  )
}
export default BoardDetailPage;