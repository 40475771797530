import Sidebar from "../components/Sidebar";
import MyPageHeader from "../components/Header/MyPageHeader";
import React, {useState} from "react";
import neverLandUtils from "../utils/NeverLandUtils";
import {accountInfo} from "../constants/constants";
import {useNavigate} from "react-router-dom";
import {updateMyInfo} from "../utils/NeverLandRestFULInterface";

const MySettingPage = () => {
  const navigate = useNavigate();

  const [alarmSettings, setAlarmSettings] = useState([
    {
      title: '초기값',
      options:
          [
            {name: "메인화면 조회조건 테이블 펼치기", isOn: false, key: 'on_show_filter_table'}
          ]
    },
    {
      title: '자동화',
      options:
          [
            {name: "요청(카트,의뢰)시 페이지 넘어가기", isOn: true, key: 'on_route_page'},
            {name: "요청(카트,의뢰)시 선택한 경기 초기화", isOn: true, key: 'on_refresh_select_game'},
          ]
    },
    {
      title: '알람',
      options:
          [
            {name: "지점 승인 알람", isOn: false, key: 'on_store_confirm'},
            {name: "승인 요청 알람ⓢ", isOn: false, key: 'on_confirm_request'},
            {name: "요청 등록 알람ⓢ", isOn: false, key: 'on_request_register'},
            {name: "의뢰 출력 알람", isOn: false, key: 'on_request_print'},
            {name: "경기 결과 알람", isOn: false, key: 'on_game_result'}
          ]
    },
    {
      title: '화면',
      options:
          [
            {name: "게임 정보 큰 폰트 적용", isOn: false, key: 'use_large_font'},
            {name: "게임 정보 돋보기 모드 사용", isOn: false, key: 'use_magnifier_mode'},
          ]
    },
    {
      title: '기타설정',
      options:
          [
            {name: "음소거", isOn: false, key: 'on_mute'},
            {name: "간편모드", isOn: true, key: 'use_simple_mode'},
          ]
    }
  ]);

  const toggleAlarmSetting = (name, boolean) => {
    let updatedAlarmSettings = alarmSettings;
    let alarmSettingIndex;
    let optionsIndex = -1;
    let tempIndex;

    alarmSettingIndex = updatedAlarmSettings.findIndex(list => {
      if (-1 !== (tempIndex = list.options.findIndex(opt => opt.name === name))) {
        optionsIndex = tempIndex;
        return true;
      }
      return false;
    });

    if (alarmSettingIndex !== -1 && optionsIndex !== -1) {
      // updatedAlarmSettings[alarmSettingIndex].options[optionsIndex].isOn = boolean;

      let key = updatedAlarmSettings[alarmSettingIndex].options[optionsIndex].key;
      accountInfo.getUserConfig().setOptionOn(key, boolean);

      setAlarmSettings([...updatedAlarmSettings]);
    }
  }

  const saveMySetting = () => {
    // 설정 저장

  }

  return (
      <>
        <div>
          <Sidebar/>
          <div className="lg:pl-64">
            <MyPageHeader/>
            <main>
              <div className="lg:mt-12 divide-y divide-gray-300">
                {alarmSettings.map((category) => {
                  return (
                      <div
                          className="grid max-w-full grid-cols-1 gap-x-8 gap-y-10 px-4 py-6 sm:py-16 sm:px-6 md:grid-cols-3 lg:px-8">

                        <div className="sm:ml-10">
                          <h2 className="text-xl font-semibold leading-7 text-gray-900">{category.title}</h2>
                        </div>

                        <div className="md:col-span-2">
                          <div className="gap-x-6 gap-y-8 sm:max-w-xl">
                            {category.options.map((option) => {
                              if (option.name.includes("ⓢ") && !accountInfo.isStore()) {
                                return <></>;
                              }
                              let isOptionOn = accountInfo.getUserConfig().isOn(option.key, option.isOn);
                              return (
                                  <div
                                      className="flex flex-row whitespace-nowrap items-center mb-10 justify-between sm:pr-40">
                                    <label htmlFor="optionName"
                                           className="block text-sm sm:text-lg font-semibold leading-6 text-gray-900">
                                      {option.name.replace("ⓢ", "")}
                                    </label>
                                    <div className="flex flex-row justify-center ring-2 ring-gray-900 rounded-xl">
                                      <button
                                          onClick={() => toggleAlarmSetting(option.name, true)}
                                          type="button"
                                          className={neverLandUtils.classNames(isOptionOn ? "bg-gray-900 text-white" : "bg-white text-gray-900"
                                              , "whitespace-nowrap rounded-xl px-2 py-1 text-xs font-semibold shadow-sm")}
                                      >
                                        ON
                                      </button>
                                      <button
                                          onClick={() => toggleAlarmSetting(option.name, false)}
                                          type="button"
                                          className={neverLandUtils.classNames(isOptionOn ? "bg-white text-gray-900" : "bg-gray-900 text-white"
                                              , "whitespace-nowrap rounded-xl px-1.5 py-1 text-xs font-semibold shadow-sm")}
                                      >
                                        OFF
                                      </button>
                                    </div>
                                  </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                  )
                })}
              </div>

              <div className=" flex justify-end ">
                <button className="text-gray-500 mr-16 mb-5 text-sm"
                        onClick={() => {
                          updateMyInfo(navigate)
                        }}
                >
                  ※ 로그인 갱신
                </button>
              </div>

              {/*<div className="flex justify-center">*/}
              {/*  <button*/}
              {/*      onClick={saveMySetting}*/}
              {/*      className={neverLandUtils.classNames("bg-blue-900 hover:bg-blue-800", "rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm")}*/}
              {/*  >*/}
              {/*    설정 저장*/}
              {/*  </button>*/}
              {/*</div>*/}

            </main>
          </div>
        </div>
      </>
  )
}
export default MySettingPage;