import {Link} from "react-router-dom";
import {HEADERS} from "../../constants/component_data";


const MyStoreHeader = () => {
  return (
      <div className="top-0 w-full border-b bg-stone-900 shadow-md">
        <nav className="flex overflow-x-auto py-4">
          <ul
              role="list"
              className="flex min-w-full flex-none gap-x-10 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8"
          >
            {HEADERS.storeHeaderNavigation.map((item) => (
                <li key={item.name}>
                  <Link to={item.link} className={item.current ? 'text-indigo-400' : ' hover:text-indigo-400'}>
                    {item.name.replace("!","")}
                  </Link>
                </li>
            ))}
          </ul>
        </nav>
      </div>
  )
}
export default MyStoreHeader;