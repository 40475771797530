import {RiLoginBoxLine, RiLogoutBoxLine} from "react-icons/ri";
import {Link} from "react-router-dom";
import {LOGIN_PAGE} from "../constants/page_constants";
import {accountInfo} from "../constants/constants";

const SideBarLoginButton = ({logOut}) => {
  return (
      <>
        {/* 로그인 상태에 따라 표시 */}
        {accountInfo.isLogin() ?
            <li className="mt-auto mb-3 flex flex-row justify-center">
              <button
                  onClick={logOut}
                  className="whitespace-nowrap w-full flex gap-x-3 rounded-md p-2 pl-8 text-sm font-semibold leading-6 text-indigo-200 hover:bg-gray-700 hover:text-white"
              >
                <RiLogoutBoxLine className="ml-5 h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"/>
                로그아웃
              </button>
            </li>
            :
            <li className="mt-auto mb-3 whitespace-nowrap ">
              <Link
                  to={LOGIN_PAGE}
                  className="group w-full -mx-2 flex gap-x-3 rounded-md p-2 pl-11 text-sm font-semibold leading-6 text-indigo-200 hover:bg-gray-700 hover:text-white"
              >
                <RiLoginBoxLine className="ml-5 h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"/>
                로그인
              </Link>
            </li>
        }
      </>
  )
}
export default SideBarLoginButton;