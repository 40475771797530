const board_cate_list = [
  {cateId: '0x001', title: "공지사항", text: "새로운 기능 추가, 이벤트 등의 소식을 알려드립니다.", categoryName: "announcement", needAdmin: true},
  {cateId: '0x002', title: "1:1 문의 게시판", text: "본인이 작성한 글만 볼수있습니다. 개인적인 문의나 요청을 작성해주세요.", categoryName: "private-question", needAdmin: false},
];


export function getBoardInfo(cateId) {

  for (let info of board_cate_list) {
    if (info.cateId === cateId)
      return info;
  }
  return {};
}
