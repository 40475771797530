import neverLandUtils from "./NeverLandUtils";
import {accountInfo} from "../constants/constants";


class NeverLandDirectMessage {
  // sendDirectMessageInterface = null;
  onMessageRefreshedHandlers = [];

  onMessageRefreshed(b) {
    if (this.onMessageRefreshedHandlers === null)
      return;
    if (this.onMessageRefreshedHandlers.length === 0)
      return;
    for (let h of this.onMessageRefreshedHandlers) {
      h(b);
    }
  }

  appendNewMessage(senderName, messageContents, sendDateTime) {
    mainData.list.push({
      from: senderName,
      to: accountInfo.accountNickName(),
      message: messageContents,
      sendtime: sendDateTime,
      readtime: null
    });
    this.refreshMessages(true);
  }

  unreadMessageCount() {
    // 읽지않은 메세지
    return mainData.unreadMessageCount;
  }

  hasUnreadMessage() {
    return mainData.unreadMessageCount > 0;
  }

  refreshMessages(b) {
    let _unreadMessageCount = 0;
    mainData.list.forEach((data) => {
      if (data.readtime === null) _unreadMessageCount++
    });
    mainData.unreadMessageCount = _unreadMessageCount;
    neverLandUtils.commons.save("directMessageData", mainData);
    this.onMessageRefreshed(b);
  }

  setMessageToAllRead() {
    mainData.list.forEach((data) => {
      if (data.readtime === null) data.readtime = new Date()
    });
    this.refreshMessages();
  }

  removeMessage(data) {
    mainData.list = mainData.list.filter((d) => d !== data);
    this.refreshMessages();
  }

  getDirectMessageList() {
    return mainData.list;
  }

  setMessageToRead(data) {
    mainData.list.forEach((d) => {
      if (data === d && d.readtime === null) d.readtime = new Date()
    });
    this.refreshMessages();
  }

  // sendMessageToCustomers(selectedCustomerList, messageContents) {
  //   let _targetIds = [];
  //   let sendDateTime = neverLandUtils.utils.getDisplayTextByDate(new Date());
  //
  //   for (let userInfo of selectedCustomerList) {
  //     if (userInfo.userId === accountInfo.accountId()) {
  //       this.appendNewMessage(accountInfo.accountNickName(), messageContents, sendDateTime);
  //       continue;
  //     }
  //     _targetIds.push(userInfo.userId);
  //   }
  //   if (_targetIds.length > 0) {
  //     let jsonSendCustomerMessage = {
  //       targetIds: _targetIds, messageContents: messageContents, sendDateTime: sendDateTime
  //     };
  //     if (this.sendDirectMessageInterface) {
  //       this.sendDirectMessageInterface(jsonSendCustomerMessage);
  //     }
  //   }
  // }

  addOnMessageHandler(_onChanged) {
    this.onMessageRefreshedHandlers.push(_onChanged);
  }

  removeOnMessageHandler(_onChanged) {
    this.onMessageRefreshedHandlers = this.onMessageRefreshedHandlers.filter((i) => i !== _onChanged);
  }
}

let mainData = neverLandUtils.commons.load("directMessageData") || {
  unreadMessageCount: 0,
  list: [],
};
const neverLandDirectMessage = new NeverLandDirectMessage();
export default neverLandDirectMessage;