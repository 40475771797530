import React, {useState} from "react";
import BaseTicketListPage from "./base/BaseTicketListPage";
import Sidebar from "../../components/Sidebar";
import AdminPageHeader from "../../components/Header/AdminPageHeader";


const AdminRequestListPage = () => {
  const [isRequesting, setIsRequesting] = useState(false);

  const actionsJson = [
    {name: '출력', color: 'green', onClick: null},
    {name: '보류', color: 'gray', onClick: null},
    {name: '취소', color: 'red', onClick: null},
  ]
  const ticketState = 'all▼!cart';
  const ticketOrderType = {view: ["stateDetail", "register", "charger-memo-only"], options: ["fixed"]};
  const fixedFilter = {searchCondition: ['admin']};
  const requestState = [
    {name: "의뢰상태 전체", code: "all"},
    {name: "의뢰", code: "order"},
    {name: "출력", code: "request_proto"},
    {name: "보류", code: "*hold"},
    {name: "취소", code: "*abort"},
    {name: "요청", code: "request_ex▼*!done▼*!abort"},
    // {name: "요청승인", code: "request_ex▼*done"},
    {name: "요청취소", code: "request_ex▼*abort"},
  ]

  return (
      <>
        <Sidebar/>
        <div>
          <div className="xl:pl-64">
            <AdminPageHeader/>
          </div>

          <div className="xl:mt-14">
            <BaseTicketListPage listKey="admin_request" isRequesting={isRequesting} isShowFilter={true}
                                actionsJson={actionsJson}
                                ticketOrderType={ticketOrderType}
                                ticketState={ticketState} fixedFilter={fixedFilter} requestState={requestState}/>
          </div>
        </div>
      </>
  )
}
export default AdminRequestListPage;