import {RiQuestionFill} from "react-icons/ri";
import React, {Fragment, useState} from "react";
import {Transition} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/20/solid";


// let test_json={
//   title:"제목",
//   message:" 1. 입력칸에 의뢰할 내용 입력 .\n" +
//       "                      기입 순서 : 경기번호 > 승무패/핸디캡/언오버 > 금액\n" +
//       "                      ex) 13승 19패 22승 5만 or  13승19패22패5만 (띄어쓰기 무관)\n" +
//       "\n" +
//       "                      2. 조합 사이에는 줄바꿈(enter)\n" +
//       "\n" +
//       "                      3. 입력 후 재적용 클릭\n" +
//       "\n" +
//       "                      4. 만들어진 문자의뢰 내용 확인 후 선택\n" +
//       "\n" +
//       "                      5. 카트담기 / 의뢰하기(출력) 선택"
// };

const HelperPopupModal = ({message}) => {
  const [explainPopUp, setExplainPopUp] = useState(false);
  return (
      <div>
        <RiQuestionFill className="h-6 w-auto text-gray-400 ml-1 cursor-pointer" onClick={() => setExplainPopUp(true)}/>
        {explainPopUp && <_explainPopUp message={message} setExplainPopUp={setExplainPopUp}/>}
      </div>
  );
}

export default HelperPopupModal;


const _explainPopUp = ({message, setExplainPopUp}) => {

  const messageLines = message.message.split('\n');

  return (
      <>
        <div className="inset-0 flex items-end justify-center sm:items-start">
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
            <Transition
                show={true}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
              <div
                  className="absolute pointer-events-auto w-full max-w-sm rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
                <div
                    className="w-full max-w-sm rounded-lg bg-white shadow-md ring-1 ring-gray-600 ring-opacity-5">
                  <div className="p-2">
                    <div className="flex items-start">
                      <h2 className="ml-3 w-0 flex-1 pt-0.5 mt-1 font-semibold text-center">
                        {message.title}
                      </h2>

                      <div className="ml-4 flex flex-shrink-0" onClick={() => setExplainPopUp(false)}>
                        <button
                            type="button"
                            className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          <span className="sr-only">Close</span>
                          <XMarkIcon className="h-5 w-5"/>
                        </button>
                      </div>
                    </div>

                    <div className="max-h-[15rem] overflow-y-auto">
                      <div className="m-5 text-sm">
                        {messageLines.map((line) => {
                              let _line = line.trim();

                              if (_line.includes("※ 주의")) {
                                return (
                                    <div className="text-red-700 bg-yellow-100 font-semibold">
                                      {_line.length > 0 ? _line : (<br/>)}
                                    </div>
                                )
                              }
                              return (
                                  <div>
                                    {_line.length > 0 ? _line : (<br/>)}
                                  </div>
                              )
                            }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </>
  )
}
