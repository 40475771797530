import neverLandGameData from "../../utils/NeverLandGameData";
import {useEffect, useState} from "react";
import React from 'react';
import soccerIcon from "../../assets/images/icon_soccer.png"
import baseballIcon from "../../assets/images/icon_baseball.png"
import volleyballIcon from "../../assets/images/icon_volleyball.png"
import basketballIcon from "../../assets/images/icon_basketball.png"
import neverLandUtils from "../../utils/NeverLandUtils";
import {toast} from "react-toastify";
import {accountInfo} from "../../constants/constants";

export const getGameLogoImgURL = (category) => {
  if (category === 'SC') return soccerIcon;
  if (category === 'BK') return basketballIcon;
  if (category === 'VL') return volleyballIcon;
  if (category === 'BS') return baseballIcon;
}

const GameCardLargeFont = ({setMouseOverData}) => {
  const [index, setIndex] = useState(0);
  const updateUI = () => {
    setIndex(prevState => prevState + 1);
  }
  const handleOnChanged = () => {
    updateUI();
  };

  useEffect(() => {
    neverLandGameData.addOnChangedHandler(handleOnChanged);
    neverLandGameData.loadGameDataList("game_card");
    return () => {
      neverLandGameData.removeOnChangedHandler(handleOnChanged);
    }
  }, [])

  const _toggleSelectItem = (parentGameInfo, thisGameData, id, key) => {
    // 조건부로 버튼 안먹히게
    if (parentGameInfo.state !== "발매중")
      return;
    if (key === "d" && ((thisGameData.extraText !== 'H' || thisGameData.draw === '0.00') && thisGameData.extraText !== '무'
        && thisGameData.extraText !== '⑤'
        && thisGameData.extraText !== '①'))
      return;
    if (neverLandGameData.toggleSelectItem(id, key)) {
      return;
    }
    toast.warn('10경기 이상 선택할 수 없습니다.');
  }

  let use_magnifier_mode = accountInfo.getUserConfig().isOn('use_magnifier_mode', false);
  return (
      <>
        <div>
          {/*<ul role="list"*/}
          {/*    className="grid grid-cols-1 gap-6 md:grid-cols-1 xl-only:grid-cols-2 2xl-only:grid-cols-3">*/}
          <ul role="list" className="flex flex-wrap justify-center items-center gap-2">
            {neverLandGameData.getFilteredGameData()?.map((game, index) => {
              // 데이터 유효성 검사
              if (game.closingDate === "-") {
                return null;
              }

              return (
                  <React.Fragment key={index}>
                    <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white text-center shadow overflow-hidden  max-w-[22rem] min-w-[18rem]">
                      <div className="flex flex-1 flex-row justify-between px-3 py-1.5 text-lg items-center">
                        <div className="flex flex-row items-center">
                          <img src={getGameLogoImgURL(game.category)}
                               className={neverLandUtils.classNames(game.category === 'BS' ? "h-6 w-6" : "h-5 w-5", "mx-2")}/>
                          <p className="font-semibold">{game.leagueName}</p>
                        </div>
                        <div>
                          <p className="text-gray-500">{neverLandGameData.formatDate(game.closingDate)}</p>
                        </div>
                        <div className="flex items-center">
                          <span
                              className={`inline-flex items-center rounded-full px-2 py-1 font-medium 
                              ${game.state === "발매중" ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"} `}
                          >
                            {game.state === "null" ? "준비중" : game.state}
                          </span>
                        </div>
                      </div>

                      <div className="text-md flex flex-1 flex-row justify-center py-3">
                        <table className="table-fixed w-full">
                          <thead>
                          <tr className="text-2xl whitespace-nowrap">
                            <th scope="col" className="w-1/12">{''}</th>
                            <th scope="col" className="w-3/12">{game.homeTeam}</th>
                            <th scope="col" className="w-3/12">vs</th>
                            <th scope="col" className="w-3/12">{game.awayTeam}</th>
                          </tr>
                          </thead>

                          <tbody>
                          {game.data?.map((buttonData) => {
                            if (neverLandGameData.isFilteredGameAllotData(buttonData.extraText)) {
                              return null;
                            }
                            if (game.state === "null") return null;
                            return (
                                <tr key={buttonData.allotId}>
                                  {/* 경기번호 */}
                                  <td>
                                    <div
                                        className="ml-2.5 text-lg mt-1 flex flex-col items-center justify-center font-semibold">
                                      <p>{buttonData.gameNo.replace("ⓢ", "")}</p>
                                      <p className="text-orange-600 font-semibold ml-1">{buttonData.gameNo.includes("ⓢ") ? "ⓢ" : ""}</p>
                                    </div>
                                  </td>

                                  {/* 선택버튼 */}
                                  <td className="w-full"
                                      onMouseOver={() => {
                                        if (use_magnifier_mode && setMouseOverData)
                                          setMouseOverData({
                                            displayTitle: game.homeTeam + " vs " + game.awayTeam,
                                            dataType: 'win',
                                            displayData: buttonData
                                          });
                                      }}
                                      onMouseLeave={() => {
                                        if (use_magnifier_mode && setMouseOverData)
                                          setMouseOverData(null);
                                      }}
                                  >
                                    <div className="flex">
                                      {buttonData.win === "0.00" ?
                                          <div
                                              className='border-green-100 text-purple-300 h-12 w-full sm:w-24 ml-2 border rounded-lg px-5 mt-1 flex items-center justify-center'>
                                            -
                                          </div>
                                          :
                                          <button
                                              onClick={() => _toggleSelectItem(game, buttonData, buttonData.allotId, 'w')}>
                                            <div
                                                className={neverLandUtils.classNames(
                                                    neverLandGameData.isSelected(buttonData.allotId, 'w') ? 'bg-neutral-400 border-neutral-400' : '',
                                                    'h-12 w-full sm:w-24 ml-2 border border-gray-300 rounded-lg px-5 mt-1 flex items-center justify-center'
                                                )}
                                            >
                                              {buttonData.extraText === "U/O" ? "U" : "승"} <br/> {buttonData.win}
                                            </div>
                                          </button>
                                      }
                                    </div>
                                  </td>

                                  <td className="w-full"
                                      onMouseOver={() => {
                                        if (use_magnifier_mode && setMouseOverData)
                                          setMouseOverData({
                                            displayTitle: game.homeTeam + " vs " + game.awayTeam,
                                            displayData: buttonData,
                                            dataType: 'draw'
                                          });
                                      }}
                                      onMouseLeave={() => {
                                        if (use_magnifier_mode && setMouseOverData)
                                          setMouseOverData(null);
                                      }
                                      }
                                  >
                                    <div className="flex">
                                      {(buttonData.draw === '0.00' && buttonData.extra === '0.0') ?
                                          <div
                                              className='border-sky-100 text-blue-300 h-12 w-full sm:w-24 ml-2 border rounded-lg px-8 mt-1 flex items-center justify-center'>-</div>
                                          : ((buttonData.extraText == null || buttonData.extraText === "null") ? "-" :
                                                  <button
                                                      onClick={() => _toggleSelectItem(game, buttonData, buttonData.allotId, 'd')}
                                                  >
                                                    <div className={neverLandUtils.classNames(
                                                        neverLandGameData.isSelected(buttonData.allotId, 'd') ? 'bg-neutral-400 border-neutral-400' : '',
                                                        ((buttonData.extraText !== 'H' || buttonData.draw === '0.00') && buttonData.extraText !== '무'
                                                            && buttonData.extraText !== '⑤' && buttonData.extraText !== '①') ?
                                                            'cursor-not-allowed text-sky-800 border-sky-300 bg-sky-100' : 'border-gray-300',
                                                        'h-12 w-full sm:w-24 ml-2 border rounded-lg px-5 mt-1 flex items-center justify-center'
                                                    )}>
                                                      {(buttonData.extraText === '무' || buttonData.extraText === '①' || buttonData.extraText === '⑤') ?
                                                          <p>{buttonData.extraText}<br/> {buttonData.draw}</p>
                                                          : buttonData.extraText === 'H' && buttonData.draw !== '0.00' ?
                                                              <p className="whitespace-nowrap">{"무 " + buttonData.draw}<br/>
                                                                {buttonData.extraText + (buttonData.extra > 0 ? "+" : " ") + buttonData.extra}
                                                              </p>
                                                              :
                                                              <p>{buttonData.extraText}<br/> {((buttonData.extraText === 'H' && buttonData.extra > 0) ? "+" : "") + buttonData.extra}
                                                              </p>
                                                      }
                                                    </div>
                                                  </button>
                                          )}
                                    </div>
                                  </td>

                                  <td className="w-full"
                                      onMouseOver={() => {
                                        if (use_magnifier_mode && setMouseOverData)
                                          setMouseOverData({
                                            displayTitle: game.homeTeam + " vs " + game.awayTeam,
                                            displayData: buttonData,
                                            dataType: 'lose'
                                          });
                                      }}
                                      onMouseLeave={() => {
                                        if (use_magnifier_mode && setMouseOverData)
                                          setMouseOverData(null);
                                      }}
                                  >
                                    <div className="flex">
                                      {buttonData.lose === "0.00" ?
                                          <div
                                              className='border-orange-100 text-red-300 h-12 sm:w-16 ml-1 border rounded-lg px-5 mt-1 flex items-center justify-center'> - </div> :
                                          <button
                                              onClick={() => _toggleSelectItem(game, buttonData, buttonData.allotId, 'l')}>
                                            <div
                                                className={neverLandUtils.classNames(
                                                    neverLandGameData.isSelected(buttonData.allotId, 'l') ? 'bg-neutral-400 border-neutral-400' : '',
                                                    'h-12 w-full sm:w-24 ml-2 border border-gray-300 rounded-lg px-5 mt-1 flex items-center justify-center'
                                                )}>
                                              {buttonData.extraText === "U/O" ? "O" : "패"} <br/> {buttonData.lose}
                                            </div>
                                          </button>
                                      }
                                    </div>
                                  </td>
                                </tr>
                            );
                          })}
                          </tbody>
                        </table>
                      </div>
                    </li>
                  </React.Fragment>
              );
            })}
          </ul>
        </div>
      </>
  )
}
export default GameCardLargeFont;
