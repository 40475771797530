import {EnvelopeIcon, LockClosedIcon, UserIcon} from "@heroicons/react/16/solid";
import {LOGIN_PAGE, MAIN_PAGE, SIGNUP_PAGE} from "../constants/page_constants";
import {Link, useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {getFetcher} from "../utils/fetcher";
import {
  CHECK_EMAIL_API, CREATE_ACCOUNT_AND_AUTO_LOGIN_API,
  CREATE_ACCOUNT_API,
  GET_SUBSCRIPTION_TERM_API
} from "../constants/api_constants";
import neverLandUtils from "../utils/NeverLandUtils";
import {toast} from "react-toastify";
import {ModalHandler, OpenModalHandle} from "../components/Modal/Generic/ModalHandler";
import {RiArrowRightSLine, RiEyeFill, RiEyeOffFill} from "react-icons/ri";
import {accountInfo, getLogoImage} from "../constants/constants";
import loading_img from "../assets/images/icon-loading.png";

const SignUpPage = () => {
  const navigate = useNavigate();
  // 가입 데이터
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [nickname, setNickname] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  // 이용약관
  const [agreementDate001, setAgreementDate001] = useState(null);
  const [termContents001, setTermContents001] = useState(null);
  const [isShowTerm001, setIsShowTerm001] = useState(false);
  const [agreementDate002, setAgreementDate002] = useState(null);
  const [termContents002, setTermContents002] = useState(null);
  const [isShowTerm002, setIsShowTerm002] = useState(false);
  // 유효성 검사
  const [isEmailConfirm, setIsEmailConfirm] = useState(false);
  const [isPasswordConfirm, setIsPasswordConfirm] = useState(false);
  // 비밀번호 보이기
  const [showPasswordFields, setShowPasswordFields] = useState({prePassword: false, passwordConfirm: false});
  // 자동 로그인
  const [autoLogin, setAutoLogin] = useState(true);

  const [isRequest, setIsRequest] = useState(false); // 로딩중

  const OpenModal = OpenModalHandle.create();

  function currentTime() {
    // 현재시간 형식 변환
    return new Date().toISOString();
  }

  // <-- 이메일 -->
  const checkEmail = () => {
    // 이메일 입력, 형식 체크
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
    if (!email) {
      OpenModal.openWarningModal('이메일 확인', '이메일을 입력해주세요.');
      return;
    }
    if (emailPattern.test(email) === false) {
      OpenModal.openWarningModal('이메일 확인', '이메일 형식이 올바르지 않습니다.');
      return;
    }
    // 이메일 중복 체크
    getFetcher().get(CHECK_EMAIL_API + `?email=${email.toLowerCase()}`)
        .then(res => {
          const type = res.data.header.type;
          if (type === 'fail') {
            OpenModal.openWarningModal('이메일 확인', '이미 사용중인 이메일 주소 입니다.');
          } else if (type === 'success') {
            OpenModal.openConfirmModal('이메일 확인', '사용가능한 이메일 주소 입니다.');
            setIsEmailConfirm(true);
          }
        })
        .catch((err) => console.log(err))
  }

  // <-- 비밀번호 입력 -->
  const onChangePassword = (newPassword, newPasswordConfirm) => {
    if (newPassword !== password) {
      setPassword(newPassword);
    }
    if (newPasswordConfirm !== passwordConfirm) {
      setPasswordConfirm(newPasswordConfirm);
    }
    if (newPassword === newPasswordConfirm) {
      setIsPasswordConfirm(true);
    } else {
      setIsPasswordConfirm(false);
    }
  }
  // 비밀번호 보이기
  const toggleVisiblePassword = (fieldName) => {
    setShowPasswordFields(prevState => ({
      ...prevState, [fieldName]: !prevState[fieldName]
    }));
  }

  // <-- 이용약관 동의 -->
  const agreementList = [
    {
      text: "1. 이용약관 동의 (필수)",
      code: '0x01',
      contents: function () {
        return termContents001;
      },
      toggle: function () {
        setIsShowTerm001(prev => !prev)
      },
      isShowTerm: function () {
        return isShowTerm001;
      }
    },
    {
      text: "2. 개인정보 수집.이용 및 제3자 제공 동의 (필수)",
      code: '0x02',
      contents: function () {
        return termContents002;
      },
      toggle: function () {
        setIsShowTerm002(prev => !prev)
      },
      isShowTerm: function () {
        return isShowTerm002;
      }
    },
  ];

  const updateTermCode = (code, contents) => {
    if (code === '0x01') {
      setTermContents001(contents);
    }
    if (code === '0x02') {
      setTermContents002(contents);
    }
  }
  const getSubscriptionTerm = (agreement, onload) => {
    // 약관 내용 가져오기
    getFetcher().get(GET_SUBSCRIPTION_TERM_API + `?dataTime=${currentTime()}&termcode=${agreement.code}`)
        .then(res => {
              const {type, message} = res.data.header;
              const result = res.data.result;
              if (type === 'fail') {
                OpenModal.openWarningModal('조회 실패', message);
              } else if (type === 'success') {
                onload(agreement.code, result.subscriptionTerm.contents);
                agreement.toggle();
              }
            }
        )
        .catch(err => console.log(err));
  }
  const isAgreedSubscriptionTerm = (code) => {
    if (code === '0x01') {
      return agreementDate001 !== null;
    }
    if (code === '0x02') {
      return agreementDate002 !== null;
    }
  }
  const handleAgreement = (code, opinion) => {
    // 동의 || 동의안함 눌렀을 때
    let _currentTime = currentTime();
    let closingDelay = 300;
    if (code === '0x01') {
      if (opinion === 'disagree') {
        setAgreementDate001(null);
      } else if (opinion === 'agree') {
        setTimeout(function () {
          setIsShowTerm001(false);
          setAgreementDate001(_currentTime);
        }, closingDelay);
      }
    }
    if (code === '0x02') {
      if (opinion === 'disagree') {
        setAgreementDate002(null);
      } else if (opinion === 'agree') {
        setTimeout(function () {
          setIsShowTerm002(false);
          setAgreementDate002(_currentTime);
        }, closingDelay);
      }
    }
  }

  const encryptPassword = neverLandUtils.security.encrypt(password);  // 비밀번호 암호화
  const createAccount = () => {
    // <-- 회원가입 -->
    setIsRequest(true);
    // 계정 생성
    getFetcher().post(CREATE_ACCOUNT_API, {
      userName: name,
      nickName: nickname,
      email: email.toLowerCase(),
      password: encryptPassword,
      agreementDate001: agreementDate001,
      agreementDate002: agreementDate002,
    })
        .then(res => {
          const {type, message} = res.data.header;
          if (type === 'success') {
            setIsRequest(false);
            navigate(LOGIN_PAGE);
            toast.success(message);
          }
        })
        .catch((err) => {
          setIsRequest(false);
          console.log(err);
          OpenModal.openWarningModal('회원가입 실패', '회원가입에 실패하였습니다.');
        })
  }

  const createAccountAndAutoLogin = () => {
    setIsRequest(true);
    // 계정 생성 후 자동 로그인
    getFetcher().post(CREATE_ACCOUNT_AND_AUTO_LOGIN_API, {
      userName: name,
      nickName: nickname,
      email: email,
      password: encryptPassword,
      agreementDate001: agreementDate001,
      agreementDate002: agreementDate002,
    }).then(res => {
      const {type, message} = res.data.header;
      if (type === 'success') {
        let userInfo = res.data.result.user;
        accountInfo.login(userInfo);
        setIsRequest(false);
        navigate(MAIN_PAGE);
        toast.success(message);
      } else {
        setIsRequest(false);
        toast.warn(message);
      }
    })
        .catch((err) => {
          setIsRequest(false);
          console.log(err);
          OpenModal.openWarningModal('회원가입 실패', '회원가입에 실패하였습니다.');
        })
  }

  const onClickCreateAccountButton = () => {
    if (!name || !nickname || !password || !passwordConfirm) {
      OpenModal.openWarningModal('정보 입력', '빈칸을 모두 입력해주세요.');
      return;
    }
    if (!isEmailConfirm) {
      OpenModal.openWarningModal('이메일 중복 검사', '이메일 중복을 확인해주세요.');
      return;
    }
    if (!isPasswordConfirm)
        // 비밀번호 일치 여부 -> 인풋창 밑에 경고 텍스트 뜨기 때문에 별도로 모달 안띄움
      return;
    if (!agreementDate001 || !agreementDate002) {
      OpenModal.openWarningModal('약관 동의', '모든 이용약관 동의는 필수입니다.');
      return;
    }
    if (autoLogin) {
      // 자동 로그인 여부 확인
      createAccountAndAutoLogin();
    } else {
      createAccount();
    }
  }


  return (
      <div className="flex min-h-screen flex-1 flex-col justify-center items-center px-6 py-12 lg:px-8 sm:my-0 mb-44">
        <ModalHandler path={SIGNUP_PAGE} initModalHandle={OpenModal}/>
        {isRequest && <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-center items-center">
            <img src={loading_img} className="h-16" alt="로딩 중 이미지"/>
            <p className="text-indigo-900 font-semibold text-xl mt-10"> 회원가입 중입니다. 잠시만 기다려주세요.</p>
          </div>
        </div>
        }
        <div className="flex flex-col sm:mx-auto sm:w-full sm:max-w-sm">
          <p className="mb-3 text-center text-sm text-gray-500">
            이미 계정이 있으신가요? {' '}
            <Link to={LOGIN_PAGE} className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
              로그인 하러가기
            </Link>
          </p>

          <div className="flex flex-row justify-center items-center my-3">
            <img src={getLogoImage('whaleLogoBlue')} alt="WhaleLogo" className="mr-5 h-12 w-auto"/>
            <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-900">
              회원가입
            </h2>
          </div>
        </div>

        {/*  회원가입 인풋 */}
        <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="flex flex-row mt-1">
            <input type="checkbox"
                   checked={autoLogin}
                   onClick={() => setAutoLogin(!autoLogin)}
                   className="mr-2"
            />
            <p className="text-md">회원가입 완료 후 자동 로그인</p>
          </div>

          <div className="space-y-6">
            <div>
              <div className="relative mt-3 rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <UserIcon className="h-5 w-5 text-gray-900" aria-hidden="true"/>
                </div>
                <input
                    onChange={(e) => setName(e.target.value.trim())}
                    className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="성명"
                />
              </div>

              <div className="relative mt-5 rounded-md shadow-sm flex flex-row">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <EnvelopeIcon className="h-5 w-5 text-gray-900" aria-hidden="true"/>
                </div>
                <input
                    onChange={(e) => setEmail(e.target.value.trim())}
                    className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="이메일"
                    // 이메일 중복체크 후 인풋창 비활성화
                    disabled={isEmailConfirm}
                />
                <button
                    className={neverLandUtils.classNames(isEmailConfirm ? "bg-gray-400" : "bg-gray-900",
                        "ml-3 w-40 justify-center rounded-md  py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    )}
                    onClick={checkEmail}
                    disabled={isEmailConfirm}
                >
                  중복검사
                </button>
              </div>

              <div className="relative mt-5 rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <UserIcon className="h-5 w-5 text-gray-900" aria-hidden="true"/>
                </div>
                <input
                    onChange={(e) => setNickname(e.target.value.trim())}
                    className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="닉네임"
                />
              </div>

              <div className="relative mt-5 rounded-md shadow-sm flex flex-row items-center">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-gray-900" aria-hidden="true"/>
                </div>
                <input
                    onChange={e => onChangePassword(e.target.value.trim(), passwordConfirm)} //e => setPassword(e.target.value)
                    type={showPasswordFields.password ? "text" : "password"}
                    className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="비밀번호"
                />
                <div className="absolute px-3 text-gray-400 right-0">
                  {showPasswordFields.password ?
                      <RiEyeFill className="h-5 w-auto"
                                 onClick={() => toggleVisiblePassword('password')}/> :
                      <RiEyeOffFill className="h-5 w-auto"
                                    onClick={() => toggleVisiblePassword('password')}/>
                  }
                </div>
              </div>

              <div className="relative mt-5 rounded-md shadow-sm flex flex-row items-center">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-gray-900" aria-hidden="true"/>
                </div>
                <input
                    onChange={e => onChangePassword(password, e.target.value.trim())}
                    type={showPasswordFields.passwordConfirm ? "text" : "password"}
                    className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="비밀번호 확인"
                />
                <div className="absolute px-3 text-gray-400 right-0">
                  {showPasswordFields.passwordConfirm ?
                      <RiEyeFill className="h-5 w-auto"
                                 onClick={() => toggleVisiblePassword('passwordConfirm')}/> :
                      <RiEyeOffFill className="h-5 w-auto"
                                    onClick={() => toggleVisiblePassword('passwordConfirm')}/>
                  }
                </div>
              </div>
              {password && passwordConfirm && (
                  <p className={`mt-2 text-sm ${isPasswordConfirm ? "text-green-600" : "text-red-600"}`}>
                    {isPasswordConfirm ? "비밀번호가 일치합니다." : "비밀번호가 일치하지 않습니다."}
                  </p>
              )}
            </div>

            {/* 약관동의 */}
            {agreementList.map((agreement) => (
                <div className="relative flex flex-col" key={agreement.code}>
                  <div className="flex flex-row justify-between" onClick={() => {
                    if (!agreement.isShowTerm()) {
                      getSubscriptionTerm(agreement, updateTermCode);
                    } else {
                      agreement.toggle();
                    }
                  }}>
                    <div className="flex h-6 items-center">
                      <input
                          checked={isAgreedSubscriptionTerm(agreement.code)}
                          readOnly={true}
                          aria-describedby="comments-description"
                          type="radio"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label htmlFor="comments" className="ml-2 text-sm leading-6 font-medium text-gray-900">
                        {agreement.text}
                      </label>
                    </div>
                    <RiArrowRightSLine className="w-5 h-5" aria-hidden="true"/>
                  </div>
                  {agreement.isShowTerm() &&
                      <div>
                        <div className="my-1.5 p-2 text-xs h-52 overflow-y-scroll border-2 border-indigo-200">
                          {agreement.contents()?.split("\\n").map((line) => (<>{line}<br/></>))}
                        </div>
                        <div className="mt-1 mb-1 ml-2 flex flex-row">
                          <div className="flex flex-row items-center">
                            <input
                                onClick={() => handleAgreement(agreement.code, "agree")}
                                checked={isAgreedSubscriptionTerm(agreement.code)}
                                readOnly={true}
                                type="radio"
                                name={agreement.code}
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <p className="ml-1 text-xs font-medium text-gray-900"> 동의 </p>
                          </div>

                          <div className="flex flex-row items-center">
                            <input
                                onClick={() => handleAgreement(agreement.code, "disagree")}
                                type="radio"
                                name={agreement.code}
                                className="ml-5 h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <p className="ml-1 text-xs font-medium text-gray-900"> 동의하지 않음 </p>
                          </div>
                        </div>
                      </div>
                  }
                </div>))
            }
            <div>
              <button
                  className="flex w-full justify-center rounded-md bg-gray-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  type="submit"
                  onClick={onClickCreateAccountButton}
              >
                가입하기
              </button>
            </div>
          </div>
        </div>
      </div>
  )
}
export default SignUpPage;