import {
  PencilSquareIcon, QuestionMarkCircleIcon,
  IdentificationIcon, RocketLaunchIcon, ShoppingCartIcon, PrinterIcon
} from '@heroicons/react/24/solid'
import {
  ADMIN_CALCULATE_PAGE,
  ADMIN_REPORT_PAGE, ADMIN_REQUEST_MANAGEMENT_PAGE,
  ALL_CUSTOMER_MANAGEMENT_PAGE, ALL_STORE_LIST_MANAGEMENT_PAGE,
  BOARD_LIST_PAGE, DEVELOPER_MANAGEMENT_PAGE, DEVELOPER_MONITORING_PAGE, DEVELOPMENT_HISTORY_PAGE,
  FAQ_PAGE, GAME_MANAGEMENT_PAGE, GAME_ROUND_MANAGEMENT_PAGE,
  MAIN_PAGE,
  MY_CART_PAGE,
  MY_INFO_PAGE, MY_SETTING_PAGE,
  MY_STORE_INFO_PAGE,
  MY_STORE_LIST_PAGE,
  REQUEST_LIST_PAGE, STAFF_MANAGEMENT_PAGE,
  STORE_CUSTOMER_MANAGEMENT_PAGE,
  STORE_MANAGEMENT_PAGE, STORE_REPORT_PAGE,
  STORE_REQUEST_LIST_PAGE
} from "./page_constants";
import userGroupIcon from "@heroicons/react/24/solid/esm/UserGroupIcon";
import {
  RiCodeSSlashLine,
  RiCommunityFill,
  RiFileList3Fill,
  RiMagicFill,
  RiMegaphoneFill,
  RiVerifiedBadgeFill
} from "react-icons/ri";

// <-- 헤더 --> //
export const HEADERS = {
  adminHeaderNavigation: [
    {name: '※집계', link: ADMIN_REPORT_PAGE, current: false},
    {name: '※경기관리', link: GAME_MANAGEMENT_PAGE, current: false},
    // {name: '※의뢰관리', link: ADMIN_REQUEST_MANAGEMENT_PAGE, current: false},
    {name: '※지점-지점리스트', link: ALL_STORE_LIST_MANAGEMENT_PAGE, current: false},
    {name: '※지점-지점관리', link: STORE_MANAGEMENT_PAGE, current: false},
    {name: '회차관리', link: GAME_ROUND_MANAGEMENT_PAGE, current: false},
    {name: '고객리스트', link: ALL_CUSTOMER_MANAGEMENT_PAGE, current: false},
    {name: '개발 이력', link: DEVELOPMENT_HISTORY_PAGE, current: false},
    {name: '정산ⓢ', link: ADMIN_CALCULATE_PAGE, current: false},
  ],

  myInfoHeaderNavigation: [
    {name: '개인정보', link: MY_INFO_PAGE, current: false},
    {name: '내지점관리', link: MY_STORE_LIST_PAGE, current: false, notifyKey: 'response_confirm_of_my_store'},
    {name: '내설정', link: MY_SETTING_PAGE, current: false},
  ],

  storeHeaderNavigation: [
    {name: '지점정보', link: MY_STORE_INFO_PAGE, current: false},
    {name: '직원관리!', link: STAFF_MANAGEMENT_PAGE, current: false},
  ]
};

function GetLinks(main, subNavigations) {
  let links = main;
  for (let navi of subNavigations) {
    links += "|" + navi.link;
  }
  return links;
}

// <-- 티켓 매수 선택 옵션 --> //
export const SHEET_QUANTITY = [
  {name: '1 매', value: 1},
  {name: '2 매', value: 2},
  {name: '3 매', value: 3},
  {name: '4 매', value: 4},
  {name: '5 매', value: 5},
  {name: '6 매', value: 6},
  {name: '7 매', value: 7},
  {name: '8 매', value: 8},
  {name: '9 매', value: 9},
  {name: '10 매', value: 10},
]

// <-- 폴더 수 선택 옵션 --> //
export const FOLDER_OPTION = [
  {id: 'all', name: '폴더'},
  {id: 1, name: '1 폴더'},
  {id: 2, name: '2 폴더'},
  {id: 3, name: '3 폴더'},
  {id: 4, name: '4 폴더'},
  {id: 5, name: '5 폴더'},
  {id: 6, name: '6 폴더'},
  {id: 7, name: '7 폴더'},
  {id: 8, name: '8 폴더'},
  {id: 9, name: '9 폴더'},
  {id: 10, name: '10 폴더'},
]

// <-- 지점 레벨 --> //
export const storeLevelOption = [
  // 지점 레벨
  {id: 1, name: "훈련병"},
  {id: 2, name: "장군"},
]
// <-- 검색 조건 --> //
export const searchTypeOption = [
  {name: '포함'},
  {name: '정확히 일치'},
]

export const pageSizeSelectOption = [
  {name: '10개씩', size:10},
  {name: '15개씩', size:15},
  {name: '30개씩', size:30},
  {name: '50개씩', size:50},
]

// <-- 사이드바 --> //
// * : 로그인 해야 보이는 메뉴, ⓒ : 일반유저,  ⓢ : 점주 페이지, ⓐ : 관리자 페이지
export const SIDEBAR_UPPER_MENU = [
  {name: '게임 정보', link: MAIN_PAGE, icon: RocketLaunchIcon, current: false},
  {name: '카트 보기*', link: MY_CART_PAGE, icon: ShoppingCartIcon, current: false},
  {name: '의뢰 내역*ⓒ', link: REQUEST_LIST_PAGE, icon: PrinterIcon, current: false, notifyKey: 'order_list'},
  {
    name: '마이 페이지*',
    link: GetLinks(MY_INFO_PAGE, HEADERS.myInfoHeaderNavigation),
    icon: IdentificationIcon,
    current: false,
    notifyKey: 'response_confirm_of_my_store'
  },
]
export const SIDEBAR_AUTHORITY_MENU = [
  {
    name: '받은 의뢰*ⓢ',
    link: STORE_REQUEST_LIST_PAGE,
    icon: PrinterIcon,
    current: false,
    notifyKey: 'order_list_for_store'
  },
  {name: '지점 정보*ⓢ', link: MY_STORE_INFO_PAGE + "|" + STAFF_MANAGEMENT_PAGE, icon: RiCommunityFill, current: false},
  {
    name: '고객 관리*ⓢ',
    link: STORE_CUSTOMER_MANAGEMENT_PAGE,
    icon: userGroupIcon,
    current: false,
    notifyKey: 'request_confirm_for_store_owner'
  },
  {name: '고객 가계부*ⓢ', link: STORE_REPORT_PAGE, icon: RiFileList3Fill, current: false},
  {
    name: '관리자 페이지*ⓐ',
    link: GetLinks(ADMIN_REPORT_PAGE, HEADERS.adminHeaderNavigation),
    icon: RiVerifiedBadgeFill,
    current: false
  },
]
export const SIDEBAR_DEVELOPER_MENU = [
  {
    name: '개발 관리*ⓓ',
    link: DEVELOPER_MANAGEMENT_PAGE,
    icon: RiCodeSSlashLine,
    current: false,
  },
  {
    name: '모니터링*ⓓ',
    link: DEVELOPER_MONITORING_PAGE,
    icon: RiCodeSSlashLine,
    current: false,
  },
]
export const SIDEBAR_LOWER_MENU = [
  {name: '공지사항', link: BOARD_LIST_PAGE, parameter: '0x001', icon: RiMegaphoneFill, current: false},
  {name: '자주 묻는 질문', link: FAQ_PAGE, icon: QuestionMarkCircleIcon, current: false},
  {name: '1:1 문의 게시판*', link: BOARD_LIST_PAGE, parameter: '0x002', icon: PencilSquareIcon, current: false},
]


export function getRegisteredMenus() {
  // App.js 에 링크들 넘겨주기
  return SIDEBAR_UPPER_MENU.concat(SIDEBAR_AUTHORITY_MENU).concat(SIDEBAR_LOWER_MENU).concat(HEADERS.adminHeaderNavigation).concat(HEADERS.myInfoHeaderNavigation).concat(HEADERS.storeHeaderNavigation);
}

const {REACT_APP_IS_MOBILE_PAGE} = process.env;

export function isMobilePage() {
  // env 에서 주석 지우면 모바일버전임
  // return REACT_APP_IS_MOBILE_PAGE !== 'none' && !REACT_APP_IS_MOBILE_PAGE.includes('//');
  return false;
}

// <-- 메인페이지 필터조건 테이블 --> //
export const FILTER_TABLE_DATA = [
  {
    title: '발매상태',
    options: [{id: 'status_all', value: '전체'}, {id: 'status_before', value: '발매전'},
      {id: 'status_in_progress', value: '발매중'}, {id: 'status_closed', value: '발매종료'},
      {id: 'status_finished', value: '게임종료'}]
  },
  {
    title: '게임유형',
    options: [{id: 'type_all', value: '전체'}, {id: 'type_normal', value: '일반'}, {id: 'type_handicap', value: '핸디캡'},
      {id: 'type_over_under', value: '언더오버'}, {id: 'type_win_loss_1', value: isMobilePage() ? '야1구' : '승1패'},
      {id: 'type_win_loss_5', value: isMobilePage() ? '농5구' : '승5패'}]
  },
  {
    title: '종목',
    options: [{id: 'category_all', value: '전체'}, {id: 'category_soccer', value: '축구'}, {
      id: 'category_basketball',
      value: '농구'
    },
      {id: 'category_baseball', value: '야구'}, {id: 'category_volleyball', value: '배구'}]
  },
  {
    title: '리그',
    options: [
      {id: 'league_all', value: '전체'},]
  },
  {
    title: '마감일',
    options: [{id: 'closingDate_all', value: '전체'},]
  },
];

// <-- 티켓 금액 버튼 --> //
export const MONEY_BUTTON_DATA = [
  {name: "+ 1백", value: 100,},
  {name: "+ 1천", value: 1000,},
  {name: "+ 1만", value: 10000,},
]

export const MONEY_BUTTON_DATA_SIMPLE = [
  {name: "+ 1만", value: 10000,},
  {name: "+ 5만", value: 50000,},
  {name: "+ 10만", value: 100000,},
]


// <-- 고객가계부, 집계 페이지 테이블 헤더 --> //
export const REPORT_UPPER_TABLE_HEADER = [
  {name: "총건"}, {name: "총투표액"}, {name: "적특건"}, {name: "적특투표액"}, {name: "유효투표액"},
  {name: "당첨건"}, {name: "당첨액"}, {name: "차액"}, {name: "낙첨건"},
  {name: "낙첨액"}, {name: "진행건"}, {name: "진행액"},
]
export const REPORT_LOWER_TABLE_HEADER = [
  {name: "의뢰번호", icon: true},
  {name: "처리시각", icon: true},
  // {name: "시작시간", icon: true},
  {name: "지점", icon: true},
  {name: "고객닉네임", icon: true},
  {name: "투표금액", icon: true},
  {name: "선택배당", icon: true},
  {name: "결과배당", icon: true},
  {name: "당첨액", icon: true},
  {name: "상태", icon: true},
  {name: "메모", icon: true},
  {name: "폴더", icon: true},
];

// <-- 정산페이지 테이블 헤더 --> //
export const CALCULATE_UPPER_TABLE_HEADER = [
  {name: "지점수"}, {name: "총투표액"}, {name: "유효투표액"},
  {name: "당첨액"}, {name: "수수료"}, {name: "차액"}, {name: "진행건"}
]
export const CALCULATE_LOWER_TABLE_HEADER = [
  {name: "지점/회차", key: 'storeName/roundCode'},
  {name: "총투표액", key: 'totalAmount'},
  {name: "유효투표액", key: 'validBettingAmount'},
  {name: "당첨액", key: 'totalWinningAmount'},
  {name: "수수료", key: 'commissionAmount'},
  {name: "차액", key: 'differenceAmount'},
  {name: "*진행건", key: 'totalOngoing'}
]

// <-- 의뢰관리 테이블 헤더 --> //
export const TICKET_TABLE_HEADER_DEFAULT = [
  {name: "접수번호"},
  {name: "고객닉네임"},
  {name: "의뢰상태"},
  {name: "의뢰시각"},
  {name: "방문예정"},
  {name: "처리시각"},
  {name: "최종경기시작"},
  {name: "예상적중배당"},
  {name: "총투표액"},
  {name: "예상적중금"},
  {name: "메모"},
  {name: "복사"},
  {name: "폴더"},
];

export const TICKET_TABLE_HEADER_SHORT = [
  {name: "선택"},
  {name: "고객닉네임"},
  {name: "의뢰상태"},
  {name: "의뢰시각"},
  {name: "최종경기시작"},
  {name: "예상적중배당"},
  {name: "총투표액"},
  {name: "예상적중금"},
  {name: "폴더"},
];


// <-- 회차관리 테이블 헤더 --> //
export const GAME_ROUND_MANAGEMENT_TABLE_HEADER = [
  {name: "번호"},
  {name: "연도"},
  {name: "회차"},
  {name: "상태"},
  {name: "동기화"},
];

// <-- 경기관리 테이블 헤더 --> //
export const GAME_MANAGEMENT_TABLE_HEADER = [
  {name: "번호"},
  {name: "종목/대회"},
  {name: "경기일시"},
  {name: "경기유형"},
  {name: "경기상태"},
  {name: "홈팀 VS 원정팀"},
  {name: "승배당", useOrder: true},
  {name: "무배당/기준", useOrder: true},
  {name: "원정배당", useOrder: true},
  {name: "홈스코어"},
  {name: "원정스코어"},
  {name: ""},
];