import {useEffect, useState} from "react";
import * as XLSX from 'xlsx/xlsx.mjs';

const ExcelLoadPage = () => {
  const [excelData, setExcelData] = useState([]);
  const { REACT_APP_EXCEL_DATA_URL } = process.env;


  function _getBaseURL(_url){
    let currentUrl = window.location.href;

    if(currentUrl.includes('.com')) {
      const newUrl = new URL(_url);
      newUrl.hostname = currentUrl.replace('http://', '').replace('https://', '').split("/")[0]; // 도메인 부분만 대체
      _url = newUrl.href;
    }
    if(currentUrl.includes("https://")&&_url.includes("http://")){
      return _url.replace("http://","https://");
    }

    return _url;
  }

  useEffect(() => {
     setTimeout(()=>loadFile(_getBaseURL(REACT_APP_EXCEL_DATA_URL)),300)
  }, []);

  const loadFile = (fileUrl)=>{
    let modified;
    fetch(fileUrl)
        .then(response => {
          modified = response.headers.get('last-modified'); // 마지막 수정시간
          return response.blob();
        })
        .then(blob => {
          parsingFile(blob,modified);
        })
        .catch(error => {
          console.error('Error:', error);
        });
  }
  const parsingFile = (file,modified) => {
    let result = []; //최종 배열 담을 변수

    const reader = new FileReader();

    reader.onload = (e) => {
      let data = e.target.result;
      const wb = XLSX.read(data, {type: "binary"});
      const wsName = wb.SheetNames[0];
      let workSheet = wb.Sheets[wsName];
      let row;
      let rowNum;
      let colNum;
      let range = XLSX.utils.decode_range(workSheet["!ref"]);

      for (rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        row = [];
        for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
          let nextCell = workSheet[XLSX.utils.encode_cell({r: rowNum, c: colNum})];
          if (nextCell === undefined) {
            row.push(void 0);
          } else row.push(nextCell.w);
        }
        result.push(row);
      }
      setExcelData({...excelData, result: result, modified: modified});
    };
    reader.readAsBinaryString(file);

  }
   {
     return (
         <>
             {Object.keys(excelData).length > 0? (excelData.result.map((rows, rowIndex) => {
             return (
                 <tr key={rowIndex}>
                   {rows.map((data, colsIndex) => {
                     return rowIndex <=1?
                          (<th className="whitespace-nowrap px-2 py-2 border bg-yellow-200 min-w-28" key={colsIndex}>{data}</th>)
                                  :(<td className="px-2 py-2 border text-xs"  key={colsIndex}>{data}</td>);
                   })}
                 </tr>
             );
           }))
           :((<>로딩 중 </>))}

           <p className="text-right text-xs mt-2">[server.time] {excelData.modified}</p>
       </>)
   }
}
export default ExcelLoadPage;