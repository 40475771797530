import React, {Fragment, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {toast} from "react-toastify";
import {
  ADMIN_GAME_MANAGEMENT_EDIT_DATA_API,
} from "../../constants/api_constants";
import {accountInfo} from "../../constants/constants";
import {RiLockFill, RiLockUnlockLine} from "react-icons/ri";
import neverLandUtils from "../../utils/NeverLandUtils";
import {getFetcherAdmin} from "../../utils/fetcher_admin";

const GameManagementModal = ({setShowManagementModal, gameData}) => {
  const [open, setOpen] = useState(true);
  const [homeScore, setHomeScore] = useState(gameData.homeScore);
  const [awayScore, setAwayScore] = useState(gameData.awayScore);
  const [sumScore, setSumScore] = useState(gameData.sumScore);
  const [chaneInputText, setChangeInputText] = useState(''); // "변경" 입력
  const [changeReason, setChangeReason] = useState(''); // 변경 사유
  const [isWinLock, setIsWinLock] = useState(gameData.winAdminLocked); // 승 잠금
  const [isDrawLock, setIsDrawLock] = useState(gameData.drawAdminLocked); // 무 잠금
  const [isLoseLock, setIsLoseLock] = useState(gameData.awayAdminLocked); // 패 잠금
  // 스코어 세팅 / 적특처리 둘중 하나만 할 수 있음
  const [isScoreSet, setIsScoreSet] = useState(false);
  const [isReject, setIsReject] = useState('');

  const closeModal = () => {
    setOpen(false);
    setShowManagementModal(false);
  }

  const setGameScore = () => {
    getFetcherAdmin().post(ADMIN_GAME_MANAGEMENT_EDIT_DATA_API, {
      user: accountInfo.makeJson(),
      gameCode: gameData.gameInfoCode,
      useScoreSet: isScoreSet,
      allotCode: gameData.gameNo,
      homeScore: homeScore,
      awayScore: awayScore,
      sumScore: sumScore,
      lockWin: isWinLock ? "L" : "U",
      lockDraw: isDrawLock ? "L" : "U",
      lockLose: isLoseLock ? "L" : "U",
      reject: isReject,
      comment : changeReason,
    }).then(res => {
      if (res.data.header.type === 'success') {
        toast.success(res.data.header.message);
        setOpen(false);
        closeModal();
      } else {
        toast.warn(res.data.header.message);
      }
    }).catch((err) => {toast.info("오류가 발생했습니다."); console.log(err);});
  }

  return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                    className="relative z-10 transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-80 sm:max-w-lg sm:p-6">

                  <h3 className="text-xl font-semibold leading-6 text-gray-900 text-center mb-5">경기정보 변경</h3>
                  {/*<button onClick={closeModal} className="ml-64">*/}
                  {/*  <RiCloseLine className="w-5 h-5"/>*/}
                  {/*</button>*/}

                  {/* 잠금버튼 */}
                  <div
                      className="border border-slate-400 p-3 rounded-lg mt-3 flex flex-row justify-around font-semibold mb-3">
                    <button
                        disabled={gameData.winPublicLocked}
                        onClick={() => setIsWinLock(prevState => !prevState)}
                        className={neverLandUtils.classNames(gameData.winPublicLocked ? "bg-black text-white border-black" : isWinLock ? "bg-rose-800 text-white border-black" : "bg-white border-gray-500 text-gray-500"
                            , "flex flex-row items-center  border-2 px-2 py-1 rounded-lg justify-center")}>
                      승
                      {isWinLock ? <RiLockFill className="h-5 w-auto ml-2"/> :
                          <RiLockUnlockLine className="h-5 w-auto ml-2"/>}
                    </button>

                    <button
                        disabled={gameData.drawPublicLocked}
                        onClick={() => setIsDrawLock(prevState => !prevState)}
                        className={neverLandUtils.classNames(gameData.drawPublicLocked ? "bg-black text-white border-black" : isDrawLock ? "bg-rose-800 text-white border-black" : "bg-white border-gray-500 text-gray-500"
                            , "flex flex-row items-center  border-2 px-2 py-1 rounded-lg justify-center")}>
                      무
                      {isDrawLock ? <RiLockFill className="h-5 w-auto ml-2"/> :
                          <RiLockUnlockLine className="h-5 w-auto ml-2"/>}
                    </button>

                    <button
                        disabled={gameData.awayPublicLocked}
                        onClick={() => setIsLoseLock(prevState => !prevState)}
                        className={neverLandUtils.classNames(gameData.awayPublicLocked ? "bg-black text-white border-black" : isLoseLock ? "bg-rose-800 text-white border-black" : "bg-white border-gray-500 text-gray-500"
                            , "flex flex-row items-center  border-2 px-2 py-1 rounded-lg justify-center")}>
                      패
                      {isLoseLock ? <RiLockFill className="h-5 w-auto ml-2"/> :
                          <RiLockUnlockLine className="h-5 w-auto ml-2"/>}
                    </button>
                  </div>

                  {/* 스코어 변경 / 적특 처리 */}
                  <div className="border border-slate-400 p-3 rounded-lg">
                    <div className="flex flex-row items-center">
                      <input
                          type="checkbox"
                          disabled={isReject === 'reject'}
                          checked={isScoreSet && (isReject !== 'reject')}
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mr-2"
                          onChange={() => {
                            if (isReject === 'reject') setIsReject(''); // 체크 하면 적특처리 초기화
                            setIsScoreSet(prevState => !prevState);
                          }}
                      />
                      <p className="text-md text-center font-semibold">스코어 변경 </p>
                    </div>

                    <div className="flex flex-row justify-between">
                      <div className="mt-5 flex flex-col">
                        <p className="text-md text-center font-semibold">홈스코어</p>
                        <input
                            disabled={!isScoreSet}
                            value={isReject === 'reject' ? 'X' : homeScore}
                            onChange={(e) => setHomeScore(e.target.value)}
                            className="mr-2 mt-1 px-4 w-28 font-semibold rounded-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>

                      <div className="mt-5 flex flex-col">
                        <p className="text-md text-center font-semibold">원정스코어</p>
                        <input
                            disabled={!isScoreSet}
                            value={isReject === 'reject' ? 'X' : awayScore}
                            onChange={(e) => setAwayScore(e.target.value)}
                            className="ml-2 mt-1 px-4 w-28 font-semibold rounded-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="mt-5 flex flex-col">
                      <p className="text-md text-center font-semibold">합산 스코어 (언더오버)</p>
                      <input
                          disabled={!isScoreSet}
                          value={isReject === 'reject' ? 'X' : sumScore}
                          onChange={(e) => setSumScore(e.target.value)}
                          className="mt-1 pl-2 w-full font-semibold rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>

                    <p className="mt-5 text-sm">변경하시려면 "변경"을 입력하세요</p>
                    <div className="flex flex-row">
                      <input
                          disabled={!isScoreSet}
                          value={isReject === 'reject' ? '' : chaneInputText}
                          onChange={(e) => setChangeInputText(e.target.value)}
                          placeholder=" 변경"
                          className="my-1 px-4 w-full font-semibold rounded-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <button disabled={isScoreSet}
                          className={neverLandUtils.classNames(isScoreSet ? "bg-gray-500 text-white" : isReject ? "bg-rose-700 text-white" : "bg-amber-300 text-black", "my-3 font-semibold w-full py-1 rounded-lg")}
                          onClick={() => setIsReject(isReject === "reject" ? '' : "reject")}
                  >
                    적특 처리
                  </button>

                  <input
                      value={changeReason}
                      onChange={(e) => setChangeReason(e.target.value)}
                      placeholder="수정 사유를 입력 하세요.(필수)"
                      className="my-1 px-4 w-full font-semibold rounded-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />

                  <div className="mt-5 flex flex-row justify-between sm:mt-6 grid-cols-2 gap-3">
                    <button
                        onClick={setGameScore}
                        type="button"
                        className={neverLandUtils.classNames(!changeReason || (isScoreSet && (chaneInputText !== '변경')) ? "bg-gray-300" : "bg-blue-600 hover:bg-blue-500 cursor-pointer",
                            "w-32 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm")}
                        disabled={!changeReason || (isScoreSet && (chaneInputText !== '변경'))}
                    >
                      적용
                    </button>
                    <button
                        type="button"
                        className="w-32 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                        onClick={closeModal}
                    >
                      취소
                    </button>
                  </div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
  )
}
export default GameManagementModal;
