import {Fragment, useState} from 'react';
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/20/solid';
import {Listbox, Transition} from '@headlessui/react';
import neverLandUtils from "../utils/NeverLandUtils";

const options = [
  {id: 0, name: "1개씩", number: 1},
  {id: 2, name: "5개씩", number: 5},
  {id: 3, name: "10개씩", number: 10},
  {id: 4, name: "15개씩", number: 15},
  {id: 5, name: "20개씩", number: 20},
];

function getIndexOfOption(initCountPerPage) {
  if (!initCountPerPage)
    return 1;
  for (let i=0; i<options.length; i++) {
    if (options[i].number === initCountPerPage)
      return i;
    if (options[i].number > initCountPerPage)
      return i - 1;
  }
  return options.length - 1;
}

const BoardListSelectBox = ({setListPerPage}) => {
  const [selectedOption, setSelectedOption] = useState(options[getIndexOfOption(setListPerPage.initCountPerPage)]);


  setListPerPage.onRefresh=(currentCountPerPage)=>{
     let  index= getIndexOfOption(currentCountPerPage);
      //console.log("BoardListSelectBox.onRefresh->"+currentCountPerPage+"/"+index);
      setSelectedOption(options[index]);
  };
  const selectBoxValue = (data) => {
    if(setListPerPage.setCountPerPage)
      setListPerPage.setCountPerPage(data.number);
    else{
      setSelectedOption(data.number);
    }
    setSelectedOption(data);
  };

  if(setListPerPage.setInitCountPerPage){
    setListPerPage.setInitCountPerPage(options[1]);
  }
  return (
      <Listbox value={selectedOption} onChange={selectBoxValue}>
        {({open}) => (
            <div className="relative">
              <Listbox.Button
                  className="w-28 rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <span className="block truncate">{selectedOption.name}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400"/>
            </span>
              </Listbox.Button>

              <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
              >
                <Listbox.Options
                    className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map((data) => (
                      <Listbox.Option
                          key={data.id}
                          value={data}
                          className={({active}) =>
                              neverLandUtils.classNames('relative cursor-default select-none py-2 pl-3 pr-9',
                                  active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                              )
                          }
                      >
                        {({active, selected}) => (
                            <>
                              <span
                                  className={neverLandUtils.classNames('block truncate', selected && 'font-semibold')}>
                                {data.name}
                              </span>
                              {selected && (
                                  <span
                                      className={neverLandUtils.classNames('absolute inset-y-0 right-0 flex items-center pr-4',
                                          active ? 'text-white' : 'text-indigo-600'
                                      )}
                                  >
                                    <CheckIcon className="h-5 w-5"/>
                                  </span>
                              )}
                            </>
                        )}
                      </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
        )}
      </Listbox>
  );
};

export default BoardListSelectBox;
