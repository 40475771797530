import {Fragment, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {getFetcher} from "../../utils/fetcher";
import {ADD_MY_STORE_API} from "../../constants/api_constants";
import {accountInfo} from "../../constants/constants";
import {toast} from "react-toastify";

const AddFavoriteStoreModal = ({setShowAddModal}) => {
  const [open, setOpen] = useState(true);
  const [storeCode, setStoreCode] = useState('');

  const addMyStore = () => {
    // <-- 즐겨찾기 지점 추가 -->
    let _encodeStoreCode = encodeURIComponent(storeCode);

    getFetcher().post(ADD_MY_STORE_API, {
      user: accountInfo.makeJson(),
      storeCode: _encodeStoreCode
    })
        .then(res => {
          const {type, message} = res.data.header;
          if (type === 'fail') {
            toast.error(message);
          } else if (type === 'success') {
            toast.success('지점 등록이 완료되었습니다.');
            if(res.data.result.isDefaultStoreSetting && res.data.result.isDefaultStoreSetting==='success'){
              accountInfo.updateDefaultStoreCode(_encodeStoreCode);
            }
            closeModal();
          }
        })
  }

  const closeModal = () => {
    setOpen(false);
    setShowAddModal(false);
  }

  return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                    className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <h3 className="text-xl font-semibold leading-6 text-gray-900">지점 추가</h3>
                      <div className="mt-2 max-w-xl text-sm text-gray-500">
                        <p>지점 코드를 입력하세요.</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row justify-center mt-5 sm:mt-6">
                    <div className="sm:max-w-xs">
                      <label htmlFor="email" className="sr-only">
                        지점 코드
                      </label>
                      <input
                          onChange={(e) => setStoreCode(e.target.value.trim())}
                          className="block w-full pl-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                          placeholder="지점코드"
                      />
                    </div>
                    <div className="flex flex-row justify-center">
                      <button
                          onClick={addMyStore}
                          className="mt-3 inline-flex w-14 items-center justify-center rounded-md bg-blue-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 sm:ml-3 sm:mt-0 sm:w-auto"
                      >
                        등록
                      </button>
                      <button
                          onClick={closeModal}
                          className="mt-3 inline-flex w-14 items-center justify-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-semibold text-gray-500 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 ml-3 sm:mt-0 sm:w-auto"
                      >
                        취소
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
  )
}
export default AddFavoriteStoreModal;
