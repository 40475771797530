import {
  RiArrowDownSLine,
  RiArrowRightSLine,
  RiCheckDoubleLine,
  RiCheckLine,
  RiCloseLine,
  RiSendPlaneFill
} from "react-icons/ri";
import {useEffect, useState} from "react";
import neverLandDirectMessage from "../utils/NeverLandDirectMessage";
import neverLandUtils from "../utils/NeverLandUtils";

const DirectMessageContainer = ({onNewMessageReceived}) => {
  const [isShaking, setIsShaking] = useState(false);
  const [viewMessages, setViewMessages] = useState(false);
  const [refreshDate, setRefreshDate] = useState(new Date());

  function _onChanged() {
    setRefreshDate(new Date());
    return true;
  }
  function _onChanged2(b) {
    if(b) {
      if (onNewMessageReceived) {
        onNewMessageReceived();
      }
    }
    return _onChanged();
  }
  useEffect(() => {
    const interval = setInterval(() => {
      if (neverLandDirectMessage.hasUnreadMessage()) setIsShaking(true);
      setTimeout(() => {
        if (neverLandDirectMessage.hasUnreadMessage()) setIsShaking(false);
      }, 500);
    }, 1500);

    neverLandDirectMessage.addOnMessageHandler(_onChanged2);
    return () => {
      clearInterval(interval);
      neverLandDirectMessage.removeOnMessageHandler(_onChanged2);
    }
  }, []);

  return (
      <div className="relative">
        {neverLandDirectMessage.hasUnreadMessage() && (
            <div>
              <style>
                {`
                  @keyframes shake {
                    0% { transform: translateX(0); }
                    25% { transform: translateX(-5px); }
                    50% { transform: translateX(5px); }
                    75% { transform: translateX(-5px); }
                    100% { transform: translateX(0); }
                  }
                  .shake {
                    animation: shake 0.5s ease-in-out;
                  }
                `}
              </style>
              <div
                  className={neverLandUtils.classNames(isShaking ?
                      "border-rose-600  bg-rose-100 shake" : "border-sky-600 bg-white", "cursor-pointer z-40 fixed top-10 right-5 lg:right-[20rem] flex flex-row items-center justify-center p-3 rounded-2xl border-2 text-xl font-semibold shadow-sm")}

                  onClick={() => {
                    neverLandDirectMessage.refreshMessages();
                    setViewMessages(neverLandDirectMessage.hasUnreadMessage());
                  }}
              >
                <RiSendPlaneFill className="h-8 w-auto text-sky-700"/>
                <p className={"ml-2 w-5 min-w-max whitespace-nowrap rounded-full bg-rose-600 px-4 py-1 text-center text-sm text-white"}>
                  {neverLandDirectMessage.unreadMessageCount()}
                </p>
              </div>
            </div>
        )}

        {/* 쪽지 목록 */}
        {viewMessages && (
            <div
                className="z-50 fixed top-20 right-8 md:right-28 lg:right-[25rem] w-[21rem] sm:w-[28rem] bg-amber-200 rounded-xl flex flex-col pb-5 pr-3 text-xs">
              <button
                  onClick={() => {
                    neverLandDirectMessage.setMessageToAllRead();
                    setViewMessages(false);
                  }}
                  className="flex flex-row rounded-lg bg-white text-gray-900 px-2 py-1 m-3 mb-5 font-semibold ml-auto border-2 border-gray-900 items-center"
              >
                <RiCheckDoubleLine className="h-5 w-auto mr-1"/>
                전체 읽음처리
              </button>

              <div className="max-h-[35rem] lg:max-h-[30rem] overflow-y-auto">
                {neverLandDirectMessage.getDirectMessageList().map((data) => {
                  if (data.readtime !== null && !data.mode) {
                    data.mode = "mini";
                  }
                  if (data.mode && data.mode === "mini") {
                    // 접었을때 작게 보이기 (mini mode)
                    return (
                        <div
                            className={neverLandUtils.classNames(data.readtime ? "bg-stone-200 border-stone-400 text-stone-600" : "bg-white border-orange-500", "flex flex-row justify-between break-words rounded p-2 text-sm border-2 max-w-[20rem] sm:max-w-[24rem] min-w-[12rem] ml-3 mb-4")}>
                          <div onClick={() => (data.mode = "full") && _onChanged()}
                               className="flex flex-row items-center cursor-pointer">
                            <RiArrowRightSLine className="h-6 w-auto mr-1"/>
                            <p className="font-semibold">From : {data.from}</p>
                            <p className="ml-3 text-xs">{data.sendtime}</p>
                          </div>
                          <div className="flex flex-row">
                            {data.readtime ?
                                <button
                                    className="font-semibold ml-3 rounded-md flex flex-row items-center border-2 border-rose-600 bg-rose-600"
                                    onClick={() => {
                                      neverLandDirectMessage.removeMessage(data);
                                      setRefreshDate(new Date());
                                      setViewMessages(neverLandDirectMessage.hasUnreadMessage());
                                    }}
                                >
                                  <RiCloseLine className="h-5 w-auto bg-rose-600 text-white"/>
                                  <p className="py-0.5 px-1.5 whitespace-nowrap bg-white rounded-r-md text-black">삭제</p>
                                </button>
                                :
                                <button
                                    className="font-semibold ml-auto rounded-md flex flex-row items-center border-2 border-green-600 bg-green-600"
                                    onClick={() => data.mode = null}
                                >
                                  <RiCheckLine className="h-5 w-auto bg-green-600 text-white"/>
                                  <p className="py-0.5 px-1.5 whitespace-nowrap bg-white rounded-r-md">확인</p>
                                </button>
                            }
                          </div>
                        </div>
                    );
                  }
                  return (
                      <div
                          className={neverLandUtils.classNames(data.readtime === null ? "text-black bg-white border-orange-500 " : "text-stone-600 border-stone-400 bg-stone-200"
                              , "flex flex-col break-words rounded p-2 text-sm border-2 max-w-[20rem] sm:max-w-[24rem] min-w-[12rem] ml-3 mb-4")}>
                        <div className="flex flex-row items-center cursor-pointer"
                             onClick={() => (data.mode = "mini") && _onChanged()}
                        >
                          <RiArrowDownSLine className="h-6 w-auto mr-1"/>
                          <p className="font-semibold">
                            From : {data.from}
                          </p>
                        </div>

                        <p className="mt-2 text-xl">{data.message}</p>

                        <div className="flex flex-row justify-between items-center mt-2">
                          <p className="text-gray-600 text-xs">{data.sendtime}</p>
                          <div className="flex flex-row">
                            {data.readtime === null
                                &&
                                <button
                                    className="font-semibold ml-auto rounded-md flex flex-row items-center border-2 border-green-600 bg-green-600"
                                    onClick={() => {
                                      data.mode = "mini";
                                      neverLandDirectMessage.setMessageToRead(data);
                                      setRefreshDate(new Date());
                                      setViewMessages(neverLandDirectMessage.hasUnreadMessage());
                                    }}
                                >
                                  <RiCheckLine className="h-5 w-auto bg-green-600 text-white"/>
                                  <p className="py-0.5 px-1.5 whitespace-nowrap bg-white rounded-r-md">확인</p>
                                </button>
                            }

                            <button
                                className="font-semibold ml-3 rounded-md flex flex-row items-center border-2 border-rose-600 bg-rose-600"
                                onClick={() => {
                                  neverLandDirectMessage.removeMessage(data);
                                  setRefreshDate(new Date());
                                  setViewMessages(neverLandDirectMessage.hasUnreadMessage());
                                }}
                            >
                              <RiCloseLine className="h-5 w-auto bg-rose-600 text-white"/>
                              <p className="py-0.5 px-1.5 whitespace-nowrap bg-white rounded-r-md text-black">삭제</p>
                            </button>
                          </div>
                        </div>
                      </div>
                  )
                })}
              </div>
            </div>
        )}
      </div>
  );
}

export default DirectMessageContainer;