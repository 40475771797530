import Sidebar from "../components/Sidebar";
import GameCard from "../components/Card/GameCard";
import FilterCheckBoxTable from "../components/Table/FilterCheckBoxTable";
import CartSidebar from "../components/CartSidebar";
import React, {useEffect, useState} from "react";
import neverLandGameData from "../utils/NeverLandGameData";
import SelectedGameTable from "../components/Table/SelectedGameTable";
import loadDummyData, {mainConfig} from "../utils/_dummyData";
import {accountInfo} from "../constants/constants";
import neverLandUtils from "../utils/NeverLandUtils";
import GameCardLargeFont from "../components/Card/GameCardLargeFont";
import MagnifierModeGameCard from "../components/Card/MagnifierModeGameCard";
import {RiMailSendFill} from "react-icons/ri";
import TextToOrderModal from "../components/Modal/TextToOrderModal";

const MainPage = () => {
  const [textToOrder, setTextToOrder] = useState(null);
  const [mouseOverData, setMouseOverData] = useState(null);
  const [refreshDate, setRefreshDate] = useState(new Date());
  const [showCartSidebar, setShowCartSidebar] = useState(false);

  const updateUI = () => {
    if (mouseOverData) {
      setMouseOverData(null);
      return;
    }
    setRefreshDate(new Date());
  }
  const handleOnChanged = () => {
    updateUI();
  };

  let prevScreenSize = "unknown";

  function handleResize() {
    const width = window.innerWidth;
    let currentScreenSize;

    if (width >= 1024) {
      currentScreenSize = "large";
    } else if (width >= 768) {
      currentScreenSize = "mid";
    } else {
      currentScreenSize = "small";
    }
    if (prevScreenSize === currentScreenSize) {
      return;
    }
    if (prevScreenSize === "small" && currentScreenSize !== "small") {
      _setShowCartSidebar(false);
    }
    prevScreenSize = currentScreenSize;
  }

  useEffect(() => {
    neverLandGameData.clearSelection();
    neverLandGameData.addOnChangedHandler(handleOnChanged);
    loadDummyData();

    window.addEventListener('resize', handleResize);

    return () => {
      neverLandGameData.removeOnChangedHandler(handleOnChanged);
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  const today = new Date();
  const thisYear = today.getFullYear();

  let _selected_game_count = neverLandGameData.selectedAllotData ? neverLandGameData.selectedAllotData.length : "-";
  let _selected_ticket_count = neverLandGameData.ticketData ? neverLandGameData.ticketData.length : "-";

  if (!showCartSidebar) {
    if (mainConfig._powered_refresh_folder) {
      mainConfig._powered_refresh_folder = false;
      neverLandGameData.refreshFolder(neverLandGameData.currentFolderNumber());
    }
  }
  const handleReadClipboard = async () => {
    let _text = "";
    try {
      const text = await navigator.clipboard.readText();
      if (text) {
        _text = text.trim();
      }
    } catch (err) {
      setTextToOrder("//" + neverLandUtils.utils.getDisplayTimeText(new Date()) + "\n");
    }
    if (_text.length === 0) {
      setTextToOrder("//" + neverLandUtils.utils.getDisplayTimeText(new Date()) + "\n");
      return;
    }
    if (_text.includes("승") || _text.includes("무") || _text.includes("패") || _text.includes("언더") || _text.includes("오버")
        || _text.includes("win") || _text.includes("draw") || _text.includes("lose") || _text.includes("under") || _text.includes("over")) {
      setTextToOrder("//" + neverLandUtils.utils.getDisplayTimeText(new Date()) + "\n" + _text);
      return;
    }
    setTextToOrder("//" + neverLandUtils.utils.getDisplayTimeText(new Date()) + "\n");
  };
  const _setShowCartSidebar = (isShow) => {
    mainConfig._powered_refresh_folder = true;
    setShowCartSidebar(isShow);
  }

  let isShowNoticeModal = false;
  return (
      <div className="bg-gray-100 flex flex-col relative mb-12 lg:mb-0">
        {/* 돋보기 모드 게임카드 */}
        {mouseOverData && <MagnifierModeGameCard mouseOverData={mouseOverData}/>}
        {/* 문자 의뢰 */}
        {textToOrder && <TextToOrderModal originText={textToOrder} refreshDate={refreshDate}/>}

        {/* 사이드바 영역 */}
        <Sidebar/>

        {/* 게임정보 영역 */}
        <main className="lg:pl-64 min-h-screen">

          <div className="md:pr-104">
            {!showCartSidebar &&
                <div>
                  <div className="px-4 py-3 sm:px-6 lg:px-8 lg:py-6">
                    <div className="flex flex-col xl:flex-row items-center mb-4">
                      <p className="text-2xl font-semibold cursor-pointer inline-block"
                         onClick={() => neverLandGameData.loadGameDataList("main_page_click")}
                      >
                        {neverLandGameData.gameRound !== -1 ? "프로토 승부식 " + thisYear + "년 " + neverLandGameData.gameRound + "회차" : "프로토 승부식 | 정보를 가져오는 중..."}
                      </p>

                      {accountInfo.isStore() &&
                          <div
                              className="mt-3 sm:mt-0 ml-5 flex flex-row bg-orange-600 text-white items-center px-3 py-0.5 rounded-md cursor-pointer"
                              onClick={handleReadClipboard}
                          >
                            <RiMailSendFill className="h-5 w-auto mr-1"/>
                            <p>문자의뢰</p>
                          </div>
                      }
                    </div>

                    {/* 조회조건 테이블 */}
                    <FilterCheckBoxTable/>
                    {/* 선택경기 고정 테이블 */}
                    <SelectedGameTable/>
                    {/* 게임카드 */}
                    {accountInfo.getUserConfig().isOn('use_large_font', false) ?
                        <GameCardLargeFont setMouseOverData={(data) => {
                          if (mouseOverData === null || mouseOverData.displayData !== data) setMouseOverData(data)
                        }}/>
                        :
                        <GameCard setMouseOverData={(data) => {
                          if (mouseOverData === null || mouseOverData.displayData !== data) setMouseOverData(data)
                        }}/>}
                  </div>

                  {/* 모바일버전 하단 */}
                  <button className="md:hidden sm:block fixed bottom-0 w-full" onClick={() => setShowCartSidebar(true)}>
                    <div className="h-12 bg-gray-900 flex flex-row justify-center items-center">
                      <p className="text-white font-semibold mr-3">조합 선택하기</p>
                      <p className="text-white"> {_selected_game_count} 경기 ( {_selected_ticket_count} 조합 )</p>
                    </div>
                  </button>
                </div>
            }
            {showCartSidebar &&
                <div className="w-auto max-w-md px-8 py-6 lg:hidden overflow-y-auto">
                  <CartSidebar showCartSidebar={showCartSidebar} setShowCartSidebar={_setShowCartSidebar}/>
                </div>
            }
          </div>
        </main>

        {/* 카트담기 영역 */}
        <aside
            className="fixed inset-y-0 right-0 hidden w-full sm:w-104 overflow-y-auto border-l border-gray-200 px-0 py-6 sm:px-6 md:block">
          <CartSidebar showCartSidebar={showCartSidebar} setShowCartSidebar={_setShowCartSidebar}/>
        </aside>
      </div>
  )
}
export default MainPage;