import {CALCULATE_LOWER_TABLE_HEADER} from "../../constants/component_data";
import React, {useEffect, useState} from "react";
import neverLandUtils from "../../utils/NeverLandUtils";
import {
  RiAlarmWarningFill,
  RiAttachmentLine,
  RiCameraFill,
  RiCheckboxFill,
  RiCheckboxLine,
  RiCheckDoubleLine, RiEraserFill
} from "react-icons/ri";
import {toast} from "react-toastify";

const CalculateLowerTableWithSyncStoresOnly = ({lowerTableBodyData}) => {
  const [syncStoreName, setSyncStoreName] = useState(null);
  const [syncTargetStoreNameList, setSyncTargetStoreNameList] = useState(null);
  const [checkedData, setCheckedData] = useState(neverLandUtils.commons.load("checkedSuperAdminReportItems"));
  const [resultText, setResultText] = useState("");
  const [copyMessage, setCopyMessage] = useState(""); // 클립보드 복사할 메세지
  const [hasOnGoing, sethasOnGoing] = useState(false); // 진행중
  const [ratePercentage, setRatePercentage] = useState(1.00);

  useEffect(() => {
    let temp;
    let roundsInfo = [];
    let _hasOnGoing = false;
    let sumOfAmounts = 0;
    let resultOfAmounts = 0;
    let countOfAmounts = 0;
    let textOfProgress = "";
    let _rate = Number(ratePercentage) || 0;
    let copyingResultText = "";

    if (_syncTargetList && _syncTargetList.length > 0) {
      for (let t of _syncTargetList) {
        if (t.totalOngoing > 1) {
          _hasOnGoing = true;
        }
        sumOfAmounts += t.totalAmount;
        countOfAmounts++;
        if (textOfProgress.length > 0) {
          textOfProgress += "\n+";
        }
        for (let i of t.list) {
          temp = getTextFormat(i.roundCode);
          if (!roundsInfo.includes(temp))
            roundsInfo.push(temp);
        }
        textOfProgress += t.totalAmount.toLocaleString() + "(" + t.storeName + ")";
      }
    }

    if (!_hasOnGoing)
      _hasOnGoing = syncStore && syncStore.totalOngoing > 1;


    if (syncStore) {

      textOfProgress = textOfProgress + "\n = " + sumOfAmounts.toLocaleString() + "(총 " + countOfAmounts + " 지점)";
      textOfProgress += "\n-------------------------------------------------------------";
      textOfProgress += "\n" + sumOfAmounts.toLocaleString() + " x " + _rate + "% = " + ((resultOfAmounts = sumOfAmounts * _rate / 100)).toLocaleString();
      textOfProgress += "\n-------------------------------------------------------------";
      // textOfProgress += "\n" + resultOfAmounts.toLocaleString() + " - " + syncStore.differenceAmount.toLocaleString() + " (현재 차액)"
      resultOfAmounts = Math.floor(resultOfAmounts);
      textOfProgress += "\n" + syncStore.differenceAmount.toLocaleString() + " (현재 차액)" + " - " + resultOfAmounts.toLocaleString()
          + " = " + (syncStore.differenceAmount - resultOfAmounts).toLocaleString();

      resultOfAmounts = syncStore.differenceAmount - resultOfAmounts;
      textOfProgress += "\n―――――――――――  ↓ 복사영역  ―――――――――――\n";

      temp = "";
      for (let r of roundsInfo) {
        if (temp.length > 0) {
          temp += "회, ";
        }
        temp += r;
      }
      copyingResultText = "사장님, " + temp + "회차는 [총 " + resultOfAmounts.toLocaleString() + "원]입니다.";
      textOfProgress += copyingResultText;
    }


    if (sumOfAmounts > 0)
      setResultText(textOfProgress);
    sethasOnGoing(_hasOnGoing);
    setCopyMessage(copyingResultText);
  }, [ratePercentage, syncStoreName, syncTargetStoreNameList]);

  function getTextFormat(text) {
    if (text.includes("G101_")) {
      text = text.replace("G101_", "").substring(2).trim();
      while (text[0] === '0') {
        text = text.substring(1);
      }
      return text;
    }
    return text;
  }

  function _setCheckData(newCheckSuperAdminReportItems, data, isChecked) {
    for (let item of data.list) {
      _setCheckDataItem(newCheckSuperAdminReportItems, data, item, isChecked);
    }
    neverLandUtils.commons.save("checkedSuperAdminReportItems", newCheckSuperAdminReportItems);
  }

  function _setCheckDataItem(newCheckSuperAdminReportItems, data, item, isChecked) {
    let dataKey = data.storeName + "_" + item.roundCode;

    if (newCheckSuperAdminReportItems === null) {
      newCheckSuperAdminReportItems = {list: []};
    }
    if (isChecked) {
      if (!newCheckSuperAdminReportItems.list.includes(dataKey)) {
        newCheckSuperAdminReportItems.list.push(dataKey);
      }
    } else {
      newCheckSuperAdminReportItems.list = newCheckSuperAdminReportItems.list.filter((data) => data !== dataKey);
    }
  }

  function _toggleCheck(data, item) {
    let dataKey = data.storeName + "_" + item.roundCode;
    let newCheckSuperAdminReportItems = checkedData;

    if (newCheckSuperAdminReportItems === null) {
      newCheckSuperAdminReportItems = {list: []};
    }
    if (!newCheckSuperAdminReportItems.list.includes(dataKey)) {
      newCheckSuperAdminReportItems.list.push(dataKey);
    } else {
      newCheckSuperAdminReportItems = {list: newCheckSuperAdminReportItems.list.filter((data) => data !== dataKey)};
    }
    neverLandUtils.commons.save("checkedSuperAdminReportItems", newCheckSuperAdminReportItems);
    if (newCheckSuperAdminReportItems == checkedData) {
      newCheckSuperAdminReportItems = {list: newCheckSuperAdminReportItems.list};
    }
    setCheckedData(newCheckSuperAdminReportItems);
  }

  function isChecked(data, item) {
    if (checkedData === null)
      return false;
    let dataKey = data.storeName + "_" + item.roundCode;
    return checkedData.list.includes(dataKey);
  }

  function _toggleCheckAll(isChecked) {
    let syncStore = _syncTargetStoreNameList ? lowerTableBodyData.find((data) => data.storeName === _syncStoreName) : null;
    let _syncTargetList = _syncStoreName ?
        lowerTableBodyData.filter((data) => _syncTargetStoreNameList.includes(data.storeName.replaceAll(" ", "").trim())) : [];

    let newCheckSuperAdminReportItems = checkedData;

    _setCheckData(newCheckSuperAdminReportItems, syncStore, isChecked);
    for (let i of _syncTargetList) {
      _setCheckData(newCheckSuperAdminReportItems, i, isChecked);
    }

    if (newCheckSuperAdminReportItems == checkedData) {
      newCheckSuperAdminReportItems = {list: newCheckSuperAdminReportItems.list};
    }
    setCheckedData(newCheckSuperAdminReportItems);
  }

  const _isFixed = (data) => {
    let item = data.list.find((item) => isChecked(data, item));
    return item !== undefined;
  }

  let _syncTargetStoreNameList = syncTargetStoreNameList;
  let _syncStoreName = syncStoreName;

  if (_syncTargetStoreNameList !== null && _syncTargetStoreNameList.length > 0) {
    _syncTargetStoreNameList = syncTargetStoreNameList.replaceAll(" ", "").trim().split(",");

  }
  if (_syncStoreName !== null && _syncStoreName.length > 0) {
    _syncStoreName = _syncStoreName.trim();
  }

  let syncStore = _syncStoreName ? lowerTableBodyData.find((data) => data.storeName === _syncStoreName) : null;
  let _syncTargetList = _syncTargetStoreNameList ?
      lowerTableBodyData.filter((data) => _syncTargetStoreNameList.includes(data.storeName.replaceAll(" ", "").trim())) : [];

  function getSortData(lowerTableBodyData) {
    return lowerTableBodyData.sort((d1, d2) => d1.storeName.localeCompare(d2.storeName));
  }

  return (
      <div className="flex-row mx-5 flex">
        <div className="p-2 max-h-[41rem] overflow-y-auto w-1/2">
          <div className="mb-3">
            <label>기준지점 : </label>
            <input value={syncStoreName}
                   onChange={(e) => setSyncStoreName(e.target.value)}
                   className="mx-1 w-44 px-3 py-1.5 ring-1 ring-gray-400 rounded-3xl focus:ring-indigo-500 mr-3"
            />
            {!syncStoreName &&
                <div className="flex flex-row text-rose-600 font-semibold mt-2">
                  <RiAlarmWarningFill className="h-5 w-auto mr-1"/>
                  <p>기준지점을 입력해 주세요 !</p>
                </div>
            }
          </div>
          <div className="flex flex-row items-center">
            <label>연계지점 : </label>
            <textarea
                rows="2"
                value={syncTargetStoreNameList}
                onChange={(e) => setSyncTargetStoreNameList(e.target.value)}
                className="mx-1 w-[25rem] px-3 py-1.5 ring-1 ring-gray-400 rounded-2xl focus:ring-indigo-500 mr-3"
            />

            <p>{_syncTargetList.length} 지점 /총 {lowerTableBodyData.length} 지점중</p>
          </div>

          {/* <-- 기준지점 테이블 --> */}
          {syncStore &&
              <div>
                {syncStore && getItemDisplay(syncStore, -1)}

                <div className="mt-5">
                  <label>연계 보상</label>
                  <input value={ratePercentage.toString()}
                         type="number"
                         onChange={(e) => setRatePercentage(Math.max(0,Number(e.target.value) || 0))}
                         className="w-24 px-3 py-1.5 ring-1 ring-gray-400 rounded-3xl focus:ring-indigo-500 ml-7 mr-3"
                  />%
                </div>
                <div className="flex flex-col mt-2 mb-4">
                  <label>결과 문구 </label>
                  <textarea value={resultText}
                            className="ml-24 text-sm mt-2 w-[39rem] px-3 py-1.5 ring-1 ring-gray-400 rounded-xl focus:ring-indigo-500 mr-3"
                            rows="14"
                            onChange={(e) => {
                              let text = e.target.value;
                              setResultText(text);
                              let _copyMessage = null;
                              for (let m of text.split("\n")) {
                                if (_copyMessage == null) {
                                  if (m.includes("――")) {
                                    _copyMessage = "";
                                    continue;
                                  }
                                  continue;
                                }
                                if (_copyMessage.length > 0) {
                                  _copyMessage += "\n";
                                }
                                _copyMessage += m;
                              }
                              setCopyMessage(_copyMessage?.trim());
                            }}/>
                </div>

                <div className="flex flex-row ml-24">
                  <button
                      onClick={() => {
                        navigator.clipboard.writeText(copyMessage);
                        toast.info("클립보드에 복사되었습니다. 😊")
                      }}
                      className="bg-blue-600 text-white px-1.5 py-0.5 flex flex-row rounded-md items-center"
                  >
                    <RiAttachmentLine className="h-4 w-auto mr-1"/>
                    <p>클립보드복사</p>
                  </button>

                  <button
                      onClick={() => _toggleCheckAll(true)}
                      className="bg-green-600 text-white px-1.5 py-0.5 flex flex-row rounded-md items-center ml-3"
                  >
                    <RiCheckDoubleLine className="h-4 w-auto mr-1"/>
                    <p>전체 체크</p>
                  </button>

                  <button
                      onClick={() => _toggleCheckAll(false)}
                      className="bg-gray-600 text-white px-1.5 py-0.5 flex flex-row rounded-md items-center ml-3"
                  >
                    <RiEraserFill className="h-4 w-auto mr-1"/>
                    <p>전체 체크 해제</p>
                  </button>
                </div>

                {hasOnGoing && // 진행건이 남아있을 경우 경고 띄우기
                    <div className="flex flex-row text-rose-600 font-semibold text-xl mt-3 ml-24">
                      <RiAlarmWarningFill className="h-6 w-auto mr-1"/>
                      <p> 아직 진행건이 남아있습니다 !</p>
                    </div>
                }
              </div>}
        </div>


        {!syncStore ?
            // 기준지점 입력안하면 전체 지점목록 띄우기
            (
                <div
                    className="ml-36 border-2 border-indigo-500 p-5 w-104 bg-white rounded-2xl ">
                  <p className="font-semibold mb-3 text-blue-600">※ 정산 대상 전체 지점 목록</p>
                  <div className="max-h-[35rem] overflow-y-auto">
                    {getSortData(lowerTableBodyData).map((data, index) => {
                      return (<p className="mb-2">● {data.storeName} </p>);
                    })}
                  </div>
                </div>
            ) : (
                <div className="ml-12 max-h-[41rem] overflow-y-auto w-1/2">
                  {/* <-- 연계지점 테이블 --> */}
                  {_syncTargetList.map((data, dataIndex) => {
                    return getItemDisplay(data, dataIndex);
                  })}
                </div>
            )}
      </div>
  )

  function getItemDisplay(data, dataIndex) {
    // 테이블
    return (<div className="mx-6 my-10 flex flex-row">
          <div className="flex flex-col items-center w-16">
            <p className={neverLandUtils.classNames(dataIndex === -1 ? "bg-blue-600 text-white border-blue-600" : "bg-white border-stone-900"
                , "mr-8 border rounded-3xl w-auto px-3.5 py-1.5 items-center text-center font-semibold")}
            >
              {dataIndex === -1 ? "BASE" : ("SUB:" + (dataIndex + 1))}
            </p>

            <button
                onClick={() => neverLandUtils.tools.convertHtmlToPngAndCopyToClipboard('reportCapture_' + dataIndex, true)}>
              <RiCameraFill className="h-8 w-auto text-gray-900 mr-4 mt-2 cursor-pointer"/>
            </button>
          </div>

          <div className="text-sm min-w-96">
            <div id={'reportCapture_' + dataIndex}>
              {/* <-- 캡처영역 테이블 (지점 ~ 차액 행) --> */}
              <table className="border border-stone-900">
                <tbody className="bg-white">
                {
                  CALCULATE_LOWER_TABLE_HEADER.map((header, headerIndex) => (
                      !header.name.includes("*") &&
                      <tr key={headerIndex}>
                        <td className="py-2 px-2 text-center font-semibold text-white bg-stone-900 border-b border-white whitespace-nowrap w-24 max-w-24">
                          {header.name}
                        </td>

                        <React.Fragment key={dataIndex + "_" + headerIndex + "_h"}>
                          {/* 지점열 */}
                          <td className={neverLandUtils.classNames(
                              // 총투표액, 유효투표액, 당첨액 = 노란색 배경
                              (header.key === 'totalAmount' || header.key === 'validBettingAmount' || header.key === 'totalWinningAmount') ? "bg-yellow-200" :
                                  // 차액 - : 빨간색 배경 / + : 초록색 배경
                                  (header.key === 'differenceAmount' && data['differenceAmount'] < 0) ? "bg-red-200" :
                                      (header.key === 'differenceAmount' && data['differenceAmount'] > 0) ? "bg-green-200" : "bg-white",
                              "text-right px-3 pl-6 pr-2 text-gray-900 border-l border-b border-gray-900 w-32 max-w-32")}>
                            {header.key.includes("/") ? data[header.key.split('/')[0]] : (typeof data[header.key] === 'number' ? data[header.key].toLocaleString() : data[header.key])}
                          </td>

                          {/* 회차열 */}
                          {data.list.map((item, itemIndex) => (
                              <td key={dataIndex + "_" + headerIndex + "_" + itemIndex}
                                  className={neverLandUtils.classNames(
                                      (header.key === 'totalAmount' || header.key === 'validBettingAmount' || header.key === 'totalWinningAmount') ? "bg-yellow-200" : "",
                                      "text-right py-2 pl-6 pr-2 border-l border-b border-gray-900 w-32 max-w-32")}>
                                {header.key.includes("/") ? getTextFormat(item[header.key.split('/')[1]]) :
                                    (typeof item[header.key] === 'number' ?
                                        item[header.key].toLocaleString() : item[header.key])}
                              </td>
                          ))}
                        </React.Fragment>
                      </tr>
                  ))
                }
                </tbody>
              </table>
            </div>

            {/* <-- 진행건 행 --> */}
            <table className="border border-stone-900 mt-0.5">
              <tbody className="bg-white">
              {CALCULATE_LOWER_TABLE_HEADER.map((header, headerIndex) => (
                  header.name.includes("*") &&
                  <tr key={headerIndex}>
                    <td className={neverLandUtils.classNames(data.list.find(item => item[header.key] > 0) === undefined ? "bg-stone-900" : "bg-rose-800",
                        "py-2 px-3 text-center font-semibold text-white whitespace-nowrap w-24 max-w-24")}>
                      {header.name.replace("*", "")}
                    </td>

                    <React.Fragment key={dataIndex + "_" + headerIndex + "_h"}>
                      <td className="text-center border-l border-stone-900 w-32">
                        {header.key.includes("/") ? data[header.key.split('/')[0]] : (typeof data[header.key] === 'number' ? data[header.key].toLocaleString() : data[header.key])}
                      </td>
                      {data.list.map((item, itemIndex) => (
                          <td key={dataIndex + "_" + headerIndex + "_" + itemIndex}
                              className="text-center border-l border-stone-900 w-32 max-w-32">
                            {header.key.includes("/") ? getTextFormat(item[header.key.split('/')[1]]) :
                                (typeof item[header.key] === 'number' ?
                                    item[header.key].toLocaleString() : item[header.key])}
                          </td>
                      ))}
                    </React.Fragment>
                  </tr>
              ))
              }
              </tbody>
            </table>

            {/* <-- 처리여부 행 --> */}
            <table className="border">
              <tbody className="divide-y divide-gray-300 bg-white border-b border-l border-stone-900">
              <tr>
                <td className="py-3 text-center font-semibold text-white lg:table-cell bg-stone-900 whitespace-nowrap min-w-56 border-t border-white">
                  처리 여부
                </td>

                {data.list.map((item) => (
                    <td onClick={() => {
                      _toggleCheck(data, item)
                    }}
                        className="min-w-32 cursor-pointer border-r border-stone-900"
                    >
                      <div className="flex justify-center items-center h-full">
                        {!isChecked(data, item) ? (
                            <RiCheckboxLine className="h-8 w-auto text-gray-400"/>
                        ) : (
                            <RiCheckboxFill className="h-8 w-auto text-green-500"/>
                        )}
                      </div>
                    </td>
                ))}
              </tr>
              </tbody>
            </table>
          </div>
        </div>
    );
  }

}
export default CalculateLowerTableWithSyncStoresOnly;