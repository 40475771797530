import {Fragment, useEffect, useState} from 'react'
import {Dialog, Listbox, Transition} from '@headlessui/react'
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/react/20/solid";
import {RiCloseLine} from "react-icons/ri";
import neverLandUtils from "../../utils/NeverLandUtils";
import {toast} from "react-toastify";
import {getFetcher} from "../../utils/fetcher";
import {CONFIRM_FOR_MY_STORE_USER_API} from "../../constants/api_constants";
import {accountInfo} from "../../constants/constants";

const approvalStateOption = [
  {id: 0, name: '승인대기중', value:'regist'},
  {id: 1, name: '승인', value:'confirmed'},
  {id: 2, name: '승인보류', value:'deferring'},
  {id: 3, name: '승인거부', value:'banned'},
]

const StoreCustomerManagementModal = ({setCustomerModalOpen, customerInfo}) => {
  const [open, setOpen] = useState(true);
  const [approvalState, setApprovalState] = useState(approvalStateOption[0]);
  const [memo, setMemo] = useState(customerInfo?.memoForStore || "");

  useEffect(() => {
    if(customerInfo.state ==='regist') {
      setApprovalState(approvalStateOption[0]);
    } else if (customerInfo.state ==='confirmed') {
      setApprovalState(approvalStateOption[1]);
    } else if (customerInfo.state ==='deferring') {
      setApprovalState(approvalStateOption[2]);
    } else if (customerInfo.state ==='banned') {
      setApprovalState(approvalStateOption[3]);
    }

  }, []);

  const closeModal = () => {
    setOpen(false);
    setCustomerModalOpen(false);
  }
  const editCustomerInfo = () => {
    let originalMemo=customerInfo?.memoForStore || "";
    let thisMemo=memo;

    if(originalMemo!==""&&thisMemo ==="")
        thisMemo="▼delete";

    getFetcher().post(CONFIRM_FOR_MY_STORE_USER_API, {
      user: accountInfo.makeJson(),
      userId: customerInfo.userId,
      state: approvalState.value,
      memo: thisMemo
    }).then(res => {
      const {type, message} = res.data.header;
      if(type === 'success') {
        toast.success(message);
        closeModal();
      } else toast.error(message);
    })
  }

  return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                    className="relative z-10 transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-80 sm:max-w-lg sm:p-6">

                  <button onClick={closeModal} className="ml-64">
                    <RiCloseLine className="w-5 h-5"/>
                  </button>

                  <div>
                    <div className="text-center sm:my-2">
                      <h3 className="text-xl font-semibold leading-6 text-gray-900">고객 정보</h3>
                    </div>
                  </div>

                  <div>
                    <div className="mt-5 flex flex-col">
                      <p className="text-sm">성명</p>
                      <input
                          value={customerInfo?.userName}
                          disabled={true}
                          className="mt-1 px-4 w-full font-semibold rounded-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>

                    <p className="mt-5 text-sm">닉네임</p>
                    <input
                        value={customerInfo?.nickName}
                        disabled={true}
                        className="mt-1 px-4 w-full font-semibold rounded-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />

                    <p className="mt-5 text-sm">고객메모</p>
                    <input
                        value={memo}
                        onChange={(e) => setMemo(e.target.value)}
                        className="mt-1 px-4 w-full font-semibold rounded-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />

                    <p className="mt-5 text-sm">승인상태</p>
                    <Listbox value={approvalState} onChange={setApprovalState}>
                      {({open}) => (
                          <>
                            <div className="relative mt-2">
                              <Listbox.Button
                                  className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <span className="block truncate">{approvalState.name}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400"/>
                                </span>
                              </Listbox.Button>

                              <Transition
                                  show={open}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                              >
                                <Listbox.Options
                                    className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {approvalStateOption.map((data) => (
                                      <Listbox.Option
                                          key={data.id}
                                          className={({active}) =>
                                              neverLandUtils.classNames(
                                                  active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                              )
                                          }
                                          value={data}
                                      >
                                        {({selected, active}) => (
                                            <>
                                              <span
                                                  className={neverLandUtils.classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                {data.name}
                                              </span>
                                              {selected ? (
                                                  <span className={neverLandUtils.classNames(
                                                      active ? 'text-white' : 'text-indigo-600',
                                                      'absolute inset-y-0 right-0 flex items-center pr-4'
                                                  )}>
                                                    <CheckIcon className="h-5 w-5"/>
                                                  </span>
                                              ) : null}
                                            </>
                                        )}
                                      </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                      )}
                    </Listbox>
                  </div>

                  <div className="mt-5 flex flex-row justify-center sm:mt-6 sm:grid-cols-2 sm:gap-3">
                    <button
                        onClick={editCustomerInfo}
                        type="button"
                        className="w-24 justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    >
                      수정
                    </button>
                    <button
                        type="button"
                        className="w-24 justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                        onClick={closeModal}
                    >
                      취소
                    </button>
                  </div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
  )
}
export default StoreCustomerManagementModal;
