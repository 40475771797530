import Sidebar from "../../components/Sidebar";
import React, {useEffect, useState} from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {ko} from 'date-fns/locale';
import DatePickerInput from "../../components/DatePickerInput";
import Pagination from "../../components/Pagination/Pagination";
import ListSelectBox from "../../components/ListSelectBox";
import {getFetcher} from "../../utils/fetcher";
import {CUSTOMER_LIST_OF_MY_STORE_API} from "../../constants/api_constants";
import {accountInfo, lastMonth, lastWeek, today, yesterday} from "../../constants/constants";
import {toast} from "react-toastify";
import StoreCustomerManagementModal from "../../components/Modal/StoreCustomerManagementModal";
import neverLandUtils from "../../utils/NeverLandUtils";
import {RiSendPlaneFill} from "react-icons/ri";
import SendDirectMessageModal from "../../components/Modal/SendDirectMessageModal";

const searchFilterOption = [
  {name: "전체", code: "all"},
  {name: "닉네임", code: "nick_name"},
  {name: "성명", code: "name"},
  {name: "연락처", code: "phoneNumber"},
]

const StoreCustomerManagementPage = () => {
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [sendDirectMessageModalOpen, setSendDirectMessageModalOpen] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [customerInfo, setCustomerInfo] = useState([]);
  // 검색조건
  const [startDate, setStartDate] = useState(new Date(Date.parse("2020-01-01")));
  const [endDate, setEndDate] = useState(new Date());
  const [searchCondition, setSearchCondition] = useState(searchFilterOption[0]);
  const [searchConditionValue, setSearchConditionValue] = useState('');
  // 페이지네이션
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState('');
  let pageSize = 10;

  const dateFilterButton = [
    {label: '당일', onClick: () => handleDateChange(today, today)},
    {label: '전일', onClick: () => handleDateChange(yesterday, yesterday)},
    {label: '일주일', onClick: () => handleDateChange(lastWeek, today)},
    {label: '한달', onClick: () => handleDateChange(lastMonth, today)},
  ]

  const handleDateChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  function _getFormatDate() {
    let _startDate = neverLandUtils.utils.getDisplayTextByDate(startDate);
    let _endDate = neverLandUtils.utils.getDisplayTextByDate(endDate);
    return _startDate + '-' + _endDate;
  }

  useEffect(() => {
    getCustomerListOfMyStore();
  }, [customerModalOpen, currentPage]);

  const getCustomerListOfMyStore = () => {
    // <-- 지점 고객 리스트 정보 가져오기 -->
    let __searchCondition = `registDate:${_getFormatDate()}` + '▼' + searchCondition.code + ':' + searchConditionValue;

    getFetcher().get(CUSTOMER_LIST_OF_MY_STORE_API + `?ownerId=${accountInfo.accountId()}&searchCondition=${__searchCondition}`
        + `&pageNumber=${currentPage}&pageSize=${pageSize}`)
        .then(res => {
          const {header, result} = res.data;
          if (header.type === 'success') {
            let datalist = result.belongList.content;
            let currentPageNumber = result.belongList.pageable.pageNumber + 1;
            if (datalist.length === 0) {
              currentPageNumber = 1;
            }
            setCustomerList(datalist);
            setCurrentPage(currentPageNumber);
            setTotalPage(result.belongList.totalPages);
          } else {
            toast.error('데이터를 불러오는데 실패하였습니다.');
          }

          // if (header.type === 'success') {
          //   setCustomerList(result.belongList.contents)
          // } else {
          //   toast.error('데이터를 불러오는데 실패하였습니다.');
          // }
        })
  }

  const openModal = (customerData) => {
    setCustomerModalOpen(true);
    setCustomerInfo(customerData);
  }

  function getSortCustomerList(customerList) {
    return customerList.sort((c1, c2) => (c1.userName + "/" + c1.memoForStore).localeCompare(c2.userName + "/" + c2.memoForStore));
  }

  return (
      <>
        {/*고객관리 모달*/}
        {customerModalOpen &&
            <StoreCustomerManagementModal setCustomerModalOpen={setCustomerModalOpen} customerInfo={customerInfo}/>}
        {/*쪽지 보내기 모달*/}
        {sendDirectMessageModalOpen && <SendDirectMessageModal customerList={getSortCustomerList(customerList)}
                                                               setSendDirectMessageModalOpen={setSendDirectMessageModalOpen}/>}

        <Sidebar/>

        <div className="py-10 lg:pl-64">
          <main className="px-4 sm:px-6 lg:px-52 lg:py-12">
            <div className="sm:flex-auto">
              <h1 className="text-2xl font-semibold leading-6 text-gray-900">지점고객관리</h1>
              <p className="mt-3 text-sm text-gray-400">
                지점에 등록된 고객을 관리 합니다.
              </p>
            </div>

            {/*데이트피커*/}
            <div className="text-sm my-5 flex flex-col md:flex-row justify-start mt-5">
              <div className="flex flex-row items-center mb-3 md:mb-0 whitespace-nowrap">
                <span>등록일 :</span>
                <div className="ml-2 flex flex-col sm:flex-row items-center">
                  <div className="flex flex-row items-center">
                    {dateFilterButton.map((data) => (
                        <button
                            key={data.label}
                            onClick={data.onClick}
                            className="mr-2 flex flex-row rounded-3xl bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500"
                        >
                          {data.label}
                        </button>
                    ))}
                  </div>

                  <div
                      className="mt-3 sm:mt-0 mr-3 flex flex-row justify-between px-5 py-2 ring-1 ring-gray-300 rounded-3xl text-center">
                    <DatePicker
                        locale={ko}
                        dateFormat='yyyy-MM-dd'
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        customInput={<DatePickerInput/>}
                    />
                    <span className="mx-2"> ~ </span>
                    <DatePicker
                        locale={ko}
                        dateFormat='yyyy-MM-dd'
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        customInput={<DatePickerInput/>}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-row">
                <ListSelectBox listData={searchFilterOption} onChange={(filter) => setSearchCondition(filter)}/>
                {searchCondition.code !== 'all' &&
                    <input className="mr-3 w-36 px-3 py-2 ring-1 ring-gray-300 rounded-3xl focus:ring-indigo-500"
                           onChange={(e) => setSearchConditionValue(e.target.value)}
                           onKeyDown={(e) => {
                             if (e.key === 'Enter') getCustomerListOfMyStore();
                           }}
                    />
                }
                <button onClick={getCustomerListOfMyStore}
                        className="px-3 py-2 ring-1 ring-gray-300 rounded-3xl hover:bg-gray-100 whitespace-nowrap">
                  조회
                </button>

                { <div
                    className="ml-5 flex flex-row bg-sky-700 text-white items-center px-2 rounded-md cursor-pointer"
                    onClick={() => setSendDirectMessageModalOpen(true)}
                >
                  <RiSendPlaneFill className="h-5 w-auto mr-1"/>
                  <p>쪽지 보내기</p>
                </div>
                }
              </div>
            </div>

            <div className="min-h-[26rem] sm:min-h-[32rem]">
              <table
                  className="min-w-full divide-y divide-gray-300 -mx-4 ring-1 ring-gray-300 sm:-mx-0 sm:rounded-lg bg-gray-900">
                <thead>
                <tr className="whitespace-nowrap text-white font-semibold text-sm text-center">
                  <th scope="col" className="pl-4 px-3 py-3 sm:pl-4">
                    성명
                  </th>
                  <th scope="col" className="hidden px-3 sm:table-cell">
                    닉네임
                  </th>
                  <th scope="col" className="px-3">
                    연락처
                  </th>
                  <th scope="col" className="hidden lg:table-cell">
                    등록일
                  </th>
                  <th scope="col">
                    승인상태
                  </th>
                  <th scope="col" className="hidden lg:table-cell">
                    메모
                  </th>
                  <th scope="col" className="pl-3 pr-4 sm:pr-0">
                  </th>
                </tr>
                </thead>

                <tbody className="divide-y divide-gray-300 bg-white">
                {customerList?.map((data) => (
                    <tr key={data.email} className="hover:bg-indigo-50 whitespace-nowrap text-center text-sm">
                      <td className="py-3 pl-4 pr-3 sm:pl-4">
                        {data.userName}
                      </td>
                      <td className="hidden sm:table-cell">
                        {data.nickName}
                      </td>
                      <td className="">
                        {data.phoneNumber}
                      </td>
                      <td className="hidden lg:table-cell">
                        {data.createDate.split('T')[0]}
                      </td>
                      <td>
                        {data.state === 'regist' && (
                            <span
                                className="inline-flex items-center rounded-full bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700"> 승인대기중 </span>
                        )}
                        {data.state === 'confirmed' && (
                            <span
                                className="inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-700"> 승인 </span>
                        )}
                        {data.state === 'deferring' && (
                            <span
                                className="inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800"> 보류 </span>
                        )}
                        {data.state === 'banned' && (
                            <span
                                className="inline-flex items-center rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-700"> 거부 </span>
                        )}
                      </td>
                      <td className="hidden lg:table-cell">
                        {data.memoForStore}
                      </td>
                      <td className="whitespace-nowrap text-center pr-4 text-sm font-medium">
                        <button
                            onClick={() => openModal(data)}
                            className="text-indigo-600"
                        >
                          관리
                        </button>
                      </td>
                    </tr>
                ))}
                </tbody>
              </table>
            </div>

            <div className="mt-10">
              <Pagination
                  totalPage={totalPage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
              />
            </div>
          </main>
        </div>
      </>
  )
}
export default StoreCustomerManagementPage;