import {EnvelopeIcon, LockClosedIcon} from "@heroicons/react/16/solid";
import React, {useState} from "react";
import {toast} from "react-toastify";
import neverLandUtils from "../utils/NeverLandUtils";
import {getFetcher} from "../utils/fetcher";
import {UNREGISTER_ACCOUNT_API} from "../constants/api_constants";
import {getLogoImage} from "../constants/constants";
import {RiAlertFill, RiEdit2Fill, RiEyeFill, RiEyeOffFill} from "react-icons/ri";


const UnregisterPage = () => {
  const [showInput, setShowInput] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [unregisterReason, setUnregisterReason] = useState('');
  const [agreeTime, setAgreeTime] = useState('');
  const [agreeText, setAgreeText] = useState('');

  const unregister = () => {
    if (!email || !password || !unregisterReason)
      return toast.error("빈칸을 모두 입력하세요");
    if (!agreeTime || !agreeText) {
      return toast.error("체크박스 체크 혹은 동의를 입력하세요.")
    }
    // 비밀번호 암호화해서 넘기기
    const encryptPassword = neverLandUtils.security.encrypt(password);

    getFetcher().delete(UNREGISTER_ACCOUNT_API + `/${email}/${encryptPassword}/${unregisterReason}/${agreeTime + agreeText}`)
        .then(res => {
          const {type, message} = res.data.header;
          if (type === 'fail') {
            toast.error(message);
            return;
          }
          if (type === 'success') {
            toast.success(message);
            setShowInput(false);
          }
        })
        .catch((err) => console.log(err));
  }

  return (
      <>
        <div className="flex min-h-screen flex-1 flex-col justify-center items-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img src={getLogoImage('whaleLogoBlue')} alt="WhaleLogo" className="mx-auto h-20 w-auto"/>
            <img src={getLogoImage('textLogoBlue')} alt="MobydickLogo" className="mx-auto h-7 w-46 mt-4"/>
          </div>

          <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
            {!showInput &&
                <div className="flex flex-col">
                  <p className="text-md mb-2 leading-relaxed font-semibold text-sky-600 text-center"> 서비스 이용자 개인정보 및 사용 이력 삭제 페이지 </p>
                  <p className="text-sm mb-2 leading-relaxed text-center text-gray-500"> ※ 아래 버튼을 클릭하여 삭제가 가능합니다.</p>

                  <button
                      onClick={() => setShowInput(true)}
                      type="submit"
                      className="flex w-full justify-center rounded-md bg-gray-900 px-20 mf:px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm pcSizeHover:hover:bg-gray-700"
                  >
                    계정삭제
                  </button>
                </div>
            }

            {showInput &&
                <div className="space-y-3">
                  <div className="relative rounded-md shadow-sm flex flex-row">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <EnvelopeIcon className="h-5 w-5 text-gray-400"/>
                    </div>
                    <input
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        autoComplete="email"
                        placeholder="이메일"
                        required
                        className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>

                  <div className="relative mt-5 rounded-md shadow-sm flex flex-row items-center">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <LockClosedIcon className="h-5 w-5 text-gray-400"/>
                    </div>
                    <input
                        onChange={(e) => setPassword(e.target.value)}
                        type={showPassword ? "text" : "password"}
                        placeholder="비밀번호"
                        required
                        className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <div className="absolute px-3 text-gray-400 right-0">
                      {showPassword ?
                          <RiEyeFill className="h-5 w-auto"
                                     onClick={() => setShowPassword(!showPassword)}/> :
                          <RiEyeOffFill className="h-5 w-auto"
                                        onClick={() => setShowPassword(!showPassword)}/>
                      }
                    </div>
                  </div>

                  <div className="relative mt-5 rounded-md shadow-sm flex flex-row">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <RiEdit2Fill className="h-5 w-5 text-gray-400"/>
                    </div>
                    <input
                        onChange={(e) => setUnregisterReason(e.target.value)}
                        placeholder="계정삭제 사유"
                        required
                        className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>

                  <div className="flex flex-col">
                    <div className="flex flex-row items-center mb-1">
                      <input type="checkbox"
                             className="h-4 w-4 rounded border-gray-300"
                             onClick={() => setAgreeTime(new Date())}
                      />
                      <RiAlertFill className="h-5 w-auto text-rose-600 ml-2 mr-1"/>
                      <p className="text-sm text-rose-600 font-semibold"> 탈퇴 후 삭제되는 모든 정보는 복구할 수 없습니다. </p>
                    </div>
                    <div className="text-xs leading-relaxed text-rose-600 font-semibold bg-rose-100 py-2 pl-7 rounded-md">
                      <ul>
                        <li className="list-disc">계정 삭제 시 모든 개인정보와 사용 이력이 영구히 삭제되며,<br/> 이후 서비스 이용이 불가하며 재가입이 필요합니다.</li>
                        <li className="list-disc">위 내용을 충분히 이해하셨으며 삭제 진행에 동의하십니까?</li>
                          <li className="list-disc">동의 시 아래에 '동의'를 입력해주세요.</li>
                      </ul>
                    </div>
                  </div>

                  <div className="relative mt-5 rounded-md shadow-sm flex flex-row">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <RiEdit2Fill className="h-5 w-5 text-gray-400"/>
                    </div>
                    <input
                        onChange={(e) => setAgreeText(e.target.value)}
                        placeholder="동의"
                        required
                        className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>

                  <div className="flex flex-row justify-center">
                    <button
                        onClick={unregister}
                        className="flex w-full justify-center rounded-md bg-rose-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm pcSizeHover:hover:bg-rose-500 mr-2"
                    >
                      삭제
                    </button>
                    <button
                        onClick={() => setShowInput(false)}
                        className="flex w-full justify-center rounded-md border border-gray-300 bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-gray-600 shadow-sm"
                    >
                      취소
                    </button>
                  </div>
                </div>
            }
          </div>
        </div>
      </>
  )
}
export default UnregisterPage;