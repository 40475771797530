import Sidebar from "../../components/Sidebar";
import React, {useEffect, useState} from "react";
import AdminPageHeader from "../../components/Header/AdminPageHeader";
import {GAME_ROUND_MANAGEMENT_TABLE_HEADER} from "../../constants/component_data";
import GameRoundManagementModal from "../../components/Modal/GameRoundManagementModal";
import Pagination from "../../components/Pagination/Pagination";
import ListSelectBox from "../../components/ListSelectBox";
import neverLandUtils from "../../utils/NeverLandUtils";
import {customToast} from "../../utils/customToast";
import {toast} from "react-toastify";
import {
  ADMIN_SYNC_GAME_REPORT,
} from "../../constants/api_constants";
import {getFetcher} from "../../utils/fetcher";
import {accountInfo} from "../../constants/constants";
import neverLandGameData from "../../utils/NeverLandGameData";

const siteOption = [
  {name: "배트맨 (메인)", key: "betman"},
  {name: "와이즈토토", key: "wiztoto"},
]

const yearOption = [
  {name: '2024년', value: 2024},
  {name: '2023년', value: 2023},
  {name: '2022년', value: 2022},
]

const GameRoundManagementPage = () => {
  // const [selectYear, setSelectYear] = useState(yearOption[0].value);
  const [showManagementModal, setShowManagementModal] = useState(false);
  const [gameRoundList, setGameRoundList] = useState([]);
  const [gameRoundInfo, setGameRoundInfo] = useState(false);
  const [syncSite, setSyncSite] = useState(siteOption[0]);
  const [thisGameRound, setThisGameRound] = useState(-1);


  const getRound_management_table_body = () => {
    // {year: '2024', round: '32', code:'G101_240032', state: '마감'},
    return gameRoundList;
  }

  function getRound_management(gameRound) {
    let newRoundList = [];
    let thisRoundYear = new Date().getFullYear();
    let thisRoundNumber = 1;
    let thisGameRoundCode;


    if (!gameRound || gameRound === -1) return newRoundList;
    if (gameRound.includes('G101_')) {
      thisRoundYear = parseInt(gameRound.substring(5, 6), 10);
      thisRoundNumber = parseInt(gameRound.substring(7), 10);
    } else {
      thisRoundNumber = gameRound;
      thisGameRoundCode = thisRoundNumber.toString();
      if (thisGameRoundCode.length < 6)
        thisGameRoundCode = thisGameRoundCode.padStart(6 - thisGameRoundCode.length, '0');
      thisGameRoundCode = 'G101_' + (thisRoundYear > 2000 ? (thisRoundYear - 2000) : thisRoundYear) + thisGameRoundCode;
    }
    if (thisRoundYear < 2000) {
      thisRoundYear += 2000;
    }
    //'G101_240032'
    newRoundList.push({year: thisRoundYear, round: thisRoundNumber, code: thisGameRoundCode, state: '진행중'});

    for (let i = 0; i < 10 && thisRoundNumber > 0; i++) {
      --thisRoundNumber;
      thisGameRoundCode = thisRoundNumber.toString();
      if (thisGameRoundCode.length < 6)
        thisGameRoundCode = thisGameRoundCode.padStart(6 - thisGameRoundCode.length, '0');
      thisGameRoundCode = 'G101_' + (thisRoundYear > 2000 ? (thisRoundYear - 2000) : thisRoundYear) + thisGameRoundCode;
      newRoundList.push({year: thisRoundYear, round: thisRoundNumber, code: thisGameRoundCode, state: '마감'});
    }

    return newRoundList;
  }

  const _onChanged = () => {
    if (neverLandGameData.gameRound !== thisGameRound)
      setThisGameRound(neverLandGameData.gameRound);
  }

  useEffect(() => {
    neverLandGameData.addOnChangedHandler(_onChanged);
    return () => {
      neverLandGameData.removeOnChangedHandler(_onChanged);
    };

  }, []);

  useEffect(() => {
    if (neverLandGameData.gameRound === -1) {
      neverLandGameData.reloadGameData();
    } else {
      setGameRoundList(getRound_management(neverLandGameData.gameRound));
    }
  }, [thisGameRound]);

  // useEffect(() => {
  //   // getGameRoundList();
  // }, [selectYear])

  const openManagementModal = (roundData) => {
    // setShowManagementModal(true);
    // setGameRoundInfo(roundData);
  }

  // const getGameRoundList = () => {
  //   // <-- 회차 리스트 받아오기 -->
  //   getFetcher().get(accountInfo.makeGetRequest(ADMIN_GET_GAME_ROUND_LIST_API) + `&year=${selectYear.value}`)
  //       .then(res => {
  //         const {header, result} = res.data;
  //         if (header.type === 'success') {
  //           setGameRoundList(result);
  //         } else {
  //           toast.error(header.message);
  //         }
  //       }).catch(err => console.log(err))
  // }

  function _requestSyncGameReport(name, code) {
    // <-- 선택한 사이트의 게임데이터 가져와서 동기화 시키기 -->
    getFetcher().get(accountInfo.makeGetRequest(ADMIN_SYNC_GAME_REPORT) + `&systemKey=${name}&roundCode=${code}`)
        .then(res => {
          const header = res.data.header;
          if (header.type === 'success') {
            toast.success("[동기화]가 진행됩니다.(" + name + "," + code + ")");
          } else {
            toast.success("[동기화]에 실패하였습니다.(" + name + "," + code + header.message + ")");
          }
        })
  }

  return (
      <>
        {showManagementModal &&
            <GameRoundManagementModal setShowManagementModal={setShowManagementModal} gameRoundInfo={gameRoundInfo}/>}
        <div>
          <Sidebar/>
          <div className="lg:pl-64">
            <AdminPageHeader/>
            <main>
              <div className="flex justify-end mx-40 lg:mt-24">
                {/*<div className="flex flex-row items-center">*/}
                {/*  <p className="font-semibold mr-3 rounded-lg px-3 py-1 bg-emerald-100 border border-emerald-500">조회년도</p>*/}
                {/*  <ListSelectBox listData={yearOption} onChange={setSelectYear}/>*/}
                {/*</div>*/}

                {/*<button*/}
                {/*    onClick={() => openManagementModal({})}*/}
                {/*    type="button"*/}
                {/*    className="ml-auto whitespace-nowrap rounded-md bg-stone-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-stone-800"*/}
                {/*>*/}
                {/*  회차 신규등록*/}
                {/*</button>*/}
              </div>

              <div className="h-full border-t border-white/10 py-6 sm:py-6 sm:min-h-[43rem]">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                  <tr>
                    {GAME_ROUND_MANAGEMENT_TABLE_HEADER.map((data) => {
                      return (
                          <th scope="col"
                              key={data.name}
                              className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-white lg:table-cell bg-stone-900 border-t">
                            <p>{data.name}</p>
                          </th>
                      );
                    })}
                  </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-300 bg-white">
                  {getRound_management_table_body().map((data, index) => {
                    return (
                        <tr key={data.round}
                            className={neverLandUtils.classNames(data.state === '진행중' ? "bg-emerald-50" : "",
                                "hover:bg-blue-50 whitespace-nowrap py-3 px-3 text-center text-sm font-medium text-gray-900")}>
                          <td className="py-2.5">
                            {index + 1}
                          </td>
                          <td>
                            {data.year}
                          </td>
                          <td>
                            {data.round}
                          </td>
                          <td>
                            {data.state}
                          </td>
                          <td className="flex flex-row justify-center items-baseline">
                            <ListSelectBox listData={siteOption} onChange={setSyncSite}/>
                            <button className="rounded-lg px-3 py-1 bg-teal-700 text-white hover:bg-teal-600"
                                    disabled={!accountInfo.getAdminLevel().includes("super")}
                                    onClick={() => customToast.info({
                                          title: "결과 동기화",
                                          message: "[" + syncSite.name + "] 동기화를 수행합니다.\n\n 동기화를 원하시면 아래에 동기화를 입력 후 버튼을 클릭하세요",
                                          input: ["동기화*"],
                                          button: ["수행 [추가입력]", "수행 [전체덮어쓰기]", "취소"],
                                          onClick: (name, input) => {
                                            if (!name.includes("수행"))
                                              return;
                                            if (!input['동기화*'] || input['동기화*'] !== '동기화') {
                                              toast.error("[동기화]를 타이핑해주셔야됩니다.");
                                              return;
                                            }
                                            _requestSyncGameReport(syncSite.key, data.code);
                                          }
                                        }
                                    )}>
                              동기화
                            </button>
                          </td>
                          {/*<td>*/}
                          {/*  <button*/}
                          {/*      onClick={() => openManagementModal(data)}*/}
                          {/*      className="rounded-lg px-3 py-1 bg-blue-900 text-white hover:bg-blue-800 mr-2"*/}
                          {/*  >*/}
                          {/*    관리*/}
                          {/*  </button>*/}
                          {/*  <button*/}
                          {/*      // onClick={}*/}
                          {/*      className="rounded-lg px-3 py-1 bg-rose-600 text-white hover:bg-rose-500"*/}
                          {/*  >*/}
                          {/*    삭제*/}
                          {/*  </button>*/}
                          {/*</td>*/}
                        </tr>
                    )
                  })}
                  </tbody>
                </table>
              </div>

              <div className="mt-10 px-10">
                {/* 페이지네이션 */}
                <Pagination
                    // totalPage={totalPage}
                    // currentPage={currentPage}
                    // setCurrentPage={setCurrentPage}
                />
              </div>
            </main>
          </div>
        </div>
      </>
  )
}
export default GameRoundManagementPage;