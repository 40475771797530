import Sidebar from "../../components/Sidebar";
import MyStoreHeader from "../../components/Header/MyStoreHeader";
import {employee} from "../../utils/_dummyData";
import Pagination from "../../components/Pagination/Pagination";
import React, {useState} from "react";

const StaffManagementPage = () => {
  const [staffEmail, setStaffEmail] = useState('');

  const addStaff = () => {
    // TODO : 직원추가 API 추가
  }

  return (
      <>
        <Sidebar/>
        <div className="lg:pl-64">
          <MyStoreHeader/>
          <main className="py-10">
            <div className="px-4 sm:px-6 lg:px-52 sm:pt-10">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-semibold leading-6 text-gray-900">직원관리</h1>
                  <p className="mt-3 text-sm text-gray-700">
                    지점에 등록된 직원을 관리 합니다.
                  </p>
                </div>

                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex flex-row">
                  <input
                      spellCheck="false"
                      placeholder="추가할 직원 이메일 주소"
                      className="mr-2 block w-52 rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 text-sm sm:leading-6"
                  />
                  <button
                      type="button"
                      className="block rounded-md bg-gray-900 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                  >
                    직원 등록
                  </button>
                </div>
              </div>

              <div className="min-h-[25rem] sm:min-h-[33rem]">
                <div className="-mx-4 mt-8 ring-1 ring-gray-300 sm:-mx-0 sm:rounded-lg bg-gray-900">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                    <tr className="text-white font-semibold text-sm text-center">
                      <th scope="col" className="py-3 pl-4 pr-3 sm:pl-4">
                        성명
                      </th>
                      <th scope="col" className="hidden px-3 sm:table-cell">
                        이메일
                      </th>
                      <th scope="col" className="px-3">
                        연락처
                      </th>
                      <th scope="col" className="hidden lg:table-cell">
                        등록일
                      </th>
                      <th scope="col" className="relative pl-3 pr-4 sm:pr-0">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-300 bg-white">
                    {employee.map((data) => (
                        <tr key={data.phone} className="hover:bg-blue-50 whitespace-nowrap text-center text-sm">
                          <td className="pl-4 py-3 pr-3 sm:pl-4">
                            {data.name}
                          </td>
                          <td className="hidden px-3 sm:table-cell">
                            {data.email}
                          </td>
                          <td>
                            {data.phone}
                          </td>
                          <td className="hidden lg:table-cell">
                            {data.registrationDate}
                          </td>
                          <td className="pl-3 pr-4">
                            <p className="text-indigo-600">
                              관리
                            </p>
                          </td>
                        </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="mt-10">
                {/* 페이지네이션 */}
                <Pagination
                    // totalPage={totalPage}
                    // limit={10}
                    // currentPage={currentPage}
                    // setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          </main>
        </div>
      </>
  )
}
export default StaffManagementPage;