import React from 'react';

// <-- 티켓카드 금액버튼 핸들링 -->
export const UseTicketList = (ticketData, initialState) => {
  const [purchaseAmount, setPurchaseAmount] = React.useState(initialState);

  React.useEffect(() => {
    // 처음 렌더링될 때 데이터에 있는 개별투표금액 객체 담기
    const initialPurchaseAmount = {};
    ticketData.forEach((data) => {
      let currentAmount = Number(data.amount) || 0;
      if(data.newAmount){
          currentAmount=data.newAmount;
      }
      else {
        data.newAmount = currentAmount;
      }
      initialPurchaseAmount[data.id] = currentAmount;
    })
    setPurchaseAmount(initialPurchaseAmount);
  }, [ticketData])
  const handleInputChange = (e, cardId) => {
    let value = e.target.value;
    value = value.replace(/\D/g, '');
    value = Math.floor(Number(value));

    // 인풋에 직접 입력했을 때 개별투표금액
    setPurchaseAmount((prevAmount) => ({
      ...prevAmount, [cardId]: value
    }));
  }
  const handleButtonClick = (cardId, value) => {
    // 금액 버튼 눌렀을때 개별투표금액
    setPurchaseAmount((prevAmount) => ({
      ...prevAmount, [cardId]: Number((purchaseAmount[cardId]|| 0) + value)
    }));
  }
  const resetAmount = (cardId) => {
    // 금액 0으로 초기화
    setPurchaseAmount((prevAmount) => ({
      ...prevAmount, [cardId]: 0
    }));
  }
  //purchaseAmount 클래스로 전달
  return [purchaseAmount, handleInputChange, handleButtonClick, resetAmount];
}

// <-- 알림 모달 핸들링 -->
export const NotifyModalHandler = () => {
  const [isWarningModalOpen, setIsWarningModalOpen] = React.useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);
  const [modalMessageTitle, setModalMessageTitle] = React.useState('');
  const [modalMessageContents, setModalMessageContents] = React.useState('');

  // 경고 모달창 띄우기
  const openWarningModal = (title, contents) => {
    setIsWarningModalOpen(true);
    setModalMessageTitle(title);
    setModalMessageContents(contents);
  }
  // 확인 모달창 띄우기
  const openConfirmModal = (title, contents) => {
    setIsConfirmModalOpen(true);
    setModalMessageTitle(title);
    setModalMessageContents(contents);
  }
  return [isWarningModalOpen, setIsWarningModalOpen, isConfirmModalOpen, setIsConfirmModalOpen, modalMessageTitle, modalMessageContents, openWarningModal, openConfirmModal];
}