import neverLandData from "../../utils/NeverLandGameData";
import React, {useEffect, useState} from "react";
import {UseTicketList} from "../../utils/SimpleComponentUtils";
import {MONEY_BUTTON_DATA} from "../../constants/component_data";
import {RiCloseLine} from "react-icons/ri";
import neverLandUtils from "../../utils/NeverLandUtils";
import {getLogoImage} from "../../constants/constants";
import Decimal from "decimal.js";

const CopyBySimpleTicketCard = ({originTicketData}) => {
  const [purchaseAmount, handleInputChange, handleButtonClick] = UseTicketList(originTicketData, {});
  const [newMemo, setNewMemo] = useState('');
  const [isTicketDataChange, setIsTicketDataChange] = useState(false);
  const [amountOnChange, setAmountOnChange] = useState(false);
  const [refresh, setRefresh] = useState(new Date());

  let ticketData = originTicketData[0];

  function removeCurrentItem(card, allotId) {
    // 티켓내 경기 삭제
    let fSelectedGame = card.selectGame.filter((game) => game.allotId !== allotId);
    card.selectGame = fSelectedGame;
    card.totalRate = neverLandData.getRatingValue(card);

    setRefresh(new Date());
    setIsTicketDataChange(true);
  }

  function _getGameRate(length, amount, rate) {
    // 소수점 계산 오류 방지
    if(rate === null) return 0;
    if(amount === null) return 0;
    const rateDecimal = new Decimal(rate);
    let _rateText = rateDecimal.times(amount).toString();
    return Number(_rateText);
  }


  {
    let amount = purchaseAmount[ticketData.id] || 0;
    if (amount === 0) {
      if (ticketData.currentAmount)
        amount = ticketData.currentAmount;
    }
    return (
        ticketData.selectGame.length === 0 ? null : (
            <div
                className={neverLandUtils.classNames(isTicketDataChange ? "ring-blue-600" : "ring-gray-300", 'px-1 mt-4 bg-white shadow w-ticket flex flex-row ring-2')}>
              <div className="py-3 px-2 w-full"
                   style={{backgroundImage: `url(${getLogoImage('ticketOrange')})`}}>
                <div className="flex flex-row justify-between">
                  <img src={getLogoImage('textLogoBlack')} alt="MobydickLogo"
                       className="object-center object-contain w-32 mx-1"/>
                  <p className="font-semibold text-sm text-right mt-2"> {ticketData.selectGame.length}경기 </p>
                </div>

                <div>
                  <div className="text-xs font-semibold w-full mt-3 text-center">
                    <div className="border-t-2 border-dashed border-gray-500 mb-1"></div>
                    <div className="flex">
                      <div className="w-2/12">경기</div>
                      <div className="w-5/12">홈팀</div>
                      <div>:</div>
                      <div className="w-3/12">원정팀</div>
                      <div className="w-2/12">예상</div>
                      <div className="w-2/12">배당률</div>
                      <div className="w-1/12"></div>
                    </div>
                    <div className="border-t-2 border-dashed border-gray-500 mt-1"></div>
                  </div>
                  {ticketData.selectGame?.map((game, index) => {
                    return (
                        <div key={game.allotId + "_" + index}
                             className="text-xs w-full flex text-center mt-1 items-center px-1 text-black">
                          <div className="w-2/12">{game.gameNumber}</div>
                          <div className="w-5/12">{game.homeTeam}</div>
                          <div>:</div>
                          <div className="w-3/12">{game.awayTeam}</div>
                          <div className="w-2/12">{game.prediction}</div>
                          <div className="w-2/12">{game.rate}</div>
                          <div>
                            <button onClick={() => removeCurrentItem(ticketData, game.allotId)}>
                              <RiCloseLine className="h-4 w-auto"/>
                            </button>
                          </div>
                        </div>
                    );
                  })
                  }
                  <div className="border-t-2 border-dashed border-gray-500 mt-1"></div>
                </div>

                <div className="flex flex-row justify-between items-center mt-3">
                  <p className="text-base font-semibold col-span-6">예상 적중배당률 :</p>
                  <div className="flex flex-row col-span-6">
                    <p className="text-lg font-semibold text-black mr-3">{ticketData.totalRate}</p>
                    <p className="text-lg font-semibold">배</p>
                  </div>
                </div>

                <div className="flex flex-row justify-between items-center mt-3">
                  <p className="text-base font-semibold col-span-6">개별투표금액 :</p>
                  <div className="flex flex-row col-span-6">
                    <input
                        value={(amountOnChange ? (ticketData.currentAmount = amount) : (ticketData.currentAmount = amount).toLocaleString())}
                        onFocus={(e) => {
                          e.target.value = (ticketData.currentAmount = amount);
                          setAmountOnChange(true);
                        }}
                        onBlur={(e) => {
                          e.target.value = (ticketData.currentAmount = amount).toLocaleString();
                          setAmountOnChange(false);
                        }}
                        onChange={(e) => {
                          ticketData.currentAmount = null;
                          handleInputChange(e, ticketData.id);
                          if (!isTicketDataChange) {
                            setIsTicketDataChange(true);
                          }
                        }}
                        className="text-right px-3 w-24 font-semibold rounded-md border-0 py-0.5 pl-2 ml-4 mx-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <p className="text-lg font-semibold">원</p>
                  </div>
                </div>

                <div className="flex flex-row justify-between mt-5">
                  {MONEY_BUTTON_DATA.map((data, index) =>
                      <button
                          key={ticketData.id + data.value + "_" + index}
                          onClick={() => {
                            handleButtonClick(ticketData.id, data.value);
                            if (!isTicketDataChange) {
                              setIsTicketDataChange(true);
                            }
                          }}
                          value={data.value}
                          type="button"
                          className="whitespace-nowrap px-7 py-1 text-sm font-semibold text-white shadow-sm rounded-md bg-orange-600 pcSizeHover:hover:bg-orange-500"
                      >
                        {data.name}
                      </button>
                  )}
                </div>

                <div className="flex flex-row justify-between items-center mt-3">
                  <p className="text-base font-semibold col-span-6">예상 적중금 :</p>
                  <div className="flex flex-row col-span-6">
                    <p className="text-lg font-semibold text-black mr-3">
                      {(_getGameRate(ticketData.selectGame.length, amount, ticketData.totalRate || 0)).toLocaleString() || 0}
                    </p>
                    <p className="text-lg font-semibold">원</p>
                  </div>
                </div>

                <input placeholder="메모" className="ring-1 ring-yellow-500 bg-yellow-100 w-full px-2 text-sm"
                       spellCheck="false"
                       value={newMemo}
                       onChange={(e) => {
                         setNewMemo(ticketData.newMemo = e.target.value);
                         setIsTicketDataChange(true);
                       }}/>
              </div>

              <div className="bg-yellow-200 w-4 ml-3"
                   style={{backgroundImage: `url(${getLogoImage('ticketSideOrange')})`}}></div>
            </div>
        )
    )
  }
}
export default CopyBySimpleTicketCard;
