import axios from "axios";
import {accountInfo} from "../constants/constants";

const {REACT_APP_API_BASE_URL_LOCAL} = process.env;
const {REACT_APP_API_BASE_URL_REAL} = process.env;

// let isLapTestURL= false;

function _getBaseURL(url) {
  let currentPath = window.location.href;

  if (currentPath.includes("https://") && url.includes("http://")) {
    url = "https://" + new URL(currentPath).hostname + "/api/";
  } else {
  }
  return url;
}

function isLapTestURL() {
  return accountInfo.getUserConfig().isOn('on_local_test_mode', false);
}

const fetcherLocal = axios.create({
  baseURL: _getBaseURL(REACT_APP_API_BASE_URL_LOCAL)
});
const fetcher = axios.create({
  baseURL: _getBaseURL(REACT_APP_API_BASE_URL_REAL)
});

export function getFetcher() {
  return isLapTestURL() ? fetcherLocal : fetcher;
}

export function isLocalTestMode() {
  return isLapTestURL();
}
