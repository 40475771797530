import neverLandUtils from "../../utils/NeverLandUtils";
import React from "react";


const MagnifierModeGameCard = ({mouseOverData}) => {

  const BarChart = ({data}) => {
    let win = Number(data.win);
    let draw = Number(data.draw);
    let lose = Number(data.lose);
    win = Math.pow(win, 1.5);
    draw = Math.pow(draw, 1.5);
    lose = Math.pow(lose, 1.5);
    let total = win + draw + lose;

    let winPercentage = (win / total) * 100;
    let drawPercentage = (draw / total) * 100;
    let losePercentage = (lose / total) * 100;

    return (
        <div className="flex w-full h-3 bg-gray-200">
          <div className="bg-blue-300 h-full" style={{width: `${winPercentage}%`}}></div>
          <div className="bg-green-300 h-full" style={{width: `${drawPercentage}%`}}></div>
          <div className="bg-red-300 h-full" style={{width: `${losePercentage}%`}}></div>
        </div>
    );
  }

  return (
      <div className="z-50 fixed bottom-11 left-12 flex flex-col items-center justify-center rounded-2xl min-w-72
                              border-2 border-gray-900 shadow-sm bg-white px-7 pt-7 pb-10 text-indigo-900 font-semibold text-xl">
        <p className={neverLandUtils.classNames(mouseOverData.displayData.gameNo.includes('ⓢ') ? "bg-orange-100 border-orange-600 text-orange-600"
            : "bg-sky-100 border-blue-800 px-5", "p-3 py-2 rounded-3xl border mb-3")}>
          {mouseOverData.displayData.gameNo}
        </p>
        <p className="mb-3 text-3xl text-black">{mouseOverData.displayTitle}</p>
        <p className="text-3xl">
          {(((mouseOverData.displayData.extraText !== null && mouseOverData.displayData.extraText !== 'null' && mouseOverData.displayData.extraText !== '무') ?
                  (mouseOverData.displayData.extra !== '0.0' ?
                      (mouseOverData.displayData.extraText + ' ' + mouseOverData.displayData.extra + " |")
                      : mouseOverData.displayData.extraText + " |")
                  : " ")
              + (mouseOverData.dataType === 'win' ? ((mouseOverData.displayData.extraText !== null && mouseOverData.displayData.extraText === "U/O" ? ' 언더 ' : ' 승 ')
                      + mouseOverData.displayData.win + "|")
                  : (mouseOverData.dataType === 'lose' ? ((mouseOverData.displayData.extraText !== null && mouseOverData.displayData.extraText === "U/O" ? ' 오버 ' : ' 패 ')
                          + mouseOverData.displayData.lose + "|")
                      : (mouseOverData.displayData.draw > 0 ? (' 무 ' + mouseOverData.displayData.draw + "|") : "")))).slice(0, -1)
          }
        </p>
        {/*<div className="w-full pt-3">*/}
        {/*  <BarChart data={mouseOverData.displayData}/>*/}
        {/*</div>*/}
      </div>
  )
}

export default MagnifierModeGameCard;