import React, {Fragment, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {RiCloseLine} from "react-icons/ri";
import DatePicker from "react-datepicker";


const GameRoundManagementModal = ({setShowManagementModal, gameRoundInfo}) => {
  const [open, setOpen] = useState(true);
  const [closingDate, setClosingDate] = useState(new Date(gameRoundInfo?.closingDate));

  const closeModal = () => {
    setOpen(false);
    setShowManagementModal(false);
  }

  return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                    className="relative z-10 transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-96 sm:max-w-lg sm:p-6">

                  <button onClick={closeModal} className="ml-64">
                    <RiCloseLine className="w-5 h-5"/>
                  </button>

                  <div>
                    <div className="text-center sm:my-2">
                      <h3 className="text-xl font-semibold leading-6 text-gray-900">회차관리</h3>
                    </div>
                  </div>

                  <div>
                    <div className="mt-5 flex flex-col">
                      <p className="text-sm">회차년도</p>
                      <input
                          value={gameRoundInfo?.year}
                          className="mt-1 px-4 w-full font-semibold rounded-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>

                    <p className="mt-5 text-sm">회차</p>
                    <input
                        value={gameRoundInfo?.round}
                        className="mt-1 px-4 w-full font-semibold rounded-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />

                    <div className="mt-5 flex flex-col text-sm">
                      <p className="mb-1">마감일시</p>
                      <DatePicker
                          selected={closingDate}
                          onChange={date => setClosingDate(date)}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={10}
                          dateFormat="yyyy/MM/dd HH:mm:ss"
                          placeholderText="날짜와 시간을 선택해주세요"
                          className="w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>

                    <p className="mt-5 text-sm">상태</p>
                    <div className="flex flex-row mt-1">
                      <div className="flex flex-row items-center">
                        <input
                            checked={gameRoundInfo?.state === '마감'}
                            type="radio"
                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <p className="ml-1 text-md font-medium text-gray-900"> 마감 </p>
                      </div>
                      <div className="flex flex-row items-center">
                        <input
                            checked={gameRoundInfo?.state === '진행중'}

                            type="radio"
                            className="ml-5 h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <p className="ml-1 text-md font-medium text-gray-900"> 진행중 </p>
                      </div>
                    </div>
                  </div>

                  <div className="mt-5 flex flex-row justify-center sm:mt-6 grid-cols-2 gap-3">
                    <button
                        type="button"
                        className="w-24 justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    >
                      저장
                    </button>
                    <button
                        type="button"
                        className="w-24 justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                        onClick={closeModal}
                    >
                      취소
                    </button>
                  </div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
  )
}
export default GameRoundManagementModal;
