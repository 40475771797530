import React from "react";

const ReportUpperCard = ({upperTableBodyData}) => {

  return (
      <div
          className="bg-white min-w-[23rem] my-3 whitespace-nowrap flex flex-col justify-center border-2 border-stone-900 rounded-lg mx-2 md:mx-5">

        <div className="flex flex-row border-b border-stone-900">
          <div className="flex flex-row w-2/5 items-center">
            <p className="bg-stone-900 text-white min-w-16 w-14 px-2 py-1 text-center rounded-tl-lg border-b border-stone-300">총건 </p>
            <p className="pl-2">{upperTableBodyData.totalCount?.toLocaleString()}</p>
          </div>
          <div className="flex flex-row w-3/5 justify-start items-center">
            <p className="bg-stone-900 text-white w-24 px-3 py-1 text-center">총투표액</p>
            <p className="pl-2">{upperTableBodyData.totalAmount?.toLocaleString()}</p>
          </div>
        </div>

        <div className="flex flex-row border-b border-stone-900">
          <div className="flex flex-row w-2/5 items-center">
            <p className="bg-stone-900 text-white min-w-16 w-14 px-2 py-1 text-center border-b border-stone-300">적특건 </p>
            <p className="pl-2">{upperTableBodyData.rejectCount?.toLocaleString()}</p>
          </div>
          <div className="flex flex-row w-3/5 justify-start items-center">
            <p className="bg-stone-900 text-white w-24 px-3 py-1 text-center">적특투표액</p>
            <p className="pl-2">{upperTableBodyData.rejectAmount?.toLocaleString()}</p>
          </div>
        </div>

        <div className="flex flex-row w-full border-b border-stone-900 items-center">
          <p className="bg-stone-900 text-white w-24 px-3 py-1 text-center border-b border-stone-300">유효투표액</p>
          <p className="pl-2">{upperTableBodyData.validBettingAmount?.toLocaleString()}</p>
        </div>

        <div className="flex flex-row border-b border-stone-900">
          <div className="flex flex-row w-2/5 items-center">
            <p className="bg-stone-900 text-white min-w-16 w-14 px-3 py-1 text-center border-b border-stone-300">당첨건</p>
            <p className="pl-2">{upperTableBodyData.totalWinningCount?.toLocaleString()}</p>
          </div>
          <div className="flex flex-row w-3/5 justify-start items-center">
            <p className="bg-stone-900 text-white w-24 px-3 py-1 text-center">당첨액</p>
            <p className="pl-2">{upperTableBodyData.totalWinningAmount?.toLocaleString()}</p>
          </div>
        </div>

        <div className="flex flex-row w-full border-b border-stone-900 items-center">
          <p className="bg-stone-900 text-white min-w-16 w-14 px-3 py-1 text-center border-b border-stone-300">차액</p>
          <p className="pl-2">{upperTableBodyData.differenceAmount?.toLocaleString()}</p>
        </div>

        <div className="flex flex-row border-b border-stone-900">
          <div className="flex flex-row w-2/5 items-center">
            <p className="bg-stone-900 text-white min-w-16 w-14 px-2 py-1 text-center border-b border-stone-300">낙첨건</p>
            <p className="pl-2">{upperTableBodyData.totalLosingCount?.toLocaleString()}</p>
          </div>
          <div className="flex flex-row w-3/5 justify-start items-center">
            <p className="bg-stone-900 text-white w-24 px-3 py-1 text-center border-b border-stone-300">낙첨액</p>
            <p className="pl-2">{upperTableBodyData.totalLosingAmount?.toLocaleString()}</p>
          </div>
        </div>

        <div className="flex flex-row">
          <div className="flex flex-row w-2/5 items-center">
            <p className="bg-stone-900 text-white min-w-16 w-14 px-2 py-1 text-center rounded-bl-lg">진행건</p>
            <p className="pl-2">{upperTableBodyData.totalOngoing?.toLocaleString()}</p>
          </div>
          <div className="flex flex-row w-3/5 justify-start items-center">
            <p className="bg-stone-900 text-white w-24 px-3 py-1 text-center">진행액</p>
            <p className="pl-2">{upperTableBodyData.ongoingAmount?.toLocaleString()}</p>
          </div>
        </div>
      </div>
  )
}
export default ReportUpperCard;