import React, {useState} from "react";
import {accountInfo} from "../../constants/constants";
import neverLandUtils from "../../utils/NeverLandUtils";
import {GAME_MANAGEMENT_TABLE_HEADER} from "../../constants/component_data";
import {RiArrowDownSFill, RiArrowUpSFill, RiExpandUpDownFill, RiLockFill} from "react-icons/ri";
import {getGameLogoImgURL} from "./GameCard";

const GameManagementCard = ({
                              gameList,
                              setGameList,
                              selectedLeague,
                              selectedEvent,
                              selectedCategory,
                              selectedState,
                              selectedAmount,
                              isOngoingShow,
                              hasAmount, typingAmount
                            }) => {
  const [showAlignButton, setShowAlignButton] = useState(false);
  const [currentAlignData, setCurrentAlignData] = useState(accountInfo.getAlignDataList("ReportLowerTable"));

  function _sortList(_list) {
    // 금액 CurrentAmount 기준으로 정렬
    let temp;
    let unsorted = true;
    if (temp = currentAlignData["승배당"]) {
      if (temp === 'asc') {
        _list.sort((d1, d2) => {
          return d1.winCurrentAmount - d2.winCurrentAmount;
        });
        unsorted = false;
      }
      if (temp === 'desc') {
        _list.sort((d1, d2) => {
          return d2.winCurrentAmount - d1.winCurrentAmount;
        });
        unsorted = false;
      }
    }
    if (temp = currentAlignData["무배당/기준"]) {
      if (temp === 'asc') {
        _list.sort((d1, d2) => {
          return d1.drawCurrentAmount - d2.drawCurrentAmount;
        });
        unsorted = false;
      }
      if (temp === 'desc') {
        _list.sort((d1, d2) => {
          return d2.drawCurrentAmount - d1.drawCurrentAmount;
        });
        unsorted = false;
      }
    }
    if (temp = currentAlignData["원정배당"]) {
      if (temp === 'asc') {
        _list.sort((d1, d2) => {
          return d1.awayCurrentAmount - d2.awayCurrentAmount;
        });
        unsorted = false;
      }
      if (temp === 'desc') {
        _list.sort((d1, d2) => {
          return d2.awayCurrentAmount - d1.awayCurrentAmount;
        });
        unsorted = false;
      }
    }
    if (unsorted) {
      _list.sort((d1, d2) => {
        return d1.order_index - d2.order_index;
      });
      return _list;
    }
    return _list;
  }

  function makeAlignData(name) {
    let prevData = currentAlignData[name];
    if (prevData === undefined || prevData === "-") {
      currentAlignData[name] = "desc";
    } else if (prevData === "desc") {
      currentAlignData[name] = "asc";
    } else if (prevData === "asc") {
      currentAlignData[name] = "-";
    }
    let newAlignData = {...currentAlignData};
    setCurrentAlignData(newAlignData);
    setGameList(_sortList(gameList));
  }

  return (
      <div className="flex flex-col">
        {showAlignButton &&
            <div className="flex flex-wrap gap-1 mx-2 my-2 md:mx-5 border-2 border-gray-200 p-1 rounded-lg">
              {/* <-- 정렬 버튼 --> */}
              {GAME_MANAGEMENT_TABLE_HEADER.map((data) => {
                if (data.name === "" || !data.useOrder) return;
                return (
                    <div key={data.name}
                         className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-white lg:table-cell bg-gray-600 rounded-lg">
                      <div className="flex items-center justify-center cursor-pointer"
                           onClick={() => makeAlignData(data.name)}>
                        <p>{data.name}</p>
                        {data.useOrder ? (currentAlignData[data.name] !== undefined && currentAlignData[data.name] !== '-' ?
                                (currentAlignData[data.name] === "desc" ?
                                        <RiArrowDownSFill className="h-5 w-5 text-yellow-500 ml-1"/>
                                        : <RiArrowUpSFill className="h-5 w-5 text-sky-500 ml-1"/>
                                ) : <RiExpandUpDownFill className="h-4 w-4 text-gray-400 ml-1"/>
                        ) : <></>
                        }
                      </div>
                    </div>
                );
              })}
            </div>
        }

        <div className="mt-2 mx-3 flex flex-row">
          <button
              onClick={() => setShowAlignButton(prevState => !prevState)}
              className="md:mx-3 w-full whitespace-nowrap rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm">
            {showAlignButton ? "정렬 숨기기" : "정렬 보이기"}
          </button>
        </div>


        <div className="grid grid-cols-1 md:grid-cols-2">
          {gameList.map((data) => {
            let isFiltered = false;
            if (selectedLeague.name !== "리그전체") {
              if (!data.event.includes("/" + selectedLeague.name)) {
                isFiltered = true;
              }
            }
            if (selectedEvent.name !== "종목전체") {
              if (!data.event.includes(selectedEvent.filter + "/")) {
                isFiltered = true;
              }
            }
            if (selectedCategory.name !== "유형전체") {
              if (!data.category.includes(selectedCategory.name)) {
                isFiltered = true;
              }
            }
            if (selectedState.name !== "상태전체") {
              if (!data.state.includes(selectedState.name)) {
                isFiltered = true;
              }
            }
            if (hasAmount) {
              if (data.winCurrentAmount === 0 && data.drawCurrentAmount === 0 && data.awayCurrentAmount === 0) {
                isFiltered = true;
              }
            }
            if (selectedAmount.name !== "금액전체") {
              if (data.winCurrentAmount < selectedAmount.value && data.drawCurrentAmount < selectedAmount.value && data.awayCurrentAmount < selectedAmount.value) {
                isFiltered = true;
              }
            }
            if (selectedAmount.name === "직접입력") {
              if (data.winCurrentAmount < typingAmount && data.drawCurrentAmount < typingAmount && data.awayCurrentAmount < typingAmount) {
                isFiltered = true;
              }
            }

            if (isOngoingShow && data.gameResult !== '-') { // 진행중에 토글이면서 결과값이 안나온것만 필터
              isFiltered = true;
            }

            if (isFiltered)
              return null;

            let category = data.event.substring(0, 2);

            return (
                <div
                    className="bg-white w-[23rem] my-3 whitespace-nowrap flex flex-col justify-center border-2 border-indigo-900 rounded-lg md:mx-3"
                >
                  <div
                      className="flex flex-row w-full border-b border-indigo-900 px-3 py-2 justify-between text-indigo-900 font-semibold">
                    <div>
                      <p className="w-14 py-0.5 text-center rounded-lg bg-indigo-900 text-white ">{data.gameNo}</p>
                    </div>
                    <div className="flex flex-row items-center">
                      <img src={getGameLogoImgURL(category)}
                           className={neverLandUtils.classNames(category === 'BS' ? "h-6 w-6" : "h-5 w-5")}/>
                      <p className="pl-2 pr-1 w-20 text-left">{data.category}</p>
                      <p>{data.gameDate.substring(5, 16)}</p>
                      <p className="pl-3">{data.state}</p>
                    </div>
                  </div>

                  <div
                      className="flex flex-row w-full border-b border-indigo-900 items-center py-2 justify-center text-indigo-900 font-semibold">
                    <p className="pl-3"> {data.team} </p>
                  </div>

                  <div
                      className="flex flex-row w-full border-b border-indigo-900 items-center bg-indigo-900 text-white text-sm">
                    <p className="w-1/4 py-1 text-center">승배당</p>
                    <p className="w-2/4 py-1 text-center border-l border-r border-gray-300">무배당/기준</p>
                    <p className="w-1/4 py-1 text-center">원정배당</p>
                  </div>

                  <div className="flex flex-row w-full border-b border-indigo-900 items-center py-1">
                    <div className="w-1/4 flex flex-col text-center items-center">
                      <div className="flex justify-center mt-1 items-center">
                        <div
                            className={neverLandUtils.classNames(data.gameResult === 'win' ? "ring-2 bg-amber-300 ring-amber-400" : (data.gameResult === 'reject' ? "ring-2 bg-rose-700 ring-rose-800 text-white" : "bg-white ring-1 ring-gray-400")
                                , "flex flex-row items-center justify-center font-semibold w-20 rounded-md py-1 text-gray-900 ring-inset")}>
                          {data.winAdminLocked && <RiLockFill title="관리자 잠금"
                                                              className="h-5 w-auto text-red-600 mr-1.5 cursor-pointer"/>}
                          {data.winPublicLocked && <RiLockFill title="공식 잠금"
                                                               className="h-5 w-auto text-blue-700 mr-1.5 cursor-pointer"/>}
                          <p>{data.winRate}</p>
                        </div>
                      </div>

                      <div>
                        {data.winFixedAmount > 0 ?
                            <p className="py-0.5 font-semibold text-xs text-gray-400">{Number(data.winFixedAmount).toLocaleString()}</p>
                            : <p>&nbsp;</p>
                        }
                      </div>

                      <div>
                        {data.winCurrentAmount > 0 ?
                            <p className="py-0.5 font-semibold text-blue-600">{Number(data.winCurrentAmount).toLocaleString()}</p>
                            : <p>&nbsp;</p>
                        }
                      </div>
                    </div>

                    <div className="w-2/4 flex flex-col justify-center text-center">
                      <div className="flex flex-row justify-center mt-1">
                        <div
                            className={neverLandUtils.classNames(data.gameResult === 'draw' ? "ring-2 bg-amber-300 ring-amber-400" : (data.gameResult === 'reject' ? "ring-2 bg-rose-700 ring-rose-800 text-white" : "bg-white ring-1 ring-gray-400")
                                , "flex flex-row items-center justify-center font-semibold w-20 rounded-md py-1 text-gray-900 ring-inset")}>
                          {data.drawAdminLocked && <RiLockFill title="관리자 잠금"
                                                               className="h-5 w-auto text-red-600 mr-1.5 cursor-pointer"/>}
                          {data.drawPublicLocked && <RiLockFill title="공식 잠금"
                                                                className="h-5 w-auto text-blue-700 mr-1.5 cursor-pointer"/>}
                          <p>{data.drawRate}</p>
                        </div>

                        <div
                            className="bg-white text-teal-600 font-semibold w-20 rounded-md py-1 ring-1 ring-inset ring-gray-400 ml-1">
                          {data.criteria}
                        </div>
                      </div>

                      <div>
                        {data.drawFixedAmount > 0 ?
                            <p className="py-0.5 font-semibold text-xs text-gray-400">{Number(data.drawFixedAmount).toLocaleString()}</p>
                            : <p>&nbsp;</p>
                        }
                      </div>
                      <div>
                        {data.drawCurrentAmount > 0 ?
                            <p className="py-0.5 font-semibold text-blue-600">{Number(data.drawCurrentAmount).toLocaleString()}</p>
                            : <p>&nbsp;</p>
                        }
                      </div>
                    </div>

                    <div className="w-1/4 flex flex-col text-center items-center">
                      <div className="flex justify-center mt-1">
                        <div
                            className={neverLandUtils.classNames(data.gameResult === 'away' ? "ring-2 bg-amber-300 ring-amber-400" : (data.gameResult === 'reject' ? "ring-2 bg-rose-700 ring-rose-800 text-white" : "bg-white ring-1 ring-gray-400")
                                , "flex flex-row items-center justify-center font-semibold w-20 rounded-md py-1 text-gray-900 ring-inset")}>
                          {data.awayAdminLocked && <RiLockFill title="관리자 잠금"
                                                               className="h-5 w-auto text-red-600 mr-1.5 cursor-pointer"/>}
                          {data.awayPublicLocked && <RiLockFill title="공식 잠금"
                                                                className="h-5 w-auto text-blue-700 mr-1.5 cursor-pointer"/>}
                          <p>{data.awayRate}</p>
                        </div>
                      </div>

                      <div>
                        {data.awayFixedAmount > 0 ?
                            <p className="py-0.5 font-semibold text-xs text-gray-400">{Number(data.awayFixedAmount).toLocaleString()}</p>
                            : <p>&nbsp;</p>}
                      </div>
                      <div>
                        {data.awayCurrentAmount > 0 ?
                            <p className="py-0.5 font-semibold text-blue-600">{Number(data.awayCurrentAmount).toLocaleString()}</p>
                            : <p>&nbsp;</p>}
                      </div>
                    </div>
                  </div>

                  <div
                      className="flex flex-row w-full border-b border-indigo-900 items-center justify-center bg-indigo-900 text-white text-sm">
                    <p className="w-1/2 py-1 text-center">홈스코어</p>
                    <p className="w-1/2 py-1 text-center border-l border-gray-300">원정스코어</p>
                  </div>

                  <div className="flex flex-col w-full items-center justify-center my-1">
                    <div className="flex flex-row w-full justify-center">
                      <div className="w-1/2">
                        <p className="text-center bg-white ring-1 ring-gray-400 font-semibold w-16 rounded-md py-1 text-gray-900 ring-inset">
                          {data.homeScore}
                        </p>
                      </div>

                      <div>
                        <p className="text-center bg-white ring-1 ring-gray-400 font-semibold w-16 rounded-md py-1 text-gray-900 ring-inset">
                          {data.awayScore}
                        </p>
                      </div>
                    </div>

                    {/*<div className="font-semibold h-6">*/}
                    {/*  {(data.sumScore && data.sumScore !== '-' && data.category === "언더오버") &&*/}
                    {/*      (data.sumScore > data.criteria ?*/}
                    {/*          <p className="text-center text-green-600">스코어 : {data.sumScore}</p>*/}
                    {/*          : <p className="text-center text-red-600">스코어 : {data.sumScore}</p>)}*/}
                    {/*</div>*/}
                  </div>
                </div>
            )
          })}
        </div>
      </div>
  )
}
export default GameManagementCard;
