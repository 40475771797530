

function replaceWords(text) {
  if(text==null)
    return "";
  const replacements = {
    '승': 'w|',
    'win': 'w|',
    '패': 'l|',
    'lose': 'l|',
    '언더': 'u|',
    'under': 'u|',
    '오버': 'o|',
    'over': 'o|',
    '무': 'd|',
    'draw': 'd|',
    '원': '\n',
  };
  let pattern = new RegExp(Object.keys(replacements).join('|'), 'g');

  return text.replace(pattern, function (match) {
    return replacements[match];
  });
}

function koreanToNumber(korean) {
  const units = {'일': 1, '이': 2, '삼': 3, '사': 4, '오': 5, '육': 6, '칠': 7, '팔': 8, '구': 9};
  const bigUnits = {'십': 10, '백': 100, '천': 1000, '만': 10000, '억': 100000000, '조': 1000000000000};

  let result = '';
  let current = 0;
  let total = 0;
  let supertotal = 0;

  const flushCurrent = () => {
    if (current > 0 || total > 0 || supertotal > 0) {
      result += (total + current + supertotal) + ':';
      supertotal = 0;
      current = 0;
      total = 0;
    }
  };

  for (let i = 0; i < korean.length; i++) {
    const char = korean[i];
    if((current > 0 || total > 0 || supertotal > 0)&&char===','){
      continue;
    }
    else if(char==='번'){
      flushCurrent();
      continue;
    }
    else if ('0' <= char && char <= '9') {
      current = current * 10 + (char - '1' + 1);
    } else if (char in units) {
      current = current * 10 + units[char];
    } else if (char in bigUnits) {
      if (bigUnits[char] > 1000) {
        supertotal += (total + (current) || 1) * bigUnits[char];
        total = 0;
      } else {
        total += (current || 1) * bigUnits[char];
      }
      current = 0;
    } else if (!isNaN(char)) {
      flushCurrent();
      result += char;
    } else {
      flushCurrent();
      result += char;
    }
  }

  flushCurrent();

  return result;
}

function parseToJsonOrder(input) {
      let _transInput = koreanToNumber(replaceWords(input)).replace(' ', '').replace(',', '');
      let result = {
        orignInput: input,
        transInput: _transInput,
        orderData: []
      };
      let orderItem;
      let temp;
      let currentTokens;
      let tempTokens;
      for (let line of _transInput.split('\n')) {
        try {
          temp = line.trim();
          if (temp.length === 0) {
            continue;
          }
          orderItem = {
            amount: 0,
            itemCount: 0,
            allotSelections: []
          };
          currentTokens = temp.split('|');
          for (let item of currentTokens) {
            temp = item.trim();
            if (temp.endsWith(':')) {
              orderItem.amount = Number(temp.replace(':', '')) || 0;
            } else if (temp.includes(':')) {
              tempTokens = temp.split(':');
              temp = tempTokens[1].replace('번', '').trim();
              if (temp !== 'w' && temp !== 'l' && temp !== 'd'&&temp !== 'o' && temp !== 'u') {
                orderItem = {error: '입력 문구에 오류가 있습니다.'};
                break;
              }
              orderItem.allotSelections.push({
                id: tempTokens[0], selection: temp
              });
              orderItem.itemCount++;
            }
          }
        } catch (err) {
          orderItem = {error: '입력 문구에 오류가 있습니다.(' + err.message + ')'};
        }
        if (orderItem.amount === 0 || orderItem.itemCount !== currentTokens.length - 1) {
          orderItem = {error: '입력 문구에 오류가 있습니다.'};
        }
        result.orderData.push(orderItem);
      }
      return result;
    }

export { parseToJsonOrder };
