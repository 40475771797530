import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {getFetcher} from "../../utils/fetcher";
import {BOARD_EDIT_API, BOARD_WRITE_API, GET_BOARD_DETAIL_API} from "../../constants/api_constants";
import {BOARD_DETAIL_PAGE} from "../../constants/page_constants";
import {toast} from "react-toastify";
import Sidebar from "../../components/Sidebar";
import {accountInfo} from "../../constants/constants";
import {getBoardInfo} from "../../constants/board_constants";
import {clearPaginationHandle} from "../../components/Pagination/CustomPagination";

const BoardWritePage = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const location = useLocation();

  let cate_id = new URLSearchParams(location.search).get('cate_id');
  let board_id = new URLSearchParams(location.search).get('board_id');

  useEffect(() => {
    if(board_id) { getPostInfo(); }
  }, []);
  
  const getPostInfo = () => {
    // 수정하기위해 게시글 내용 가져오기
    getFetcher().get(accountInfo.makeGetRequest(GET_BOARD_DETAIL_API) + `&boardId=${board_id}`)
        .then((res) => {
          const type = res.data.header.type;
          if (type === 'success') {
            const {title, content} = res.data.result.boardContentDetail;
            setTitle(title);
            setContent(content);
          }else {
            console.log('데이터 읽어오기 실패')
          }
        })
        .catch((err) => console.log(err));
  }

  const writePost = () => {
    // 게시글 작성하기
    if(!title) {
      toast.warn('제목을 입력하세요');
      return;
    }
    if(!content) {
      toast.warn('내용을 입력하세요');
      return;
    }
    getFetcher().post(BOARD_WRITE_API, {
      user: accountInfo.makeJson(),
      title: title,
      content: content,
      categoryName: getBoardInfo(cate_id).categoryName,
      boardType: null,
      attachment: null,
    }).then((res) => {
      const type = res.data.header.type;
      if (type === 'success') {
        const id = res.data.result.boardId;
        toast.success('게시글 작성이 완료되었습니다.');
        clearPaginationHandle(cate_id);
        navigate(`${BOARD_DETAIL_PAGE}/${id}?cate_id=${cate_id}`);
      } else {
        toast.error('게시글 작성에 실패하였습니다.');
      }
    })
  }

  const editPost = () => {
    getFetcher().post(BOARD_EDIT_API, {
      user: accountInfo.makeJson(),
      title: title,
      content: content,
      boardId: board_id,
    }).then((res) => {
      const type = res.data.header.type;
      if (type === 'success') {
        toast.success('게시글 작성이 완료되었습니다.');
        clearPaginationHandle(cate_id);
        navigate(`${BOARD_DETAIL_PAGE}/${board_id}?cate_id=${cate_id}`);
      } else {
        toast.error('게시글 작성에 실패하였습니다.');
      }
    })
  }

  return (
      <>
        <Sidebar/>
        <div className="lg:pl-64">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-6xl px-6 py-20 lg:px-8">
              <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                  <h1 className="text-2xl font-semibold leading-6 text-gray-900">작성하기</h1>
                </div>
                <div className="mt-20 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <label htmlFor="title" className="ml-1 block font-semibold leading-6">
                        제목
                      </label>
                      <div className="mt-2">
                        <input
                            onChange={(e) => setTitle(e.target.value)}
                            value={title || ''}
                            className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="제목을 입력 하세요."
                            aria-describedby="email-description"
                        />
                      </div>

                      <div>
                        <label htmlFor="content" className="mt-5 ml-1 block font-semibold leading-6">
                          내용
                        </label>

                        <div className="mt-2">
                          <textarea
                              onChange={(e) => setContent(e.target.value)}
                              rows={15}
                              value={content || ''}
                              className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder="내용을 입력 하세요."
                          />
                        </div>
                      </div>

                      <div className="mt-3 flex flex-row justify-between">
                        <button
                            onClick={() => navigate(-1)}
                            className="block rounded-md px-3 py-2 text-sm ring-1 ring-inset ring-gray-300 font-semibold hover:bg-gray-100"
                        >
                          목록으로
                        </button>

                        <button
                            onClick={ board_id ? editPost : writePost}
                            className="block rounded-md px-3 py-2 text-sm ring-1 ring-inset ring-indigo-500 bg-indigo-500 text-white font-semibold hover:bg-indigo-400 hover:ring-indigo-400"
                        >
                          { board_id ? "수정하기" : "작성하기"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  )
}
export default BoardWritePage;