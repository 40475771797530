import React, {useState, useEffect} from 'react';
import {toast} from "react-toastify";

let isChanged = false;

function InitFileLoad({url}) {
  const [fileContent, setFileContent] = useState('');
  const [lastModified, setLastModified] = useState('');
  const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname.includes('192.168.1.');

  useEffect(() => {
    // 버전 정보 확인해서 바뀌었으면 새로고침
    async function fetchTextFile() {
      let targetUrl = url;

      if (isLocalhost) {
        targetUrl = "http://teamneverland.com" + url + "?" + new Date().getTime();
      } else {
        targetUrl = targetUrl + "?" + new Date().getTime();
      }

      try {
        const response = await fetch(targetUrl);
        if (!response.ok) {
          // toast.warning("버전 정보를 가져오는데 실패하였습니다. 인터넷 연결 상황을 점검해주세요.");
          return;
        }
        let text = await response.text(); // 파일 내 버전 정보
        const modified = response.headers.get('last-modified'); // 마지막 수정시간

        // 이전 파일 내용과 비교하여 변경 여부 확인
        if (text !== fileContent) {

          if (text.includes("DOCTYPE html")) {
            text = "test.version[" + window.location.hostname + "]";
          }
          isChanged = fileContent.length > 0;
          setFileContent(text);
        }

        // 이전 수정 날짜와 비교하여 변경 여부 확인
        if (modified !== lastModified) {
          isChanged = lastModified.length > 0;
          setLastModified(modified);
        }
      } catch (error) {
        // toast.warning("버전 정보를 가져오는데 실패하였습니다. 인터넷 연결 상황을 점검해주세요.");
      }
    }

    const intervalId = setInterval(fetchTextFile, 15000); // 15초마다 가져옴

    if (isChanged) {
      // toast.warning("버전 정보가 변경되어 모듈이 초기화됩니다.(2초 뒤)");
      setTimeout(() => window.location.reload(true), 2000);
    }
    return () => clearInterval(intervalId);
  }, [fileContent, lastModified]); // 의존성 배열에 fileContent와 lastModified 추가

  return (
      <div>
        <p className="text-gray-300 text-xs text-right mr-3">{fileContent}</p>
        <p className="text-gray-800 text-xs italic text-center">{lastModified}</p>
      </div>
  );
}

export default InitFileLoad;