import React, {Fragment, useEffect, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import ListSelectBox from "../ListSelectBox";
import CopyByOriginTicketCard from "../Card/CopyByOriginTicketCard";
import CopyBySimpleTicketCard from "../Card/CopyBySimpleTicketCard";
import {toast} from "react-toastify";
import {accountInfo} from "../../constants/constants";
import {getFetcher} from "../../utils/fetcher";
import {GET_MY_STORE_LIST_API, REGIST_NEW_GAME_TICKET_API} from "../../constants/api_constants";
import {customToast} from "../../utils/customToast";
import {SHEET_QUANTITY} from "../../constants/component_data";

const CopyTicketRequestModal = ({ticketData, onClose, ticketOrderType}) => {
  const [ticketList, setTicketList] = useState([]);
  const [open, setOpen] = useState(true);
  const [selectedSheetQuantity, setSelectedSheetQuantity] = useState(SHEET_QUANTITY.find((item) => {
    return item.value === Number(ticketData.copyNumber);
  }));
  const [requestStoreCode, setRequestStoreCode] = useState('');

  useEffect(() => {
    if (ticketList.length !== Number(selectedSheetQuantity.value)) {
      setTicketList(getTicketList(ticketData));
    }
  }, [selectedSheetQuantity]);

  useEffect(() => {
    // 요청할 지점 코드 설정
    // 지점이면 내 지점코드 / 지점이 아니면 요청했던 지점코드로
    if (accountInfo.isStore()) {
      setRequestStoreCode(accountInfo.getStoreCode());
    } else {
      getRequestStoreCode();
    }
  }, []);

  const closeModal = (res) => {
    setOpen(false);
    onClose(res);
  }


  const getRequestStoreCode = () => {
    // 내 지점 리스트 가져와서 기존 요청한 지점이름으로 코드찾아서 넣기
    const prevRequestStoreName = ticketData.chargerName.split('|')[0].trim();

    getFetcher().get(accountInfo.makeGetRequest(GET_MY_STORE_LIST_API))
        .then(res => {
          const storeList = res.data.result.myStoreList;
          let _requestStore = storeList.find(store => store.name === prevRequestStoreName);
          setRequestStoreCode(_requestStore.code);
        });
  }

  const getTicketList = (ticketData) => {
    // 복사할 수량만큼 티켓데이터 리스트에 담기
    let thisTicketDataList = [];
    for (let index = 0; index < Number(selectedSheetQuantity.value); index++) {
      let currentTicketData = {...ticketData};
      currentTicketData.currentAmount = currentTicketData.amount;
      currentTicketData.newMemo = "-";
      currentTicketData.index = index;
      thisTicketDataList.push([currentTicketData]);
    }
    return thisTicketDataList;
  }

  const requestCopyTickets = (ticketList) => {
    // 복사 요청하기
    if (ticketList === null || ticketList.length === 0) {
      toast.warning("복사할 티켓이 없습니다.");
      return;
    }

    let user = accountInfo.makeJson();
    let postJson = {
      user: user,
      list: [],
      targetStoreCode: requestStoreCode,
      defaultStatus: 'order'
    };

    let ticket_data;
    let sent_ticket_list = [];
    let ticket;
    let defaultMemo = "방문예정일시 : 2024. 06. 27. 17:28) ";

    for (let ticketItem of ticketList) {
      ticket = ticketItem[0];
      console.log('복사요청 ticket ->', ticket)
      if (ticket.currentAmount === 0)
        continue;
      if (ticket.selectGame.length === 0)
        continue;
      defaultMemo = "방문예정일시 : " + ticket.reservDate + ") ";
      if (ticket.newMemo && ticket.newMemo.trim().length === 0)
        defaultMemo += "-"; else defaultMemo += ticket.newMemo.trim();
      ticket_data = {
        gameAllotCodesAndSelection: [],
        amount: ticket.currentAmount,
        unitType: 'won',
        memo: defaultMemo,
        ticketCount: 1
      };
      sent_ticket_list.push(ticket.id);
      for (let game of ticket.selectGame) {
        ticket_data.gameAllotCodesAndSelection.push({
          allotId: game.allotId,
          gameNo: game.gameNumber,
          selection: game.prediction
        });
      }
      postJson.list.push(ticket_data);
    }
    if (0 === postJson.list.length) {
      toast.warning("금액을 입력해 주세요.");
      return;
    }
    // if (ticketList.length !== postJson.list.length) {
    //   toast.warning("금액을 입력해 주세요.");
    //   return;
    // }

    getFetcher().post(REGIST_NEW_GAME_TICKET_API, postJson)
        .then(res => {
          const {type, message} = res.data.header;
          if (type === 'success') {
            toast.success(message);
            closeModal(true);
          } else {
            toast.error(message);
          }
        }).catch(err => {
      toast.error(err.message);
    })
  }

  function getTicketListWithGames() {
    let _ticketList = [];

    for (let t of ticketList) {
      if (t[0].selectGame.length === 0) {
        continue;
      }
      _ticketList.push(t);
    }
    return _ticketList;
  }

  return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                    className="relative transform  rounded-lg bg-white lg:px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-[50rem] md:p-6 mx-auto">

                  <div className="flex flex-col">
                    {/* <-- 티켓영역 --> */}
                    <div className="mt-10 lg:mt-0 flex flex-col md:flex-row justify-between md:max-h-[40rem]">
                      <div className=" flex flex-col justify-center mr-1 items-center">
                        {/* 원본 티켓 */}
                        <CopyByOriginTicketCard ticketData={ticketData} ticketOrderType={ticketOrderType}/>
                      </div>

                      {/* md 사이즈 이상에서 보이는 복사 티켓 */}
                      <div className="hidden md:block border-l-2 border-dotted border-gray-400"/>

                      <div className="hidden md:block overflow-y-auto overflow-x-hidden">
                        <div className="px-1.5 pb-2">
                          {/* 복사 할 티켓 */}
                          {getTicketListWithGames().map((data, index) => {
                            return (<CopyBySimpleTicketCard key={index} originTicketData={data}/>)
                          })}
                        </div>
                      </div>
                    </div>

                    {/* <-- 버튼 영역 --> */}
                    <div className="flex flex-row justify-center md:justify-end mt-2 items-center">
                      <div className="flex flex-row items-center">
                        <p className="mr-2 pt-2 lg:pt-0">총 복사건수</p>
                        <ListSelectBox defaultValue={selectedSheetQuantity} listData={SHEET_QUANTITY}
                                       onChange={setSelectedSheetQuantity}/>
                      </div>
                      <div className="pt-2 lg:pt-0">
                        <button
                            onClick={() => {
                              let thisTicketList = getTicketListWithGames();
                              customToast.info({
                                    title: "복사요청",
                                    message: "총 [" + thisTicketList.length + "]장을 복사 요청합니다.\n\n 정말 진행하시겠습니까?",
                                    button: ["요청하기", "취소"],
                                    onClick: (name) => {
                                      if (!name.includes("요청하기"))
                                        return;
                                      requestCopyTickets(thisTicketList);
                                    }
                                  }
                              )
                            }}
                            className="whitespace-nowrap mr-2 lg:mb-0 rounded-md bg-blue-600 px-3 lg:px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500"
                        >
                          복사요청
                        </button>
                        <button
                            onClick={closeModal}
                            className="inline-flex rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm sm:mt-0 sm:w-auto"
                        >
                          취소
                        </button>
                      </div>
                    </div>

                    {/* sm 사이즈에서 보이는 복사 티켓 */}
                    <div className="block md:hidden my-5 mx-2 border-b-2 border-dotted border-gray-400"/>
                    <div className="block md:hidden max-h-[33rem] md:max-h-[46rem] overflow-y-auto overflow-x-hidden">
                      <div className="px-1.5 pb-2">
                        {ticketList.map((data, index) => {
                          return (<CopyBySimpleTicketCard key={index} originTicketData={data}/>)
                        })}
                      </div>
                    </div>
                  </div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
  )
}
export default CopyTicketRequestModal;
