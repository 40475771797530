import neverLandData from "../../utils/NeverLandGameData";
import neverLandGameData from "../../utils/NeverLandGameData";
import React, {useState} from "react";
import {UseTicketList} from "../../utils/SimpleComponentUtils";
import {MONEY_BUTTON_DATA, MONEY_BUTTON_DATA_SIMPLE, SHEET_QUANTITY} from "../../constants/component_data";
import {RiCloseLine} from "react-icons/ri";
import neverLandUtils from "../../utils/NeverLandUtils";
import {getLogoImage} from "../../constants/constants";
import ListSelectBox from "../ListSelectBox";
import Decimal from "decimal.js";

function getImageKey(gameDataResult) {
  if (gameDataResult === undefined)
    return 'ticketOrange';
  if (gameDataResult === "lose") {
    return 'ticketLose';
  }
  if (gameDataResult.includes("win")) {
    return 'ticketWin';
  }
  return 'ticketOrange';
}

const TicketCard = ({batchPrice, isPoweredObject, selectTicketDataList, ticketType}) => {
  const [isSelectedTickets, setIsSelectedTickets] = useState([]);
  const [purchaseAmount, handleInputChange, handleButtonClick, resetAmount] = UseTicketList(neverLandData.ticketData, {});
  const [amountOnChange, setAmountOnChange] = useState(false);
  const [currentData, setCurrentData] = useState({list: []});
  const [selectedSheetQuantity, setSelectedSheetQuantity] = useState(SHEET_QUANTITY[0].value);

  // 구매금액 일괄입력 관련 우선순위
  const {isPoweredRequest, disablePowered} = isPoweredObject;
  disablePowered();

  const handleSelectTicketChange = (id) => {
    // 티켓 선택
    setIsSelectedTickets((prevTickets) => {
      if (prevTickets.includes(id)) {
        return prevTickets.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevTickets, id];
      }
    })
  }

  function removeCurrentItem(card, allotId) {
    // 티켓내 경기 삭제
    let fSelectedGame = card.selectGame.filter((game) => game.allotId !== allotId);
    if (fSelectedGame.length === 0) {
      neverLandData.ticketData = neverLandData.ticketData.filter((_card) => _card.id !== card.id);
    } else {
      card.selectGame = fSelectedGame;
      card.totalRate = neverLandData.getRatingValue(card);
    }
    setIsSelectedTickets(prevTickets => {
      return [...prevTickets];
    });
  }

  if (currentData.list !== neverLandData.ticketData) {
    currentData.list = neverLandData.ticketData;
    if (isSelectedTickets.length > 0)
      setIsSelectedTickets([]);
  }
  let amount;
  if (isSelectedTickets !== null && isSelectedTickets.length > 0) {
    let _current_selected_ticket = [];
    for (let card of currentData.list) {
      if (isSelectedTickets.includes(card.id)) {
        amount = purchaseAmount[card.id] || 0;
        if (isPoweredRequest) {
          amount = batchPrice;
        }
        if (amount === 0) {
          if (card.currentAmount)
            amount = card.currentAmount;
        }
        _current_selected_ticket.push({
          amount: amount,
          selectedGame: card.selectGame,
          id: card.id,
          totalRate: card.totalRate,
          ticketCount: selectedSheetQuantity[card.id] || 1
        });
      }
    }
    selectTicketDataList(_current_selected_ticket);
  } else {
    selectTicketDataList([]);
  }

  const handleSheetQuantity = (value, id) => {
    // 티켓 매수 선택
    setSelectedSheetQuantity((prevQuantity) => ({
      ...prevQuantity, [id]: value
    }));
  }

  function deleteTicketCard(card) {
    // 선택한 티켓 삭제
    neverLandData.ticketData = neverLandData.ticketData.filter((_card) => _card.id !== card.id);

    setIsSelectedTickets(prevTickets => {
      return [...prevTickets.filter((card) => card.id !== card.id)];
    });
  }

  const getPrediction = (gameNumber, prediction) => {
    // <-- 언더/오버 일때 승,패 대신 언더/오버 -->
    let _prediction = prediction;
    if (gameNumber.includes('U')) {
      if (prediction === '승') _prediction = '언더';
      if (prediction === '패') _prediction = '오버';
    }
    return _prediction;
  }

  function _getGameRate(length, amount, rate) {
    // 소수점 계산 오류 방지
    if(rate === null) return 0;
    if(amount === null) return 0;
    const rateDecimal = new Decimal(rate);
    let _rateText= rateDecimal.times(amount).toString();
    return Number(_rateText);
  }

  return (
      <div>
        {currentData.list?.map((card) => {
          if (card.selectGame.length === 0)
            return <></>;

          let amount = purchaseAmount[card.id] || 0;

          if (isPoweredRequest) {
            amount = batchPrice;
            purchaseAmount[card.id] = amount;
          } else if (amount === 0) {
            // if (card.currentAmount)  = { if (card.currentAmount!==undefined || card.currentAmount!==null|| card.currentAmount!==0  ) {
            if (card.currentAmount !== undefined) {
              amount = card.currentAmount;
            } else {
              amount = batchPrice;
            }
          }
          return (
              <div key={card.id}
                   className={neverLandUtils.classNames(
                       isSelectedTickets.includes(card.id) ? 'ring-4 ring-orange-600' : 'ring-1 ring-gray-300',
                       'px-1 mt-4 bg-white shadow w-ticket flex flex-row'
                   )}
              >
                <div className="py-5 px-2 w-full"
                     style={{backgroundImage: `url(${getLogoImage(getImageKey(card.gameDataResult))})`}}>

                  <div className="flex flex-col">
                    <div className="flex flex-row justify-between">
                      <div className="flex flex-row items-center cursor-pointer" onClick={() => {
                        if (ticketType.selection) handleSelectTicketChange(card.id)
                      }}>
                        {ticketType.selection && <input
                            checked={isSelectedTickets.includes(card.id)}
                            type="checkbox"
                            className="mr-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />}
                        <img src={getLogoImage('textLogoBlack')} alt="MobydickLogo"
                             className="object-center object-contain w-36 mr-4"/>
                      </div>
                      <ListSelectBox listData={SHEET_QUANTITY} onChange={(e) => handleSheetQuantity(e.value, card.id)}/>
                      <button className="inline-block"
                              onClick={() => deleteTicketCard(card)}
                      >
                        <RiCloseLine className="h-7 w-7 text-black"/>
                      </button>
                    </div>
                    {!ticketType.isSimpleMode &&
                        <div>
                          <div className="flex flex-row mt-2 justify-between">
                            <p className="text-xl font-bold tracking-tight text-gray-900"> 프로토(승부식)</p>
                            <p className="text-xl font-bold tracking-tight text-gray-900">{card.number}회차 </p>
                          </div>
                          <p className="text-sm text-right mt-2">경기시작 : {card.closingDate.substring(0, 10)}</p>
                        </div>
                    }
                    <p className="font-semibold text-sm text-right mt-2"> {card.selectGame.length}경기 선택 </p>
                  </div>

                  <div>
                    <div className="text-xs font-semibold w-full mt-3 text-center">
                      <div className="border-t-2 border-dashed border-black mb-1"></div>
                      <div className="flex">
                        <div className="w-2/12">경기</div>
                        <div className="w-5/12">홈팀</div>
                        <div>:</div>
                        <div className="w-3/12">원정팀</div>
                        <div className="w-2/12">예상</div>
                        <div className="w-2/12">배당률</div>
                        <div className="w-1/12"></div>
                      </div>
                      <div className="border-t-2 border-dashed border-black mt-1"></div>
                    </div>
                    {(
                        <div key={card.id}>
                          {card.selectGame?.map((game) => {
                            let isFixedData = neverLandGameData.fixedSelectedAllotData.includes(game.itemKey);
                            return (
                                <div key={game.allotId}
                                     className={neverLandUtils.classNames(isFixedData ? "bg-amber-300" : "", "text-xs w-full flex text-center mt-1 items-center px-1 text-black")}>
                                  <div className="w-11 flex flex-row justify-between">
                                    <div className="w-3">
                                      {(game.gameNumber.includes("U") || game.gameNumber.includes("H")) &&
                                          <p>
                                            {(game.gameNumber.includes("U") || game.gameNumber.includes("H")) ? game.gameNumber.substring(0, 1) : ""}
                                          </p>
                                      }
                                    </div>
                                    <p className={neverLandUtils.classNames(game.gameNumber.includes('*') ? "" : "ml-1", "w-8")}>
                                      {(game.gameNumber.includes("U") || game.gameNumber.includes("H")) ? game.gameNumber.substring(1) : game.gameNumber}
                                    </p>
                                  </div>
                                  <div className="w-5/12 pl-3 text-left whitespace-nowrap">
                                    <p>{game.homeTeam}</p>
                                  </div>
                                  <div>:</div>
                                  <div className="w-3/12 px-1.5 text-left whitespace-nowrap">{game.awayTeam}</div>
                                  <div className="w-2/12">{getPrediction(game.gameNumber, game.prediction)}</div>
                                  <div className="w-2/12">{game.rate}</div>
                                  <div>
                                    <button onClick={() => removeCurrentItem(card, game.allotId)}>
                                      <RiCloseLine className="h-4 w-auto"/>
                                    </button>
                                  </div>
                                </div>
                            );
                          })}
                        </div>
                    )}
                    <div className="border-t-2 border-dashed border-black mt-1"></div>
                  </div>

                  <div className="flex flex-row justify-between items-center mt-3 font-semibold">
                    <p className="w-1/2 text-right">예상 적중배당률 :</p>
                    <div className="flex flex-row">
                      <p className="text-black mr-3">{card.totalRate}</p>
                      <p>배</p>
                    </div>
                  </div>

                  <div className="flex flex-row justify-between items-center mt-3 font-semibold">
                    <label className="w-1/2 text-right" htmlFor="amount">개별투표금액 :</label>
                    <div
                        className="flex flex-row rounded-md border-0 ring-1 ring-inset ring-gray-300 py-0.5 pl-2 ml-4 mr-2">
                      <input
                          id="amount"
                          value={(amountOnChange ? (card.currentAmount = amount) : (card.currentAmount = amount).toLocaleString())}
                          onFocus={(e) => {
                            e.target.value = (card.currentAmount = amount);
                            setAmountOnChange(true);
                            // 커서 제일 끝으로 보내기
                            setTimeout(() => {
                              const length = e.target.value.length;
                              e.target.setSelectionRange(length, length);
                            }, 0);
                          }}
                          onBlur={(e) => {
                            card.currentAmount = amount || 0;
                            e.target.value = card.currentAmount.toLocaleString();
                            setAmountOnChange(false);
                          }}
                          onChange={(e) => {
                            card.currentAmount = null;
                            handleInputChange(e, card.id);
                          }}
                          className="text-left w-20 font-semibold text-gray-900 sm:text-sm sm:leading-6 focus:ring-0 focus:ring-white"
                      />
                      <button type="reset">
                        <RiCloseLine className="h-4 w-auto px-1"
                                     onClick={() => {
                                       card.currentAmount = 0;
                                       resetAmount(card.id)
                                     }}/>
                      </button>
                    </div>
                    <p>원</p>
                  </div>

                  <div className="flex flex-row justify-between mt-5">
                    {(ticketType.isSimpleMode ? MONEY_BUTTON_DATA_SIMPLE : MONEY_BUTTON_DATA).map((data) =>
                        <button
                            key={card.id + data.value}
                            onClick={() => {
                              purchaseAmount[card.id] = amount;
                              handleButtonClick(card.id, data.value)
                            }}
                            value={data.value}
                            type="button"
                            className="whitespace-nowrap px-7 py-1 text-sm font-semibold text-white shadow-sm rounded-md bg-orange-600 pcSizeHover:hover:bg-orange-500"
                        >
                          {data.name}
                        </button>
                    )}
                  </div>

                  <div className="flex flex-row justify-between items-center mt-3 font-semibold">
                    <p className="w-1/2 text-right">예상 적중금 :</p>
                    <div className="flex flex-row col-span-6">
                      <p className="mr-3">
                        {(_getGameRate(card.selectGame.length, amount, card.totalRate || 0)).toLocaleString() || 0}
                      </p>
                      <p>원</p>
                    </div>
                  </div>

                  {!ticketType.isSimpleMode &&
                      <div className="flex flex-row items-center mt-4 text-lg font-semibold">
                        <p className="mr-3">총 투표금액</p>
                        <p className="mr-1">{amount?.toLocaleString()}</p>
                        <p>원</p>
                      </div>
                  }
                </div>

                <div className="bg-yellow-200 w-5 ml-3"
                     style={{backgroundImage: `url(${getLogoImage('ticketSideOrange')})`}}></div>
              </div>
          )
        })}
      </div>
  )
}
export default TicketCard;
