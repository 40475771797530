import {Dialog, Transition} from '@headlessui/react'
import {Link, useNavigate} from "react-router-dom";
import React, {Fragment, useState} from "react";
import {Bars3Icon, XMarkIcon} from "@heroicons/react/16/solid";
import {
  SIDEBAR_AUTHORITY_MENU,
  SIDEBAR_DEVELOPER_MENU,
  SIDEBAR_LOWER_MENU,
  SIDEBAR_UPPER_MENU
} from "../constants/component_data";
import {EXCEL_LOAD_PAGE, LOGIN_PAGE, MAIN_PAGE, MY_INFO_PAGE, MY_STORE_INFO_PAGE} from "../constants/page_constants";
import neverLandUtils from "../utils/NeverLandUtils";
import {accountInfo, getLogoImage, MOBYDICK_VERSION_URL, USER_INFO} from "../constants/constants";
import {toast} from "react-toastify";
import SideBarLoginButton from "./SideBarLoginButton";
import InitFileLoad from "../utils/component/InitFileLoad";
import {RiVerifiedBadgeFill} from "react-icons/ri";
import AddFavoriteStoreModal from "./Modal/AddFavoriteStoreModal";
import NeverlandNotification from "./NeverlandNotification";
import neverLandGameData from "../utils/NeverLandGameData";
import {isLocalTestMode} from "../utils/fetcher";
import ScrollButton from "./ScrollButton";

function getLink(link) {
  let temp = link.link;
  let index = temp.indexOf('|');

  if (index > 0) {
    temp = temp.substring(0, index);
  }

  let param = link.parameter;
  if (param) {
    temp += "/" + param;
  }
  return temp;
}

const Sidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showAddStoreModal, setShowAddStoreModal] = useState(false);
  const [refresh, setRefresh] = useState(new Date());
  const myLocalInfo = neverLandUtils.commons.load(USER_INFO);
  const navigate = useNavigate();

  const logOut = () => {
    neverLandUtils.commons.clear();
    accountInfo.saveLastNavigation(null);
    neverLandGameData.reloadGameData();
    navigate(MAIN_PAGE);
    toast.success('로그아웃 되었습니다.');
  }

  let _onNavigate = false;

  function goNavigate(MAIN_PAGE) {
    // 로그인이 필요한 페이지에 접근하려고 할 때 메인으로 보내기
    if (_onNavigate)
      return;
    // 두번 실행안되게
    _onNavigate = true;
    setTimeout(function () {
      if (accountInfo.isLogin()) {
        return;
      }
      toast.error('로그인이 필요한 페이지입니다.');
      navigate(MAIN_PAGE);
      _onNavigate = false;
    }, 1);
  }

  return (
      <>
        {showAddStoreModal && <AddFavoriteStoreModal setShowAddModal={setShowAddStoreModal}/>}
        <ScrollButton/>

        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80"/>
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white"/>
                      </button>
                    </div>
                  </Transition.Child>

                  {/* <-- 모바일 사이드바 --> */}
                  <div
                      className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                    {
                      accountInfo.isMonitoringAdminMode() ?
                          <div
                              className="cursor-pointer mt-5 flex xh-16 shrink-0 justify-center bg-rose-800 text-white rounded-md p-2"
                              onClick={() => {
                                accountInfo.rollbackMonitoringAdmin();
                                setRefresh(new Date());
                                navigate(MAIN_PAGE);
                              }}
                          >
                            <p>점검모드 [돌아가기]</p>
                          </div>
                          : <div className="mt-5 flex xh-16 shrink-0 justify-center">
                            <Link to={MAIN_PAGE} className="flex flex-row items-center">
                              <img src={getLogoImage('whaleLogoMix')} alt="WhaleLogo" className="h-10 w-auto"/>
                              <img src={getLogoImage('textLogoWhite')} alt="MobydickLogo" className="ml-4 h-5 w-32"/>
                            </Link>
                          </div>
                    }

                    <nav className="flex flex-1 flex-col">
                      <div role="list" className="flex flex-1 flex-col gap-y-7">
                        <div
                            className="flex flex-1 flex-col gap-y-7 max-h-[40rem] overflow-y-auto px-3">
                          <div role="list" className="-mx-2 space-y-1 ">
                            {SIDEBAR_UPPER_MENU.map((item) => {
                              if (item.name.includes("ⓒ")) {
                                if (accountInfo.isStore())
                                  return null;
                              }
                              if (item.name.includes('*') && !accountInfo.isLogin()) {
                                if (item.current) {
                                  goNavigate(MAIN_PAGE);
                                }
                                return null;
                              }
                              return (
                                  <Link
                                      key={item.name}
                                      to={getLink(item)}
                                      className={neverLandUtils.classNames(
                                          item.current ? 'bg-gray-700 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-700',
                                          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                      )}
                                  >
                                    <item.icon className="h-6 w-6 shrink-0"/>
                                    {item.name.replaceAll('*', '').replaceAll('ⓒ', '').replaceAll('ⓐ', '').replaceAll('ⓢ', '')}
                                    {item.notifyKey &&
                                        <NeverlandNotification programKey={'mobydick'} notifyKey={item.notifyKey}
                                                               accountKey={accountInfo.accountId()}/>
                                    }
                                  </Link>
                              );
                            })}
                          </div>

                          <div>
                            <div className="mb-2 text-xs font-semibold leading-6 text-gray-400">
                              {accountInfo.isStore() ? "지점메뉴" : accountInfo.isAdmin() ? "관리자 메뉴" : ""}
                            </div>
                            <div role="list" className="-mx-2 space-y-1 ">
                              {SIDEBAR_AUTHORITY_MENU.map((item) => {
                                if (item.name.includes('ⓐ') && !accountInfo.isAdmin()) {
                                  if (item.current) {
                                    goNavigate(MAIN_PAGE);
                                  }
                                  return null;
                                }
                                if (item.name.includes('ⓢ') && !accountInfo.isStore()) {
                                  if (item.current) {
                                    goNavigate(MAIN_PAGE);
                                  }
                                  return null;
                                }
                                if (item.name.includes('*') && !accountInfo.isLogin()) {
                                  if (item.current) {
                                    goNavigate(MAIN_PAGE);
                                  }
                                  return null;
                                }
                                return (
                                    <Link
                                        key={item.name}
                                        to={getLink(item)}
                                        className={neverLandUtils.classNames(
                                            item.current ? 'bg-gray-700 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-700',
                                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold flex-row'
                                        )}
                                    >
                                      <item.icon className="h-6 w-6 shrink-0"/>
                                      <span
                                          className="truncate">
                                        {item.name.replaceAll('*', '').replaceAll('ⓐ', '').replaceAll('ⓢ', '')}
                                      </span>
                                      {item.notifyKey &&
                                          <NeverlandNotification programKey={'mobydick'} notifyKey={item.notifyKey}
                                                                 accountKey={accountInfo.accountId()}/>
                                      }
                                    </Link>
                                );
                              })}
                            </div>
                          </div>

                          <div>
                            <div className="mb-2 text-xs font-semibold leading-6 text-gray-400">커뮤니티</div>
                            <div role="list" className="-mx-2 space-y-1 ">
                              {SIDEBAR_LOWER_MENU.map((item) => {
                                if (item.name.includes('*') && !accountInfo.isLogin()) {
                                  if (item.current) {
                                    goNavigate(MAIN_PAGE);
                                  }
                                  return null;
                                }
                                return (
                                    <Link
                                        key={item.name}
                                        to={getLink(item)}
                                        className={neverLandUtils.classNames(
                                            item.current ? 'bg-gray-700 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-700',
                                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                        )}
                                    >
                                      <item.icon className="h-6 w-6 shrink-0"/>
                                      <span
                                          className="truncate">
                                        {item.name.replaceAll('*', '').replaceAll('ⓐ', '').replaceAll('ⓢ', '')}
                                      </span>
                                      {item.notifyKey &&
                                          <NeverlandNotification programKey={'mobydick'} notifyKey={item.notifyKey}
                                                                 accountKey={accountInfo.accountId()}/>
                                      }
                                    </Link>
                                );
                              })}
                            </div>
                          </div>
                        </div>

                        {/*{Environment.isTest && accountInfo.isAdmin() &&*/}
                        {/*    <a href={EXCEL_LOAD_PAGE} target="_blank"*/}
                        {/*       className="gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold bg-rose-900 text-rose-100 whitespace-nowrap text-center">*/}
                        {/*      테스트버전*/}
                        {/*    </a>*/}
                        {/*}*/}

                        <SideBarLoginButton logOut={logOut}/>
                      </div>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* <-- 화면 작아졌을 때 사이드바 숨기고 위에 띄우는 부분 --> */}
        <div className="sticky top-0 z-40 flex gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden pt-7">
          <button type="button" className="-m-2.5 p-2.5 text-gray-400 lg:hidden" onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
          </button>

          <div
              className="flex flex-row items-center text-md font-semibold leading-6 text-white justify-center w-full whitespace-nowrap">
            {accountInfo.isLogin() &&
                <div className="flex flex-row items-center">
                  <img style={{backgroundColor: 'white'}} src={accountInfo.getProfileImage()}
                       className="h-8 w-8 rounded-full bg-gray-800 object-contain mr-3" alt="profileImg"/>
                  <Link to={MY_INFO_PAGE}>
                    <div className="flex flex-row items-center">
                      <div className="flex flex-row mr-4">
                        <p>{myLocalInfo.nickName}님</p>
                        {accountInfo.isStore() &&
                            <p className="text-indigo-300 ml-5"> {accountInfo.getStoreCode()} </p>}
                      </div>
                      {accountInfo.isAdmin() &&
                          <RiVerifiedBadgeFill className="text-blue-600 h-5 w-auto"/>}
                    </div>
                  </Link>
                </div>
            }
            {accountInfo.isLogin() ?
                <div className="flex mr-10 whitespace-nowrap">
                  {(!accountInfo.isStore() && !accountInfo.isAdmin()) && (
                      (accountInfo.getDefaultStore() && accountInfo.getDefaultStore().length > 1) ?
                          <p className="text-indigo-300"><span
                              className={accountInfo.isDefaultStoreConfirmed() ? "text-xs text-gray-300" : "text-xs text-orange-600"}>기본지점</span> {accountInfo.getDefaultStore()}
                          </p>
                          :
                          <button
                              onClick={() => setShowAddStoreModal(true)}
                              className="sm:w-40 rounded bg-indigo-200 text-black px-2 py-1.5 text-xs font-semibold whitespace-nowrap">
                            지점 추가
                          </button>
                  )}
                </div>
                : <p>로그인이 필요합니다.</p>
            }
          </div>
        </div>

        {/* <-- 사이드바 --> */}
        <div className="absolute hidden lg:fixed lg:inset-y-0 lg:z-40 lg:flex lg:w-64 lg:flex-col">
          <div className={neverLandUtils.classNames(isLocalTestMode() ? "bg-gray-800": "bg-stone-900", "flex grow flex-col gap-y-5 overflow-y-auto px-6")}>
            {
              accountInfo.isMonitoringAdminMode() ?
                  <div
                      className="cursor-pointer mt-5 flex xh-16 shrink-0 justify-center bg-rose-800 text-white rounded-md p-2"
                      onClick={() => {
                        accountInfo.rollbackMonitoringAdmin();
                        setRefresh(new Date());
                        navigate(MAIN_PAGE);
                      }}
                  >
                    <p>점검모드 [돌아가기]</p>
                  </div>
                  : <div className="mt-5 flex xh-16 shrink-0 justify-center">
                    <Link to={MAIN_PAGE} className="flex flex-row items-center">
                      <img src={isLocalTestMode() ? getLogoImage('whaleLogoBlue') : getLogoImage('whaleLogoMix')}
                           alt="WhaleLogo" className="h-10 w-auto"/>
                      <img src={isLocalTestMode() ? getLogoImage('textLogoBlue') : getLogoImage('textLogoWhite')}
                           alt="MobydickLogo" className="ml-4 h-5 w-32"/>
                    </Link>
                  </div>
            }

            <nav className="flex flex-1 flex-col">
              {/* 로그인 상태에 따라 표시 */}
              {accountInfo.isLogin() ?
                  <div className="-mx-6 mt-auto flex flex-col items-center">
                    <Link
                        to={accountInfo.isStore() ? MY_STORE_INFO_PAGE : MY_INFO_PAGE}
                        className="mt-4 w-full flex flex-col items-center gap-x-3 px-4 py-4 text-sm font-semibold leading-6 text-white  hover:bg-gray-800"
                    >
                      <div className="flex flex-row">
                        <img
                            className="h-8 w-8 rounded-full bg-gray-800 object-contain"
                            src={accountInfo.getProfileImage()}
                            alt="profileImg"
                        />

                        <p className="whitespace-nowrap flex flex-wrap px-2">안녕하세요 {myLocalInfo.nickName}님</p>
                        {accountInfo.isAdmin() && <RiVerifiedBadgeFill className="text-blue-600 h-5 w-auto"/>}
                      </div>
                      {accountInfo.isStore() && <p className="text-indigo-300"> {accountInfo.getStoreCode()} </p>}
                    </Link>

                    <div className="overflow-y-scroll">
                      {(!accountInfo.isStore() && !accountInfo.isAdmin()) && (
                          (accountInfo.getDefaultStore() && accountInfo.getDefaultStore().length > 1) ?
                              <p className="text-indigo-300"><span
                                  className={accountInfo.isDefaultStoreConfirmed() ? "text-xs text-gray-300" : "text-xs text-orange-600"}>기본지점</span> {accountInfo.getDefaultStore()}
                              </p>
                              :
                              <button
                                  onClick={() => setShowAddStoreModal(true)}
                                  className="w-40 rounded bg-indigo-200 px-2 py-1.5 text-xs font-semiboldshadow-sm font-semibold whitespace-nowrap">
                                지점 추가
                              </button>
                      )}
                    </div>
                  </div>
                  :
                  <div className="-mx-6 mt-auto flex flex-row">
                    <Link
                        to={LOGIN_PAGE}
                        className="mt-4 w-full flex items-center gap-x-4 px-6 py-4 text-sm font-semibold leading-6 text-white hover:bg-gray-800"
                    >
                      <img src={getLogoImage('whaleLogoMix')} alt="WhaleLogo" className="h-8 w-auto ml-4"/>
                      <p>로그인이 필요합니다.</p>
                    </Link>
                  </div>
              }

              <div role="list" className="mt-3 flex flex-1 flex-col gap-y-7">
                <div className="max-h-[35rem] overflow-y-auto px-3 custom-scrollbar">
                  <div>
                    <div role="list" className="-mx-2 space-y-1">
                      {SIDEBAR_UPPER_MENU.map((item) => {
                        if (item.name.includes("ⓒ")) {
                          if (accountInfo.isStore())
                            return null;
                        }
                        if (item.name.includes('*') && !accountInfo.isLogin()) {
                          if (item.current) {
                            goNavigate(MAIN_PAGE);
                          }
                          return null;
                        }
                        return (
                            <Link
                                key={item.name}
                                to={getLink(item)}
                                className={neverLandUtils.classNames(
                                    item.current ? 'bg-gray-700 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-700',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                )}
                            >
                              <item.icon className="h-6 w-6 shrink-0"/>
                              {item.name.replaceAll('*', '').replaceAll('ⓒ', '').replaceAll('ⓐ', '').replaceAll('ⓢ', '')}
                              {item.notifyKey &&
                                  <NeverlandNotification programKey={'mobydick'} notifyKey={item.notifyKey}
                                                         accountKey={accountInfo.accountId()}/>
                              }
                            </Link>
                        );
                      })}
                    </div>
                  </div>

                  <div>
                    <div className="mt-3 text-xs font-semibold leading-6 text-gray-400">
                      {accountInfo.isStore() ? "지점메뉴" : accountInfo.isAdmin() ? "관리자 메뉴" : ""}
                    </div>
                    <div role="list" className="-mx-2 space-y-1 ">
                      {SIDEBAR_AUTHORITY_MENU.map((item) => {
                        if (item.name.includes('ⓐ') && !accountInfo.isAdmin()) {
                          if (item.current) {
                            goNavigate(MAIN_PAGE);
                          }
                          return null;
                        }
                        if (item.name.includes('ⓢ') && !accountInfo.isStore()) {
                          if (item.current) {
                            goNavigate(MAIN_PAGE);
                          }
                          return null;
                        }
                        if (item.name.includes('*') && !accountInfo.isLogin()) {
                          if (item.current) {
                            goNavigate(MAIN_PAGE);
                          }
                          return null;
                        }
                        return (
                            <Link
                                key={item.name}
                                to={getLink(item)}
                                className={neverLandUtils.classNames(
                                    item.current ? 'bg-gray-700 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-700',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                )}
                            >
                              <item.icon className="h-6 w-6 shrink-0"/>
                              <span className="truncate">
                                {item.name.replaceAll('*', '').replaceAll('ⓐ', '').replaceAll('ⓢ', '')}
                              </span>
                              {item.notifyKey &&
                                  <NeverlandNotification programKey={'mobydick'} notifyKey={item.notifyKey}
                                                         accountKey={accountInfo.accountId()}/>
                              }
                            </Link>
                        );
                      })}
                    </div>
                  </div>

                  {accountInfo.isDeveloper()&&<div>
                    <div className="mt-3 text-xs font-semibold leading-6 text-gray-400">
                      개발자 메뉴
                    </div>
                    <div role="list" className="-mx-2 space-y-1 ">
                      {SIDEBAR_DEVELOPER_MENU.map((item) => {
                        if (item.name.includes('ⓓ') && !accountInfo.isDeveloper()) {
                          if (item.current) {
                            goNavigate(MAIN_PAGE);
                          }
                          return null;
                        }
                        return (
                            <Link
                                key={item.name}
                                to={getLink(item)}
                                className={neverLandUtils.classNames(
                                    item.current ? 'bg-gray-700 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-700',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                )}
                            >
                              <item.icon className="h-6 w-6 shrink-0"/>
                              <span className="truncate">
                                {item.name.replaceAll('*', '').replaceAll('ⓓ', '')}
                              </span>
                              {item.notifyKey &&
                                  <NeverlandNotification programKey={'mobydick'} notifyKey={item.notifyKey}
                                                         accountKey={accountInfo.accountId()}/>
                              }
                            </Link>
                        );
                      })}
                    </div>
                  </div>
                  }

                  <div>
                    <div className="mt-3 text-xs font-semibold leading-6 text-gray-400">커뮤니티</div>
                    <div role="list" className="-mx-2 space-y-1 ">
                      {SIDEBAR_LOWER_MENU.map((item) => {
                        if (item.name.includes('*') && !accountInfo.isLogin()) {
                          if (item.current) {
                            goNavigate(MAIN_PAGE);
                          }
                          return null;
                        }
                        return (
                            <Link
                                key={item.name}
                                to={getLink(item)}
                                className={neverLandUtils.classNames(
                                    item.current ? 'bg-gray-700 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-700',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                )}
                            >
                              <item.icon className="h-6 w-6 shrink-0"/>
                              <span className="truncate">
                                  {item.name.replaceAll('*', '').replaceAll('ⓐ', '').replaceAll('ⓢ', '')}
                              </span>
                              {item.notifyKey &&
                                  <NeverlandNotification programKey={'mobydick'} notifyKey={item.notifyKey}
                                                         accountKey={accountInfo.accountId()}/>
                              }
                            </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <SideBarLoginButton logOut={logOut}/>

                <InitFileLoad url={MOBYDICK_VERSION_URL}/>
              </div>
            </nav>
          </div>
        </div>
      </>
  )
}
export default Sidebar;
