import sha256 from 'crypto-js/sha256';
import * as htmlToImage from "html-to-image";
import {toast} from "react-toastify";
import html2canvas from "html2canvas";
// 이 알고리즘은 서버쪽에서 저장시 사용 -> 해쉬알고리즘만 사용
// const bcrypt = require('bcryptjs');
// const saltRounds = 12;


const keyList = [];


function generateFileName() {
  const d = new Date().toISOString().replace(/[-T:.Z]/g, '');
  return d.substring(0, 14);
}

class NeverLandUtils {
  classNames(...classes) {
    // 테일윈드 classNames 여러곳에 사용하기위해 빼놓음
    return classes.filter(Boolean).join(' ')
  }

  constructor() {
    if (!NeverLandUtils.instance) {
      NeverLandUtils.instance = this;
    }
    return NeverLandUtils.instance;
  }

  Tools = class {
    convertHtmlToPngAndCopyToClipboard(divId) {
      // <-- HTML 을 png 이미지로 변환 -->
      const element = document.getElementById(divId); // 대상 HTML 요소의 ID를 지정합니다.
      if (element) {
        let orginStyle = element.style;
        element.style.backgroundColor = "white";
        element.style.padding = "1px 1px 12px 1px";
        //
        // if (useVCenter) {
        //   element.style.display = 'flex';
        //   element.style.alignItems = 'center';
        // }

        let childs = element.querySelectorAll('.ignore-export');
        childs.forEach(function (ele) {
          ele.style.display = 'none';
        });

        // html2canvas 호출을 setTimeout으로 지연시킵니다.
        setTimeout(() => {
          // HTML 요소를 Canvas에 그립니다.
          html2canvas(element)
              .then((canvas) => {
                // Canvas를 이미지로 변환합니다.
                canvas.toBlob((blob) => {
                  if (window.isSecureContext && navigator.clipboard) {
                    navigator.clipboard.write([
                      new ClipboardItem({
                        'image/png': blob
                      })
                    ]).then(() => {
                      element.style = orginStyle;
                      childs.forEach(function (ele) {
                        ele.style.display = 'block'; // 다시 보이도록 설정
                      });
                      toast.success('이미지가 클립보드에 복사되었습니다.');
                    }).catch((error) => {
                      element.style = orginStyle;
                      childs.forEach(function (ele) {
                        ele.style.display = 'block'; // 다시 보이도록 설정
                      });
                      toast.error('클립보드에 이미지를 복사하는 중 오류가 발생했습니다');
                    });
                  } else {
                    let saveImg = document.createElement('a');
                    let blobUrl = URL.createObjectURL(blob);
                    saveImg.href = blobUrl;
                    saveImg.download = "ticket_" + generateFileName() + ".png";
                    saveImg.click();
                    saveImg.remove();
                    element.style = orginStyle;
                    childs.forEach(function (ele) {
                      ele.style.display = 'block'; // 다시 보이도록 설정
                    });
                  }
                });
              })
              .catch((error) => {
                element.style = orginStyle;
                childs.forEach(function (ele) {
                  ele.style.display = 'block'; // 다시 보이도록 설정
                });
                console.log('convertHtmlToPngAndCopyToClipboard->error:', error);
                toast.error('HTML을 PNG로 변환하는 중 오류가 발생했습니다[권한 없음]', error);
              });

        }, 20); // 100 밀리초 후에 호출

      } else {
        console.error('대상 HTML 요소를 찾을 수 없습니다.');
      }
    }


  }
  // <-- 공통 함수 -->
  Commons = class {
    // 로컬스토리지 저장
    save(key, jsonData) {
      if (jsonData === null) {
        localStorage.removeItem(key);
        return;
      }
      if (!keyList.includes(key) && key.includes('*')) keyList.push(key);

      const jsonString = JSON.stringify(jsonData);
      localStorage.setItem(key, jsonString);
    }

    has(key) {
      return localStorage.getItem(key) !== undefined && localStorage.getItem(key) !== null;
    }

    // 로컬스토리지 불러오기
    load(key) {
      if (!keyList.includes(key) && key.includes('*')) keyList.push(key);

      const jsonData = localStorage.getItem(key);
      if (jsonData == null) {
        return null;
      }
      try {
        return JSON.parse(jsonData);
      } catch (e) {
        console.log(e);
      }
    }

    // 로컬스토리지에서 삭제
    clear() {
      for (let k of keyList) {
        localStorage.removeItem(k);
      }
      keyList.length = 0;
    }

    clearAll() {
      localStorage.clear();
    }
  };
  Security = class {
    encrypt(text) {
      return String(sha256(text));
      // return bcrypt.hashSync(text, saltRounds);
    }
  };
  Utils = class {
    formatter1 = new Intl.DateTimeFormat('ko-KR', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    });
    formatter2 = new Intl.DateTimeFormat('ko-KR', {
      hour12: false,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
    formatter3 = new Intl.DateTimeFormat('ko-KR', {
      hour12: false,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });

    textToNumber(numberText) {
      numberText = numberText.replace(/\D/g, '');
      return Number(numberText);
    }

    toLocaleString(number) {
      if (typeof number === 'number') {
        let current = number.toLocaleString();
        if (current !== 'NaN')
          return current;
      }
      let current = number.toString();

      current = current.replace(/\D/g, '');
      return Number(current).toLocaleString();
    }

    getDisplayDateText(datetime, useDetail = false) {
      if (useDetail) {
        return this.formatter2.format(new Date(datetime));
      }
      // 작성일이 오늘이면 시간만, 오늘이 아니면 날짜만 띄우기
      const toDay = new Date().toISOString().substring(0, 10);
      const writeDate = datetime?.substring(0, 10);
      const writeTime = this.formatter1.format(new Date(datetime));
      if (toDay === writeDate) {
        return writeTime;
      } else return writeDate;
    }

    getDisplayTextByDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더해줍니다.
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}/${month}/${day}`;
    }

    getDisplayTimeText(datetime) {
      return this.formatter3.format(new Date(datetime));
    }

    getDisplayTimeText2(datetime) {
      const parts = this.formatter3.formatToParts(datetime);

      const year = parts.find(part => part.type === 'year').value;
      const month = parts.find(part => part.type === 'month').value;
      const day = parts.find(part => part.type === 'day').value;
      const hour = parts.find(part => part.type === 'hour').value;
      const minute = parts.find(part => part.type === 'minute').value;
      const second = parts.find(part => part.type === 'second').value;


      return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    }
  }
  commons = new this.Commons();
  security = new this.Security();
  utils = new this.Utils();
  tools = new this.Tools();
}

const neverLandUtils = new NeverLandUtils();
Object.freeze(neverLandUtils);

export default neverLandUtils;