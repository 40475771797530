import React, {useEffect, useState} from "react";
import neverLandNotificationInterface from "../utils/NeverLandNotificationInterface";


const NeverlandNotification = ({programKey, notifyKey, accountKey}) => {
  const currentJson = {programKey: programKey, notifyKey: notifyKey, accountKey: accountKey};
  const [notificationCount, setNotificationCount] = useState(neverLandNotificationInterface.getCurrentNotifications(currentJson).length);

  const _onNotificationChanged = (notificationList) => {
    if (notificationCount === notificationList.length)
      return;
    setNotificationCount(notificationList.length);
  }
  useEffect(() => {
    neverLandNotificationInterface.registMonitor(currentJson, _onNotificationChanged);
    return () => {
      neverLandNotificationInterface.unRegistMonitor(_onNotificationChanged);
    }
  }, []);


  return notificationCount > 0 && (
      <div>
        <span
            className="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-rose-600 px-2.5 py-1 text-center text-xs font-medium leading-5 text-white ring-1 ring-inset ring-rose-600"
            aria-hidden="true"
        >
          {notificationCount}
        </span>
      </div>
  )
}
export default NeverlandNotification;