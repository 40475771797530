import Pagination from "./Pagination";
import {useEffect, useState} from "react";
import fetcher, {getFetcher} from "../../utils/fetcher";
import {accountInfo} from "../../constants/constants";
import {GET_BOARD_LIST_API} from "../../constants/api_constants";
import {toast} from "react-toastify";


class PaginationHandle {
  customPaginationData;
  thisPageStatus = null;
  setPageStatus = null;
  timeout = 15000;
  initCountPerPage = 1;
  requestUrl = GET_BOARD_LIST_API;

  onRefresh = null;

  onResultHandle = (res) => {
    return res.data.result.boardList;
  };

  currentSearchCondition() {
    if (this.thisPageStatus === undefined)
      return "none";
    if (this.thisPageStatus !== null) {
      return this.thisPageStatus.searchCondition;
    }
    return "none";
  }

  checkLive() {
    if (this.thisPageStatus !== null) {
      let timeDifference = new Date() - this.thisPageStatus.lastUpdateTime.getTime();
      // console.log("timeout check=" + timeDifference);
      if (this.timeout > timeDifference)
        return true;
    }
    return false;
  }

  setOnSearchingHandle(requestUrl, onResultHandle) {
    this.requestUrl = requestUrl;
    this.onResultHandle = onResultHandle;
    return this;
  }

  setPaginationState(paginationState, initCountPerPage, timeout) {
    this.initCountPerPage = initCountPerPage;
    if (this.thisPageStatus === null) {
      this.thisPageStatus = {
        currentPageList: paginationState[0].currentPageList || [],
        listPerPage: paginationState[0].listPerPage || initCountPerPage,
        searchCondition: paginationState[0].searchCondition || "none",
        lastUpdateTime: paginationState[0].lastUpdateTime || new Date()
      };
      this.setPageStatus = paginationState[1];
      this.timeout = timeout;
      return this;
    }
    this.setPageStatus = paginationState[1];
    this.timeout = timeout;
    this.setPageStatus(this.thisPageStatus);
    return this;
  };

  getCountPerPage() {
    if (this.thisPageStatus == null)
      return 1;
    return this.thisPageStatus.listPerPage;
  }

  setCountPerPage(count) {
    if (this.thisPageStatus !== null) {
      this.thisPageStatus = {
        currentPageList: this.thisPageStatus.currentPageList,
        listPerPage: count,
        searchCondition: "none",
        lastUpdateTime: this.thisPageStatus.lastUpdateTime
      };
    } else {
      this.thisPageStatus = {
        currentPageList: [],
        listPerPage: count,
        searchCondition: "none",
        lastUpdateTime: new Date()
      };
    }
    if (this.setPageStatus !== null) {
      this.setPageStatus(this.thisPageStatus);
    }
  }

  list() {
    if (this.customPaginationData) {
      if (this.thisPageStatus) {
        return this.thisPageStatus.currentPageList;
      }
    }
    return [];

  }

  changeCateId(newCateId){
    //TODO :주말작업
  }
  refreshContentList() {
    if (this.setPageStatus === null) {
      return;
    }
    let listPerPage = this.thisPageStatus.listPerPage;
    this.setPageStatus(this.thisPageStatus = {
      currentPageList: this.thisPageStatus.currentPageList,
      listPerPage: listPerPage,
      searchCondition: this.thisPageStatus.searchCondition,
      lastUpdateTime: this.thisPageStatus.lastUpdateTime
    });
    if (this.onRefresh) {
      this.onRefresh(listPerPage);
      return;
    }
  }

  setContentList(searchCondition, newlistData, isUpdated) {
    if (this.setPageStatus === null) {
      this.thisPageStatus = {
        currentPageList: newlistData,
        listPerPage: this.getCountPerPage(),
        searchCondition: searchCondition,
        lastUpdateTime: isUpdated ? new Date() : this.thisPageStatus.lastUpdateTime
      };
      return;
    }

    this.setPageStatus(this.thisPageStatus = {
      currentPageList: newlistData === null ? this.thisPageStatus.currentPageList : newlistData,
      listPerPage: this.getCountPerPage(),
      searchCondition: searchCondition,
      lastUpdateTime: isUpdated ? new Date() : this.thisPageStatus.lastUpdateTime
    });
  }

  constructor(newPaginationData) {
    this.customPaginationData = newPaginationData;
  }
}

class CustomPaginationData {
  pageContent = null;
  sizePerPage = 0;
  poweredChangePage = false;
  countPerPage = 1;
  _searchCondition = null;

  loadPage(requestUrl, onResultHandle, searchCondition, _setContentList, pageNumber) {
    this._searchCondition = searchCondition;
    let thisCondition = searchCondition;
    if (thisCondition.indexOf('cateId') === 0 && thisCondition.includes('|')) {
      thisCondition = thisCondition.substring(thisCondition.indexOf('|') + 1).trim();
    }

    let url = accountInfo.makeGetRequest(requestUrl) + `&searchCondition='${thisCondition}'&pageNumber=${pageNumber}&pageSize=${customPaginationDataManager.default_page_size}`;

    getFetcher().get(url)
        .then(res => {
          const header = res.data.header;
          if (header.type === 'success') {
            let pageData = onResultHandle(res);

            this.sizePerPage = -1;
            this.pageContent = pageData.content;
            this.pageFirstIndex = pageData.number * customPaginationDataManager.default_page_size;
            this.totalElements = pageData.totalElements;

            // if (pageData.content == null)
            //   return;

            _setContentList("loadPage", true);
          } else {
            console.log('불러오기 실패');

            this.sizePerPage = -1;
            this.pageContent = [];
            this.pageFirstIndex = 0;
            this.totalElements = 0;
            _setContentList("loadPage", true);
          }
        })
        .catch(err => toast.error(err));
  }
}

class CustomPaginationDataManager {
  default_page_size = 200;
  dataList = [];

  newPaginationData(contentsName) {
    for (let data of this.dataList) {
      if (data.contentsName === contentsName) {
        return data.data;
      }
    }
    let newData = {contentsName: contentsName, data: new CustomPaginationData()};
    this.dataList.push(newData);
    return newData.data;
  }
}

const customPaginationDataManager = new CustomPaginationDataManager();

export function newPaginationHandle(contentsName) {
  return new PaginationHandle(customPaginationDataManager.newPaginationData(contentsName));
}

export function clearPaginationHandle(contentsName) {
  customPaginationDataManager.newPaginationData(contentsName).pageContent = null;
}

const CustomPagination = ({paginationHandle, searchCondition}) => {
  const customPaginationData = paginationHandle.customPaginationData;
  const [currentPage, setCurrentPage] = useState(paginationHandle.initPageNumber);  // 현재 페이지
  const [totalPage, setTotalPage] = useState(1);  // 총 페이지 수

  const listPerPage = paginationHandle.getCountPerPage();


  function _setContentList(cause, isUpdate) {
    if (customPaginationData.sizePerPage === listPerPage) {
      if (isUpdate && customPaginationData.pageContent !== null && customPaginationData.pageContent.length > 0) {
        if (currentPage === undefined) {
          setCurrentPage(1);
          return;
        }
        if (currentPage === 1) {
        } else {
          return;
        }
      } else {
        return;
      }
    }
    let newPageNumber = -1;
    if (customPaginationData.sizePerPage !== -1) {
      newPageNumber = 1;
      if (currentPage !== newPageNumber) {
        setCurrentPage(newPageNumber);
        return;
      }
    }

    customPaginationData.sizePerPage = listPerPage;

    if (customPaginationData.pageContent === null) {
      paginationHandle.setContentList(searchCondition, null, true);
      return;
    }
    if (customPaginationData.pageContent.length === 0) {
      paginationHandle.setContentList(searchCondition, null, true);
      return;
    }
    newPageNumber = currentPage;
    setTotalPage(Math.ceil(customPaginationData.totalElements / listPerPage));

    let newContentList = customPaginationData.pageContent.slice((newPageNumber - 1) * listPerPage
        - customPaginationData.pageFirstIndex,
        Math.min(newPageNumber * listPerPage - customPaginationData.pageFirstIndex, customPaginationData.pageContent.length));

    for (let i = 0; i < newContentList.length; i++) {
      newContentList[i].index = (i + 1 + (newPageNumber - 1) * listPerPage);
    }

    paginationHandle.setContentList(searchCondition, newContentList, isUpdate);
  }

  function _setCurrentPage(page) {
    if (!paginationHandle.checkLive()) {
      customPaginationData.sizePerPage = -1;
      customPaginationData.pageContent = null;
    } else {
      if (customPaginationData.pageFirstIndex < (page * listPerPage + 1) && (page * listPerPage + 1) < customPaginationData.pageFirstIndex + customPaginationDataManager.default_page_size) {
        customPaginationData.sizePerPage = -1;
      } else {
        customPaginationData.sizePerPage = -1;
        customPaginationData.pageContent = null;
      }
    }
    setCurrentPage(page);
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  useEffect(() => {
    if (!paginationHandle.checkLive()) {
      customPaginationData.pageContent = null;
    }
    if (customPaginationData._searchCondition !== searchCondition) {
      customPaginationData.pageContent = null;
    }
    if (customPaginationData.pageContent == null) {
      let pageNumber = Math.floor((currentPage - 1) * listPerPage / 100) + 1;
      customPaginationData.loadPage(paginationHandle.requestUrl, paginationHandle.onResultHandle, searchCondition, _setContentList, pageNumber);
    } else {
      _setContentList("useEffect", true);
    }
  }, [currentPage, searchCondition]);


  if (paginationHandle.currentSearchCondition() !== customPaginationData._searchCondition && currentPage !== 1) {
    _setCurrentPage(1);
    return (
        <>목록을 가져오는 중</>
    );
  }

  function getWindowSize() {
    const width = window.innerWidth;

    if (width >= 1024) {
      return "large";
    } else if (width >= 768) {
      return "mid";
    }
    return "small";
  }

  const windowSize = getWindowSize();

  function handleResize() {
    let newWindowSize = getWindowSize();
    if (newWindowSize === windowSize) {
      return;
    }
    paginationHandle.refreshContentList();
  }


  _setContentList("redraw", false);
  return (
      <>
        {totalPage !== 1 ?
            (<><Pagination
                totalPage={totalPage}
                limit={getWindowSize() === "small" ? 5 : 10}
                currentPage={currentPage}
                setCurrentPage={_setCurrentPage}
            /></>)
            :
            (<></>)
        }
      </>
  );

}

export default CustomPagination;