import useSound from "use-sound";
import React from "react";
import toastSound from "../assets/sounds/toast.mp3";
import warningSound from "../assets/sounds/warning.mp3";
import requestSound from "../assets/sounds/request.mp3";
import confirmSound from "../assets/sounds/confirm.mp3";
import {accountInfo} from "../constants/constants";

export class SoundPlayerHandle {
  playSoundFunc = null;
  _playQueue = null;

  play(path, type) {
    if (this.playSoundFunc !== null) {
      this._playQueue = null;
      this.playSoundFunc(path, type);
      return;
    }
    this._playQueue = {path: path, type: type};
  }

  initModule = (playSound) => {
    this.playSoundFunc = playSound;
    let p = this._playQueue;
    this._playQueue = null;
    if (p !== null)
      this.play(p.path, p.type);
  }
}

export let SoundOption = {
  // 옵션별로 사운드 조작
  all_mute: "false",
  signup_alarm: "false"
};

export const SoundPlayer = ({initPlayerHandle}) => {
  const [warningAlarm] = useSound(warningSound);
  const [confirmAlarm] = useSound(confirmSound);
  const [requestAlarm] = useSound(requestSound);
  const [toastAlarm] = useSound(toastSound);

  function _playSound(path, type) {
    if (accountInfo.getUserConfig().isOn('on_mute', false))
      return;
    if (SoundOption.all_mute === "true") {
      return;
    }
    if (path === "signup") {
      if (SoundOption.signup_alarm === "true") {
        return;
      }
    }
    if (type === "noti") {
      confirmAlarm();
    }
    if (type === "warning") {
      warningAlarm();
    }
    if (type === "confirm") {
      confirmAlarm();
    }
    if (type === "toast") {
      toastAlarm();
    }
    if (type === "request") {
      requestAlarm();
    }
  }

  initPlayerHandle.initModule(_playSound);
  return (<></>);
};