import RequestNoticeModal from "../components/Modal/RequestNoticeModal";
import {useEffect, useState} from "react";


const noticeModalContainerStatus={
  isOpen:false, message : []
};

class customToast {
  static error(type, title, message) {
    noticeModalContainerStatus.isOpen=true;
    noticeModalContainerStatus.message.push({type:"error/"+type,title:title,detail:message});
    _onUpdated();
    // toast.error(message);
  }
  static success(type, title, message) {
    noticeModalContainerStatus.isOpen=true;
    noticeModalContainerStatus.message.push({type:"success/"+type,title:title,detail:message});
    _onUpdated();
    // toast.success(message);
  }

  /*
    {title:"간편 지점 등록",message:"간편 지점 등록을 수행합니다.",button:["확인","취소"], onClick:(name)=>{}}
    {title:"동기화",message:"동기화를 수행합니다.",input:["동기화*"],button:["수행[추가입력]","수행[전체덮어쓰기]","취소"], onClick:(name)=>{}}
   */
  static info(messageData) {
    noticeModalContainerStatus.isOpen=true;
    noticeModalContainerStatus.message.push({type:"info", title:messageData.title,detail:messageData.message, button:messageData.button, onClick:messageData.onClick
              ,input:messageData.input});
    _onUpdated();
  }
}

let noticeModalContainerStatusHandler =[];


function _onUpdated() {
  try {
    noticeModalContainerStatusHandler.forEach(handler => {
      try {
        if (handler == null)
          return;
        handler();
      } catch {
      }
    });
  } catch {
  }
}

function addNoticeModalContainerStatusHandler(onChangeModalStatus) {
  if (noticeModalContainerStatusHandler.includes(onChangeModalStatus)) {
    return;
  }
  noticeModalContainerStatusHandler.push(onChangeModalStatus);
}
function removeNoticeModalContainerStatusHandler(onChangeModalStatus) {
  noticeModalContainerStatusHandler = noticeModalContainerStatusHandler.filter(h => h !== onChangeModalStatus);
}
const NoticeModalContainer =({}) => {
  const [open, setOpen] = useState(false);

  function onChangeModalStatus() {
    if(open){
      return;
    }
    setOpen(noticeModalContainerStatus.isOpen);
  }

  useEffect(() => {
    addNoticeModalContainerStatusHandler(onChangeModalStatus);
    return () => {
      removeNoticeModalContainerStatusHandler(onChangeModalStatus);
    };
  }, []);

  return open && <RequestNoticeModal setIsConfirmModalOpen={setOpen} displayMessage={noticeModalContainerStatus.message}></RequestNoticeModal>;
}
export {customToast, NoticeModalContainer};