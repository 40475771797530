import {Fragment, useEffect, useState} from 'react'
import {Listbox, Transition} from '@headlessui/react'
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/20/solid'
import neverLandUtils from "../utils/NeverLandUtils";

const ListSelectBox = ({defaultValue, listData, onChange}) => {
  const [selected, setSelected] = useState(defaultValue ? defaultValue : listData[0])

  const handleOnChange = (selectOption) => {
    if(onChange)
      onChange(selectOption);
    setSelected(selectOption);
  }

  useEffect(() => {
    setSelected(defaultValue ? defaultValue : listData[0]);
  }, [defaultValue]);

  return (
      <Listbox value={selected} onChange={handleOnChange}>
        {({open}) => (
            <>
              <div className="relative mr-3 whitespace-nowrap mt-2 xl:mt-0">
                <Listbox.Button
                    className="relative cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  <span className="block">{selected?.name}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400"/>
              </span>
                </Listbox.Button>

                <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                  <Listbox.Options
                      className="absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {listData.map((list) => (
                        <Listbox.Option
                            key={list.name}
                            className={({active}) =>
                                neverLandUtils.classNames(active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                )}
                            value={list}
                        >
                          {({selected, active}) => (
                              <>
                                <span
                                    className={neverLandUtils.classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                  {list.name}
                                </span>

                                {selected ? (
                                    <span className={neverLandUtils.classNames(
                                        active ? 'text-white' : 'text-indigo-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-3'
                                    )}>
                                    <CheckIcon className="h-5 w-5"/>
                                    </span>
                                ) : null}
                              </>
                          )}
                        </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
        )}
      </Listbox>
  )
}
export default ListSelectBox;