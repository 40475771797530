import Sidebar from "../../components/Sidebar";
import React, {useState} from "react";
import AdminPageHeader from "../../components/Header/AdminPageHeader";
import ReportUpperTable from "../../components/Table/ReportUpperTable";
import {getFetcher} from "../../utils/fetcher";
import {accountInfo} from "../../constants/constants";
import {ADMIN_CALCULATE_LIST_FOR_SA_API,} from "../../constants/api_constants";
import {toast} from "react-toastify";
import {CALCULATE_UPPER_TABLE_HEADER,} from "../../constants/component_data";
import CalculateLowerTable from "../../components/Table/CalculateLowerTable";
import loading_img from "../../assets/images/icon-loading.png";
import CalculateLowerTableWithSyncStoresOnly from "../../components/Table/CalculateLowerTableWithSyncStoresOnly";

const AdminCalculatePage = () => {
  const [isRequest, setIsRequest] = useState(false); // 로딩중
  const [gameRound, setGameRound] = useState('');
  const [isFixed, setIsFixed] = useState(false);
  const [isUnCheckFixed, setIsUnCheckFixed] = useState(false);
  const [useSyncStoreMode, setUseSyncStoreMode] = useState(false);

  const [upperTableBody, setUpperTableBody] = useState({});
  const [lowerTableBody, setLowerTableBody] = useState([]);

  const getLowerTableBodyData = () => {
    let roundList = gameRound.replaceAll(',', '▼').replaceAll(' ', '').trim();

    if (roundList.length === 0) {
      toast.error("회차 정보를 입력해 주십시오.");
      return;
    }
    // <-- 하단 리스트 테이블 데이터 가져오기 -->
    setIsRequest(true);
    getFetcher().get(accountInfo.makeGetRequest(ADMIN_CALCULATE_LIST_FOR_SA_API) + `&roundList=${roundList}`
    ).then(res => {
      const {header, result} = res.data;
      if (header.type === 'success') {
        let upperTableData = {
          '지점수': 0,
          '총투표액': 0,
          '유효투표액': 0,
          '당첨액': 0,
          '수수료': 0,
          '차액': 0,
          '진행건': 0
        };
        for (let data of result.list) {
          upperTableData['지점수']++;
          upperTableData['총투표액'] += data['totalAmount'];
          upperTableData['유효투표액'] += data['validBettingAmount'];
          upperTableData['당첨액'] += data['totalWinningAmount'];
          upperTableData['수수료'] += data['commissionAmount'];
          upperTableData['차액'] += data['differenceAmount'];
          upperTableData['진행건'] += data['totalOngoing'];
        }
        setUpperTableBody(upperTableData);
        setLowerTableBody(result.list);
        setIsRequest(false);
      } else {
        setIsRequest(false);
        toast.error(header.message);
      }
    }).catch(err => {
      setIsRequest(false);
      toast.error(err.message);
    });
  }

  const toggleFixed = () => {
    setIsFixed(prevState => {
      if (prevState === false) setIsUnCheckFixed(false);
      return !prevState;
    });
  }
  const toggleUnCheckedFixed = () => {
    setIsUnCheckFixed(prevState => {
      if(prevState === false) setIsFixed(false);
      return !prevState;
    });
  }

  return (
      <div className="bg-gray-100">
        <Sidebar/>
        {isRequest &&
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
              <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-center items-center">
                <img src={loading_img} className="h-16" alt="로딩 중 이미지"/>
                <p className="text-stone-900 font-semibold text-xl mt-10"> 정보를 가져오는 중입니다.</p>
              </div>
            </div>
        }
        <main className="lg:pl-64 min-h-screen">
          <AdminPageHeader/>

          <div className="h-full border-t border-white/10 py-6 sm:pt-16">
            <div className="sm:-mx-0 sm:rounded-lg">
              {/*검색조건 라인*/}
              <div className="mx-10 my-3 flex flex-row justify-between text-sm">
                <div className="flex flex-row">
                  <input
                      placeholder="회차"
                      onChange={(e) => setGameRound(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') getLowerTableBodyData();
                      }}
                      className="mx-1 w-32 px-3 py-1.5 ring-1 ring-gray-400 rounded-3xl focus:ring-indigo-500 mr-3"
                  />
                  <button
                      onClick={getLowerTableBodyData}
                      className="whitespace-nowrap rounded-md bg-blue-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-800">
                    검색
                  </button>
                </div>

                <div className="flex flex-row">
                  {!useSyncStoreMode &&
                      <div className="flex flex-row">
                        <div className="flex flex-row items-center mr-5 cursor-pointer bg-green-600 px-3 rounded-lg text-white"
                             onClick={toggleFixed}>
                          <input type="checkbox"
                                 checked={isFixed}
                                 className="mr-2 scale-110"
                          />
                          <p>처리 내역</p>
                        </div>

                        <div className="flex flex-row items-center mr-5 cursor-pointer bg-gray-600 px-3 rounded-lg text-white"
                             onClick={toggleUnCheckedFixed}
                        >
                          <input type="checkbox"
                                 checked={isUnCheckFixed}
                                 className="mr-2 scale-110"
                          />
                          <p>미처리 내역</p>
                        </div>
                      </div>
                  }
                  <div className="flex flex-row items-center cursor-pointer bg-pink-700 px-3 rounded-lg text-white"
                       onClick={() => setUseSyncStoreMode(prevState => !prevState)}
                  >
                    <input type="checkbox"
                           checked={useSyncStoreMode}
                           className="mr-2 scale-110"
                    />
                    <p>연계 지점 처리</p>
                  </div>
                </div>
              </div>

              {/* <- 위쪽 테이블 -> */}
              <div className="mx-5 border-x border-gray-900">
                <ReportUpperTable upperTableBodyData={upperTableBody} tableHeader={CALCULATE_UPPER_TABLE_HEADER}/>
              </div>

              {/* <- 아래쪽 테이블 -> */}
              <div className="mt-5 overflow-y-auto h-[calc(100vh-245px)]">
                {/*<div>*/}
                {!useSyncStoreMode ? <CalculateLowerTable lowerTableBodyData={lowerTableBody} isFixed={isFixed}
                                                          isUnCheckFixed={isUnCheckFixed}/>
                    : <CalculateLowerTableWithSyncStoresOnly lowerTableBodyData={lowerTableBody}/>}
              </div>
            </div>
          </div>

        </main>
      </div>
  )
}
export default AdminCalculatePage;