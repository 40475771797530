import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { RiCloseLine } from "react-icons/ri";

const ExternalLinkModal = ({ title, externalLink, setExternalLink }) => {
  const [open, setOpen] = useState(true);

  const closeModal = () => {
    setOpen(false);
    setExternalLink(null);
  };

  useEffect(() => {
    return () => {
      // Clean up code if necessary
    };
  }, []);

  return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
            <div
                className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0"
            >
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                    className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all"
                >
                  <div className="-mx-2">
                    <div className="flex flex-row justify-between">
                      <h3 className="text-xl font-semibold leading-6 text-gray-900">{title}</h3>
                      <button onClick={closeModal}>
                        <RiCloseLine className="w-5 h-5" />
                      </button>
                    </div>
                    <div>
                      {externalLink.includes("youtube") && externalLink.includes("embed") ? (
                          <iframe
                              width="560"
                              height="315"
                              src={externalLink}
                              className="p-5"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              referrerPolicy="strict-origin-when-cross-origin"
                              allowFullScreen
                          ></iframe>
                      ) : (
                          <iframe className="w-full h-[35rem]" src={externalLink} title="External Content" />
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
  );
};
export default ExternalLinkModal;