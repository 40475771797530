import Sidebar from "../../components/Sidebar";
import React, {useEffect, useState} from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {ko} from 'date-fns/locale';
import DatePickerInput from "../../components/DatePickerInput";
import Pagination from "../../components/Pagination/Pagination";
import ListSelectBox from "../../components/ListSelectBox";
import {getFetcher} from "../../utils/fetcher";
import {ALL_STORE_LIST_WITH_CONDITION_API} from "../../constants/api_constants";
import {toast} from "react-toastify";
import AdminPageHeader from "../../components/Header/AdminPageHeader";
import {Link} from "react-router-dom";
import {STORE_MANAGEMENT_PAGE} from "../../constants/page_constants";
import {storeLevelOption} from "../../constants/component_data";
import {accountInfo, lastMonth, lastWeek, today, yesterday} from "../../constants/constants";
import neverLandUtils from "../../utils/NeverLandUtils";

const filterOption = [
  {name: "전체", code: "all"},
  {name: "지점코드", code: "store_code"},
  {name: "지점명", code: "store_name"},
  {name: "지점주소", code: "store_address"},
  {name: "점주명", code: "store_owner_name"},
  {name: "연락처", code: "phoneNumber"},
]

const AllStoreListManagementPage = () => {
  const [storeList, setStoreList] = useState([]);
  // 검색조건
  const [startDate, setStartDate] = useState(new Date(Date.parse("2020-01-01")));
  const [endDate, setEndDate] = useState(new Date());
  const [searchCondition, setSearchCondition] = useState(filterOption[0]);
  const [searchConditionValue, setSearchConditionValue] = useState('');
  // 페이지네이션
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState('');
  let pageSize = 15;

  const dateFilterButton = [
    {label: '당일', onClick: () => handleDateChange(today, today)},
    {label: '전일', onClick: () => handleDateChange(yesterday, yesterday)},
    {label: '일주일', onClick: () => handleDateChange(lastWeek, today)},
    {label: '한달', onClick: () => handleDateChange(lastMonth, today)},
  ]

  const handleDateChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  function _getFormatDate() {
    let _startDate = neverLandUtils.utils.getDisplayTextByDate(startDate);
    let _endDate = neverLandUtils.utils.getDisplayTextByDate(endDate);
    return _startDate + '-' + _endDate;
  }

  useEffect(() => {
    getAllStoreList();
  }, [currentPage])

  const getAllStoreList = () => {
    let searchingCondition = `registDate:${_getFormatDate()}▼${searchCondition.code}:${searchConditionValue}`;

    getFetcher().get(accountInfo.makeGetRequest(ALL_STORE_LIST_WITH_CONDITION_API) + `&searchCondition=${searchingCondition}&pageNumber=${currentPage}&pageSize=${pageSize}`)
        .then(res => {
          const {header, result} = res.data;
          if (header.type === 'success') {

            let storeList = result.storeList;

            if(storeList.pageable===undefined) {
              setStoreList(storeList);
            }else {
              let currentPageNumber = storeList.pageable.pageNumber + 1;
              if (storeList.content.length === 0) {
                currentPageNumber = 1;
              }
              setStoreList(storeList.content);
              setCurrentPage(currentPageNumber);
              setTotalPage(storeList.totalPages);
            }
          } else {
            toast.error('데이터를 불러오는데 실패하였습니다.');
          }
        }).catch(err => toast.error(err.message));
  }

  function getStoreLevelText(storeLevel) {
    storeLevel = Number(storeLevel);
    let item = storeLevelOption.find((level) => (level.id === storeLevel));
    return item ? item.name : "-";
  }

  return (
      <>
        <Sidebar/>
        <div className="lg:pl-64">
          <AdminPageHeader/>

          <main className="lg:mt-10 px-4 sm:px-6 lg:px-52 lg:py-12">
            <div className="sm:flex sm:items-center py-6 sm:py-0">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold leading-6 text-stone-900">지점 리스트</h1>
                <p className="mt-3 text-sm text-gray-400">
                  등록된 지점 리스트 입니다.
                </p>
              </div>
            </div>

            {/*데이트피커*/}
            <div className="text-sm my-5 flex flex-col sm:flex-row justify-start mt-5">
              <div className="flex flex-row items-center mb-3 md:mb-0">
                <span>등록일 :</span>
                <div className="ml-2 flex flex-row items-center">
                  {dateFilterButton.map((data) => (
                      <button
                          key={data.label}
                          onClick={data.onClick}
                          className="mr-2 flex flex-row rounded-3xl bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500"
                      >
                        {data.label}
                      </button>
                  ))}
                  <div
                      className="mr-3 flex flex-row justify-between px-5 py-2 ring-1 ring-gray-300 rounded-3xl text-center">
                    <DatePicker
                        locale={ko}
                        dateFormat='yyyy-MM-dd'
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        customInput={<DatePickerInput/>}
                    />
                    <span className="mx-2"> ~ </span>
                    <DatePicker
                        locale={ko}
                        dateFormat='yyyy-MM-dd'
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        customInput={<DatePickerInput/>}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-row">
                <ListSelectBox listData={filterOption} onChange={(filter) => setSearchCondition(filter)}/>
                {searchCondition.code !== 'all' &&
                    <input className="mr-3 w-36 px-3 py-2 ring-1 ring-gray-300 rounded-3xl focus:ring-indigo-500"
                           onChange={(e) => setSearchConditionValue(e.target.value)}
                           onKeyDown={(e) => {
                             if (e.key === 'Enter') getAllStoreList();
                           }}
                    />
                }
                <button onClick={getAllStoreList}
                        className="whitespace-nowrap rounded-md bg-blue-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-800">
                  조회
                </button>
              </div>
            </div>

            <div className="ring-1 ring-stone-300 -mx-0 rounded-lg bg-stone-900">
              <table
                  className="min-w-full divide-y divide-stone-300 -mx-4 ring-1 ring-stone-300 sm:-mx-0 sm:rounded-lg bg-stone-900">
                <thead>
                <tr className="whitespace-nowrap text-white font-semibold text-sm text-center">
                  <th scope="col"
                      className="hidden px-3 py-3 sm:pl-4 sm:table-cell">
                    지점코드
                  </th>
                  <th>
                    지점명
                  </th>
                  <th>
                    지점주소
                  </th>
                  <th className="hidden sm:table-cell">
                    연락처
                  </th>
                  <th className="hidden sm:table-cell">
                    점주명
                  </th>
                  <th className="hidden sm:table-cell">
                    등록일
                  </th>
                  <th className="hidden sm:table-cell">
                    승인/레벨
                  </th>
                  <th>
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
                </thead>

                <tbody className="divide-y divide-stone-300 bg-white">
                {storeList?.map((data) => data.storeCode && data.storeCode.trim().length === 0 ? <></> : (
                    <tr key={data.storeCode} className="hover:bg-blue-50 whitespace-nowrap text-center text-sm ">
                      <td className="py-3 text-center pl-4 pr-3 sm:pl-4 hidden sm:table-cell">
                        {data.storeCode}
                      </td>
                      <td className="px-3">
                        {data.storeName}
                      </td>
                      <td className="flex-col">
                        {data.addressLatitudeAndLongitude === '0.0x0.0' ? <></> :
                            (<div className="text-xs text-right italic text-stone-300">
                              경도 X 위도 : {data.addressLatitudeAndLongitude}
                            </div>)
                        }
                        <div>
                          {data.address}
                        </div>
                      </td>
                      <td className="hidden sm:table-cell">
                        {data.phoneNumber}
                      </td>
                      <td className="hidden sm:table-cell">
                        {data.storeOwnerName}
                      </td>
                      <td className="hidden sm:table-cell">
                        {data.registerdDate.split('T')[0]}
                      </td>
                      <td className="hidden sm:table-cell">
                        {getStoreLevelText(data.storeLevel)}
                      </td>
                      <td className="px-3">
                        <Link className="text-blue-600"
                              to={`${STORE_MANAGEMENT_PAGE}/${encodeURIComponent(data.storeCode)}`}>
                          관리
                        </Link>
                      </td>
                    </tr>
                ))}
                </tbody>
              </table>
            </div>

            {/* 페이지네이션 */}
            {totalPage > 1 &&
                <div className="mt-10">
                <Pagination
                      totalPage={totalPage}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                  />
                </div>
            }
          </main>
        </div>
      </>
  )
}
export default AllStoreListManagementPage;