import WarningModal from "./WarningModal";
import ConfirmModal from "./ConfirmModal";
import React from "react";
import {NotifyModalHandler} from "../../../utils/SimpleComponentUtils";
import {SoundPlayer, SoundPlayerHandle} from "../../../utils/Soundplayer";

export const ModalHandler = ({path, initModalHandle}) => {
  const [isWarningModalOpen, setIsWarningModalOpen, isConfirmModalOpen, setIsConfirmModalOpen, modalMessageTitle, modalMessageContents, openWarningModal, openConfirmModal] = NotifyModalHandler();
  const soundPlayer=new SoundPlayerHandle();

  initModalHandle.initModal(openConfirmModal, openWarningModal);

  if(isWarningModalOpen) {
    soundPlayer.play(path,'warning');
  }
  if(isConfirmModalOpen) {
    soundPlayer.play(path,'confirm');
  }
  return (<>
    <SoundPlayer initPlayerHandle={soundPlayer}/>
    {isWarningModalOpen && (  // 경고 모달창
        <WarningModal
            messageTitle={modalMessageTitle}
            messageContents={modalMessageContents}
            setIsWarningModalOpen={setIsWarningModalOpen}
        />
    )}
    {isConfirmModalOpen && (  // 승인 모달창
        <ConfirmModal
            messageTitle={modalMessageTitle}
            messageContents={modalMessageContents}
            setIsConfirmModalOpen={setIsConfirmModalOpen}
        />
    )}
  </>);
}

export class OpenModalHandle {
  openConfirmModal;
  openWarningModal;

  initModal = (openConfirmModal, openWarningModal) => {
    this.openConfirmModal = (title, contents)=>{openConfirmModal(title, contents); return true;};
    this.openWarningModal = (title, contents)=>{openWarningModal(title, contents); return false;};
  };

  static create() {
    return new OpenModalHandle();
  }
}
