import {CALCULATE_LOWER_TABLE_HEADER} from "../../constants/component_data";
import React, {useState} from "react";
import neverLandUtils from "../../utils/NeverLandUtils";
import {RiCameraFill, RiCheckboxFill, RiCheckboxLine} from "react-icons/ri";

const CalculateLowerTable = ({lowerTableBodyData, isFixed, isUnCheckFixed}) => {
  const [checkedData, setCheckedData] = useState(neverLandUtils.commons.load("checkedSuperAdminReportItems"));

  function getTextFormat(text) {
    // 회차 번호 가져오기
    if (text.includes("G101_")) {
      text = text.replace("G101_", "").substring(2).trim();
      while (text[0] === '0') {
        text = text.substring(1);
      }
      return text;
    }
    return text;
  }

  function _toggleCheck(data, item) {
    let dataKey = data.storeName + "_" + item.roundCode;
    let newCheckSuperAdminReportItems = checkedData;

    if (newCheckSuperAdminReportItems === null) {
      newCheckSuperAdminReportItems = {list: []};
    }
    if (!newCheckSuperAdminReportItems.list.includes(dataKey)) {
      newCheckSuperAdminReportItems.list.push(dataKey);
    } else {
      newCheckSuperAdminReportItems = {list: newCheckSuperAdminReportItems.list.filter((data) => data !== dataKey)};
    }
    neverLandUtils.commons.save("checkedSuperAdminReportItems", newCheckSuperAdminReportItems);
    if (newCheckSuperAdminReportItems == checkedData) {
      newCheckSuperAdminReportItems = {list: newCheckSuperAdminReportItems.list};
    }
    setCheckedData(newCheckSuperAdminReportItems);
  }

  function isChecked(data, item) {
    if (checkedData === null)
      return false;
    let dataKey = data.storeName + "_" + item.roundCode;
    return checkedData.list.includes(dataKey);
  }

  const _isFixed=(data)=>{
    let item=data.list.find((item)=> isChecked(data, item));
    return item!==undefined;
  }
  const _isFixed2=(data)=>{
    let item=data.list.find((item)=> !isChecked(data, item));
    return item===undefined;
  }
  return (
      <div>
        {lowerTableBodyData.map((data, dataIndex) => {
            if(isFixed&&!_isFixed(data))
              return null;
            if(isUnCheckFixed&&_isFixed2(data))
              return null;
            return (<div className="mx-10 my-10 flex flex-row">
              <div className="flex flex-col items-center w-16">
                <p className="mr-4 border rounded-3xl border-gray-900 w-auto px-3.5 py-1.5 items-center text-center bg-white font-semibold">
                  {dataIndex + 1}
                </p>

                <button
                    onClick={() => neverLandUtils.tools.convertHtmlToPngAndCopyToClipboard('reportCapture_' + dataIndex, true)}>
                  <RiCameraFill className="h-8 w-auto text-gray-900 mr-4 mt-2 cursor-pointer"/>
                </button>
              </div>

              <div className="text-sm min-w-96">
                <div id={'reportCapture_' + dataIndex}>
                  {/* <-- 캡처영역 테이블 (지점 ~ 차액 행) --> */}
                  <table className="border border-stone-900">
                    <tbody className="bg-white">
                    {
                      CALCULATE_LOWER_TABLE_HEADER.map((header, headerIndex) => (
                          !header.name.includes("*") &&
                          <tr key={headerIndex}>
                            <td className="py-2 px-2 text-center font-semibold text-white bg-stone-900 border-b border-white whitespace-nowrap w-24 max-w-24">
                              {header.name}
                            </td>

                            <React.Fragment key={dataIndex + "_" + headerIndex + "_h"}>
                              {/* 지점열 */}
                              <td className={neverLandUtils.classNames(
                                  // 총투표액, 유효투표액, 당첨액 = 노란색 배경
                                  (header.key === 'totalAmount' || header.key === 'validBettingAmount' || header.key === 'totalWinningAmount') ? "bg-yellow-200" :
                                      // 차액 - : 빨간색 배경 / + : 초록색 배경
                                      (header.key === 'differenceAmount' && data['differenceAmount'] < 0) ? "bg-red-200" :
                                          (header.key === 'differenceAmount' && data['differenceAmount'] > 0) ? "bg-green-200" : "bg-white",
                                  "text-right px-3 pl-6 pr-2 text-gray-900 border-l border-b border-gray-900 w-32 max-w-32")}>
                                {header.key.includes("/") ? data[header.key.split('/')[0]] : (typeof data[header.key] === 'number' ? data[header.key].toLocaleString() : data[header.key])}
                              </td>

                              {/* 회차열 */}
                              {data.list.map((item, itemIndex) => (
                                  <td key={dataIndex + "_" + headerIndex + "_" + itemIndex}
                                      className={neverLandUtils.classNames(
                                          (header.key === 'totalAmount' || header.key === 'validBettingAmount' || header.key === 'totalWinningAmount') ? "bg-yellow-200" : "",
                                          "text-right py-2 pl-6 pr-2 border-l border-b border-gray-900 w-32 max-w-32")}>
                                    {header.key.includes("/") ? getTextFormat(item[header.key.split('/')[1]]) :
                                        (typeof item[header.key] === 'number' ?
                                            item[header.key].toLocaleString() : item[header.key])}
                                  </td>
                              ))}
                            </React.Fragment>
                          </tr>
                      ))
                    }
                    </tbody>
                  </table>
                </div>

                {/* <-- 진행건 행 --> */}
                <table className="border border-gray-900 mt-0.5">
                  <tbody className="bg-white">
                  {CALCULATE_LOWER_TABLE_HEADER.map((header, headerIndex) => (
                      header.name.includes("*") &&
                      <tr key={headerIndex}>
                        <td className={neverLandUtils.classNames(data.list.find(item => item[header.key] > 0) === undefined ? "bg-gray-900" : "bg-rose-800",
                            "py-2 px-3 text-center font-semibold text-white whitespace-nowrap w-24 max-w-24")}>
                          {header.name.replace("*", "")}
                        </td>

                        <React.Fragment key={dataIndex + "_" + headerIndex + "_h"}>
                          <td className="text-right py-2 pl-6 pr-2 border-l border-gray-900 w-32">
                            {header.key.includes("/") ? data[header.key.split('/')[0]] : (typeof data[header.key] === 'number' ? data[header.key].toLocaleString() : data[header.key])}
                          </td>
                          {data.list.map((item, itemIndex) => (
                              <td key={dataIndex + "_" + headerIndex + "_" + itemIndex}
                                  className="text-right py-2 pl-6 pr-2 border-l border-gray-900 w-32 max-w-32">
                                {header.key.includes("/") ? getTextFormat(item[header.key.split('/')[1]]) :
                                    (typeof item[header.key] === 'number' ?
                                        item[header.key].toLocaleString() : item[header.key])}
                              </td>
                          ))}
                        </React.Fragment>
                      </tr>
                  ))
                  }
                  </tbody>
                </table>

                {/* <-- 처리여부 행 --> */}
                <table className="border">
                  <tbody className="divide-y divide-gray-300 bg-white border-b border-l border-gray-900">
                  <tr>
                    <td className="py-3 px-3 text-center font-semibold text-white lg:table-cell bg-gray-900 whitespace-nowrap w-56 border-t border-white">
                      처리 여부
                    </td>

                    {data.list.map((item) => (
                        <td onClick={() => {
                          _toggleCheck(data, item)
                        }}
                            className="w-32 cursor-pointer border-r border-gray-900 py-2"
                        >
                          <div className="flex justify-center items-center h-full">
                            {!isChecked(data, item) ? (
                                <RiCheckboxLine className="h-8 w-auto text-gray-400"/>
                            ) : (
                                <RiCheckboxFill className="h-8 w-auto text-green-500"/>
                            )}
                          </div>
                        </td>
                    ))}
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
        )})}
      </div>
  )
}
export default CalculateLowerTable;