import {useEffect, useState} from "react";
import Sidebar from "../../components/Sidebar";
import BoardListSelectBox from "../../components/BoardListSelectBox";
import {Link, useNavigate, useParams} from "react-router-dom";
import {BOARD_DETAIL_PAGE, BOARD_WRITE_PAGE} from "../../constants/page_constants";
import CustomPagination, {
  CustomPaginationData,
  newPaginationHandle
} from "../../components/Pagination/CustomPagination";
import {accountInfo} from "../../constants/constants";
import {getBoardInfo} from "../../constants/board_constants";
import {GET_BOARD_LIST_API} from "../../constants/api_constants";
import neverLandUtils from "../../utils/NeverLandUtils";

const BoardListPage = () => {
  const {cateId} = useParams();
  const navigate = useNavigate();
  const paginationHandle = newPaginationHandle(cateId)
      .setPaginationState(useState({}), 5, 3000)
      .setOnSearchingHandle(GET_BOARD_LIST_API, (res) => {
        return res.data.result.boardList;
      });
  const boardInfo = getBoardInfo(cateId);

  useEffect(() => {
    paginationHandle.changeCateId(cateId);
  }, [cateId]);

  const routeDetail = (id) => {
    navigate(`${BOARD_DETAIL_PAGE}/${id}?cate_id=${cateId}`);
  }

  let useCate = cateId === '0x001';
  return (
      <>
        <Sidebar/>
        <div className="lg:pl-64">
          <div className="sm:px-6 lg:px-8">
            <div className="mx-auto max-w-6xl px-3 py-5 sm:py-20 lg:px-8">
              <div className="sm:px-6 lg:px-8">
                <div className="sm:flex items-center">
                  <div className="sm:flex-auto">
                    <h1 className="text-2xl font-semibold leading-6 text-gray-900">{boardInfo.title}</h1>
                    <p className="mt-3 text-sm text-gray-400">
                      {boardInfo.text}
                    </p>
                  </div>

                  <div className="mt-4 sm:ml-16 sm:mt-0 flex flex-row">
                    {useCate &&
                        ((accountInfo.isAdmin() && boardInfo.needAdmin) || !boardInfo.needAdmin) &&
                        <Link to={BOARD_WRITE_PAGE + "?cate_id=" + cateId}>
                          <button
                              type="button"
                              className="whitespace-nowrap mr-2 block rounded-md bg-gray-900 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-700"
                          >
                            게시글 작성
                          </button>
                        </Link>
                    }
                    <BoardListSelectBox setListPerPage={paginationHandle}/>
                  </div>
                </div>

                <div className="mt-5 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">

                      <div className="sm:min-h-[37rem]">
                        <table className="min-w-full">
                          <thead className="hidden sm:table-header-group">
                          <tr>
                            <th scope="col"
                                className="w-1/6 py-3.5 pr-10 text-center text-sm font-semibold sm:pl-3 bg-gray-900 text-white">
                              NO.
                            </th>
                            <th scope="col"
                                className="w-3/6 px-3 py-3.5 text-center text-sm font-semibold bg-gray-900 text-white">
                              제목
                            </th>
                            <th scope="col"
                                className="w-1/6 px-3 py-3.5 pr-10 text-center text-sm font-semibold bg-gray-900 text-white">
                              작성자
                            </th>
                            <th scope="col"
                                className="w-1/6 px-3 py-3.5 pr-10 text-center text-sm font-semibold bg-gray-900 text-white">
                              작성일
                            </th>
                          </tr>
                          </thead>

                          <tbody className="bg-white">
                          {!useCate ? <div className="mt-20 text-lg text-blue-700 font-semibold bg-yellow-100 whitespace-nowrap">※ 현재 준비 중입니다.</div>
                              : paginationHandle.list()?.map((data, index) => (
                                  <tr key={data.boardId}
                                      onClick={() => routeDetail(data.boardId)}
                                      className="even:bg-gray-100 cursor-pointer"
                                  >
                                    <td className="whitespace-nowrap text-center pr-10 px-3 py-4 text-sm text-gray-900 hidden sm:table-cell">
                                      {data.index}
                                    </td>
                                    <td className="whitespace-nowrap text-left py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 hidden sm:table-cell">
                                      {data.title}
                                    </td>
                                    <td className="whitespace-nowrap text-center px-3 py-4 pr-10 text-sm text-gray-900 hidden sm:table-cell">
                                      {data.nickName}
                                    </td>
                                    <td className="whitespace-nowrap text-center px-3 py-4 pr-10 text-sm text-gray-900 hidden sm:table-cell">
                                      {neverLandUtils.utils.getDisplayDateText(data.datetime)}
                                    </td>
                                    {/*sm 사이즈 이하일 때 두줄로 바꾸기*/}
                                    <td className="sm:hidden px-3 text-left py-2 r-3 text-sm font-medium text-gray-900 pl-3 border-t border-b border-gray-300">
                                      <span className="font-semibold">{data.title}</span>
                                      <br className="sm:hidden"/>
                                      <span className="text-xs mr-3">{data.nickName}</span>
                                      <span
                                          className="text-xs text-gray-500">{neverLandUtils.utils.getDisplayDateText(data.datetime)}</span>
                                    </td>
                                  </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>

                      <div className="mt-10">
                        <CustomPagination paginationHandle={paginationHandle}
                                          searchCondition={"cateId=" + cateId + "|" + boardInfo.categoryName}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  )
}
export default BoardListPage;