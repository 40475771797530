import React, {Fragment, useEffect, useState} from "react";
import BaseTicketListPage from "./base/BaseTicketListPage";
import Sidebar from "../../components/Sidebar";
import CopyTicketRequestModal from "../../components/Modal/CopyTicketRequestModal";
import neverLandNotificationInterface from "../../utils/NeverLandNotificationInterface";
import {accountInfo} from "../../constants/constants";

const RequestListPage = () => {
  const [isRequesting, setIsRequesting] = useState(false);
  const [showCopyRequestModal, setShowCopyRequestModal] = useState({isOpen: false, ticket: []});
  const [refreshDate, setRefreshDate] = useState(new Date());

  const _onNotificationChanged = (notificationList) => {
    setRefreshDate(new Date());
  }

  useEffect(() => {
    neverLandNotificationInterface.registMonitor(
        {programKey: 'mobydick', notifyKey: 'order_list', accountKey: accountInfo.accountId()},
        _onNotificationChanged);

    return () => {
      neverLandNotificationInterface.unRegistMonitor(_onNotificationChanged);
    }
  }, []);


  const actionsJson = [{
    name: '복사요청*',
    color: 'blue',
    onClick: (ticket, selectedTicketData) =>
        setShowCopyRequestModal({
          isOpen: true,
          ticket: selectedTicketData[ticket[0]]
        })
  }]
  const ticketState = 'all▼!cart';
  const ticketOrderType = {
    view: ["stateDetail", "register", "charger"
       //             , "memo-charger-invisible"
              ],
    options: ["fixed", "memo-user-editable", "copy-selectable", "single-selection"],
    stateFilter: (state) => {
      if (state.includes('request'))
        return state.replace('request', 'print');
      return state;
    }
  };
  const fixedFilter = {};

  const requestState = [
    {id: 1, name: "의뢰상태 전체", code: "all"},
    {id: 2, name: "의뢰", code: "order▼*!abort▼*!hold"},
    {id: 3, name: "출력", code: "request▼*!abort▼*!hold"},
    {id: 4, name: "보류", code: "*hold"},
    {id: 5, name: "취소", code: "*abort"},
  ]


  return (
      <>
        {showCopyRequestModal.isOpen && <CopyTicketRequestModal ticketData={showCopyRequestModal.ticket}
                                                                ticketOrderType={ticketOrderType}
                                                                onClose={(res) => {
                                                                  setShowCopyRequestModal({
                                                                    isOpen: false,
                                                                    ticket: []
                                                                  })
                                                                  if (res) setRefreshDate(new Date());
                                                                }}/>
        }

        <Sidebar/>

        <BaseTicketListPage listKey="user_request1" isRequesting={isRequesting} isShowFilter={true}
                            actionsJson={actionsJson}
                            ticketOrderType={ticketOrderType}
                            ticketState={ticketState} fixedFilter={fixedFilter} requestState={requestState}
                            refreshDate={refreshDate}/>
      </>
  )
}
export default RequestListPage;