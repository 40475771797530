import Sidebar from "../../components/Sidebar";
import React, {useEffect, useState} from "react";
import {accountInfo} from "../../constants/constants";
import neverLandUtils from "../../utils/NeverLandUtils";
import {getFetcher} from "../../utils/fetcher";
import {ADMIN_AGGREGATE_TABLE_LIST_API} from "../../constants/api_constants";
import {toast} from "react-toastify";

const DeveloperMonitoringPage = () => {
  const [lastRequestTime, setLastRequestTime] = useState('');

  useEffect(()=>{
    getAdminReportData();
  },[])
  
  const getAdminReportData = () => {
    // <-- 하단 리스트 테이블 데이터 가져오기 -->
    getFetcher().get(accountInfo.makeGetRequest(ADMIN_AGGREGATE_TABLE_LIST_API)).then(res => {
      const {header, result} = res.data;
      if (header.type === 'success') {
        toast.success('성공~');
      } else toast.error(header.message);
    }).catch(err => toast.error(err));
  }


  return (
      <div>
        <Sidebar/>

        <main className="lg:pl-64 min-h-screen">
          마지막 요청 시간 : {lastRequestTime}
        </main>
      </div>
  )
}
export default DeveloperMonitoringPage;