import {ArrowLongLeftIcon, ArrowLongRightIcon} from '@heroicons/react/20/solid'
import {useEffect, useState} from "react";
import neverLandUtils from "../../utils/NeverLandUtils";
import {mobileInterface} from "../../pages/WindowInterfaces";

const Pagination = ({totalPage, currentPage, setCurrentPage}) => {
  const [currentPageArray, setCurrentPageArray] = useState([]);
  const [totalPageArray, setTotalPageArray] = useState([]);
  let limit = mobileInterface.isMobile() ? 5 : 10; // 페이지네이션에 표시될 페이지 개수

  const sliceArrayByLimit = () => {
    // 특정 숫자까지 배열을 만든후 limit 기준으로 자른 배열 만들기
    const totalPageArray = Array(totalPage)
        .fill()
        .map((_, i) => i);
    return Array(Math.ceil(totalPage / limit))
        .fill()
        .map(() => totalPageArray.splice(0, limit));
  };

  useEffect(() => {
    if (currentPage) {
      if (currentPage % limit === 1) {
        setCurrentPageArray(totalPageArray[Math.floor(currentPage / limit)]);
        return;
      }
      if (currentPage % limit === 0) {
        setCurrentPageArray(totalPageArray[Math.floor(currentPage / limit) - 1]);
      }
    }
  }, [currentPage]);

  useEffect(() => {
    if (totalPage) {
      const slicedPageArray = sliceArrayByLimit(totalPage, limit);
      setTotalPageArray(slicedPageArray);
      setCurrentPageArray(slicedPageArray[0]);
    }
  }, [totalPage]);

  return (
      <nav className="flex items-center justify-between sm:border-t border-gray-200 px-4 sm:px-0">
        <div className="-mt-px flex w-0 flex-1">
          <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-indigo-300 hover:font-semibold hover:text-indigo-700"
          >
            <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400"/>
            <p className="hidden sm:block"> 이전 </p>
          </button>
        </div>

        <div className="md:-mt-px md:flex">
          {currentPageArray?.map((i) => (
              <button
                  key={i + 1}
                  onClick={() => setCurrentPage(i + 1)}
                  aria-current={currentPage === i + 1 ? 'page' : null}
                  className={neverLandUtils.classNames(currentPage === i + 1 ? "border-indigo-500 text-indigo-600" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                      , "inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium")}
              >
                {(i + 1)}
              </button>
          ))}
        </div>

        <div className="-mt-px flex w-0 flex-1 justify-end">
          <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPage}
              className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:font-semibold hover:border-indigo-300 hover:text-indigo-700"
          >
            <p className="hidden sm:block"> 다음 </p>
            <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400"/>
          </button>
        </div>
      </nav>
  )
}
export default Pagination;