import React from "react";
import {RiArrowDownSFill, RiArrowUpSFill, RiExpandUpDownFill} from "react-icons/ri";
import neverLandUtils from "../utils/NeverLandUtils";

const buttons = [
  {name: "마감"}, {name: "폴더"}, {name: "구매금액"}, {name: "배당률"}, {name: "배당금액"},
]

const TicketAlignButtons = ({currentAlignData, setAlignData}) => {
  let _currentAlignData = currentAlignData;

  if (_currentAlignData === undefined) {
    _currentAlignData = {};
  }

  function makeAlignData(name) {
    let prevData = _currentAlignData[name];
    if (prevData === undefined || prevData === "-") {
      _currentAlignData[name] = "asc";
    } else if (prevData === "asc") {
      _currentAlignData[name] = "desc";
    } else if (prevData === "desc") {
      _currentAlignData[name] = "-";
    }

    if (setAlignData === undefined)
      return;
    setAlignData({..._currentAlignData});
  }

  return (
      <div className="lg:mr-10 ml-5 hidden lg:flex mt-3 2xl:mt-0 flex-row justify-between items-center">
        {buttons.map((data) =>
            <button
                onClick={() => makeAlignData(data.name)}
                key={data.name}
                type="button"
                className={neverLandUtils.classNames(_currentAlignData[data.name] !== undefined && _currentAlignData[data.name] !== '-' ?
                        (_currentAlignData[data.name] === "desc" ? 'ring-amber-500 pcSizeHover:hover:bg-amber-100' : 'ring-sky-500 pcSizeHover:hover:bg-sky-100') : 'ring-gray-400 pcSizeHover:hover:bg-gray-100',
                    'whitespace-nowrap items-center mt-2 xl:mt-0 mr-3 flex flex-row rounded-md bg-white px-1.5 py-1.5 text-sm font-semibold ring-2 text-gray-600 shadow-sm')}
            >
              {data.name}
              {_currentAlignData[data.name] !== undefined && _currentAlignData[data.name] !== '-' ?
                  (_currentAlignData[data.name] === "desc" ?
                          <RiArrowDownSFill className="h-5 w-5 text-yellow-500 ml-1"/> :
                          <RiArrowUpSFill className="h-5 w-5 text-sky-500 ml-1"/>
                  ) : <RiExpandUpDownFill className="h-4 w-4 text-gray-400 ml-1"/>
              }
            </button>
        )}
      </div>
  )
}
export default TicketAlignButtons;