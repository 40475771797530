import React, {Fragment, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {
  RiCloseLine, RiCommunityFill, RiLoginCircleLine
} from "react-icons/ri";
import {toast} from "react-toastify";
import SendTempPasswordModal from "./SendTempPasswordModal";
import {MAIN_PAGE, STORE_MANAGEMENT_PAGE} from "../../constants/page_constants";
import {useNavigate} from "react-router-dom";
import {getFetcher} from "../../utils/fetcher";
import {accountInfo} from "../../constants/constants";
import {
  ADMIN_USER_EDIT_API,
  LOGIN_USER_ACCOUNT_BY_ADMIN
} from "../../constants/api_constants";
import neverLandUtils from "../../utils/NeverLandUtils";


const AllCustomerManagementModal = ({setCustomerModalOpen, customerInfo}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [email, setEmail] = useState(customerInfo.email);
  const [phoneNumber, setPhoneNumber] = useState(customerInfo.phoneNumber);
  const [newPassword, setNewPassword] = useState('*****');
  const [passwordChangeInput, setPasswordChangeInput] = useState('');

  const closeModal = () => {
    setOpen(false);
    setCustomerModalOpen(false);
  }

  const editUserInfoFromAdmin = () => {
    // 고객 정보 변경하기
    if (passwordChangeInput !== '변경' && newPassword !== '*****' && newPassword.length > 1) {
      toast.warn('비밀번호를 변경하시려면 "변경"을 입력해주세요.');
      return;
    }

    let encryptPassword = neverLandUtils.security.encrypt(newPassword);  // 비밀번호 암호화
    if (newPassword === '*****' && passwordChangeInput !== '변경') {
      encryptPassword = null;
    }

    getFetcher().post(ADMIN_USER_EDIT_API, {
      user: accountInfo.makeJson(),
      email: email,
      phoneNumber: phoneNumber,
      newPassword: encryptPassword
    }).then(res => {
      const {type, message} = res.data.header;
      if (type === 'success') {
        toast.success(message);
      } else toast.warning(message);
    }).catch(err => toast.error(err.message));
  }

  // const createTempPassword = () => {
  //   toast.success('임시 비밀번호가 메일로 전송되었습니다.')
  // }

  const loginAccountByAdmin = () => {
    accountInfo.saveMonitoringAdmin();
    // 해당유저 계정으로 로그인하기
    getFetcher().post(LOGIN_USER_ACCOUNT_BY_ADMIN, {
      user: accountInfo.makeJson(),
      email: email,
    })
        .then(res => {
          const {type, message} = res.data.header;
          if (type === 'fail') {
            toast.error(message);
            return;
          }
          if (type === 'success') {
            toast.success(message);
            let userInfo = res.data.result.user;
            accountInfo.login(userInfo);
            navigate(MAIN_PAGE);
          }
        })
        .catch((err) => toast.error(err));
  }

  return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
          </Transition.Child>

          {showAddModal && <SendTempPasswordModal email={customerInfo?.email} setShowAddModal={setShowAddModal}/>}

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                    className="relative z-10 transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-80 sm:max-w-lg sm:p-6">

                  <button onClick={closeModal} className="ml-64">
                    <RiCloseLine className="w-5 h-5"/>
                  </button>

                  <div className="flex flex-row items-center">
                    {customerInfo.person !== '점주' &&
                        <div className="flex flex-row items-center">
                          {/*<button*/}
                          {/*    title="간편 지점 등록"*/}
                          {/*    onClick={() => customToast.info({*/}
                          {/*      title: "간편 지점 등록",*/}
                          {/*      message: "간편 지점 등록을 수행합니다.\n\n ※보나연결 선택시 보나스포츠와 자동 연결됩니다.",*/}
                          {/*      button: ["보나 연결", "보나 미연결", "취소"],*/}
                          {/*      onClick: (name) => {*/}
                          {/*        if (name === '취소') {*/}
                          {/*          return;*/}
                          {/*        }*/}
                          {/*        toast.info("현재 구현 중입니다.")*/}
                          {/*      }*/}
                          {/*    })}>*/}
                          {/*  <RiCommunityFill className='bg-rose-600 h-9 w-9 text-white rounded-full px-2'/>*/}
                          {/*</button>*/}

                          <button
                              title="지점관리 페이지"
                              onClick={() => navigate(STORE_MANAGEMENT_PAGE + "/" + encodeURI("◆" + customerInfo.nickName + "▼" + customerInfo.email))}>
                            <RiCommunityFill className='bg-blue-600 h-9 w-9 text-white rounded-full px-2 mx-3'/>
                          </button>
                        </div>
                    }

                    <h3 className="text-xl font-semibold leading-6 text-gray-900">{customerInfo.person !== '점주' ? "고객 정보 [일반]" : "고객 정보 [점주]"}</h3>
                  </div>

                  <div>
                    <div className="mt-5 flex flex-col">
                      <p className="text-sm">성명</p>
                      <input
                          value={customerInfo?.userName}
                          disabled={true}
                          className="mt-1 px-4 w-full font-semibold rounded-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>

                    <p className="mt-5 text-sm">닉네임</p>
                    <div className="flex flex-row">
                      <input
                          value={customerInfo?.nickName}
                          disabled={true}
                          className="mt-1 px-4 w-full font-semibold rounded-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      <button onClick={loginAccountByAdmin} title="이 계정으로 로그인">
                        <RiLoginCircleLine className='bg-emerald-600 h-9 w-9 text-white rounded-full px-2 ml-3'/>
                      </button>
                    </div>

                    {customerInfo?.registStoreName &&
                        <div className="mt-5 flex flex-row items-center">
                          <p className="text-sm mr-3">등록지점</p>
                          <p className={neverLandUtils.classNames(customerInfo.registStoreName.includes("ⓡ") ? "bg-blue-600" :
                                  (customerInfo.registStoreName.includes("ⓒ") ? "bg-green-600" : (customerInfo.registStoreName.includes("ⓓ") ? "bg-amber-500" : "bg-gray-500"))
                              , "text-white text-center min-w-24 max-w-32 px-3 py-0.5 rounded-lg font-semibold")}
                          >
                            {(customerInfo.registStoreName.endsWith("ⓡ") || customerInfo.registStoreName.endsWith("ⓒ") || customerInfo.registStoreName.endsWith("ⓓ")) ?
                                customerInfo.registStoreName.substring(0, customerInfo.registStoreName.length - 1) : customerInfo.registStoreName}
                          </p>
                        </div>
                    }

                    <p className="mt-5 text-sm">연락처</p>
                    <input
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        className="mt-1 px-4 w-full font-semibold rounded-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />

                    <p className="mt-5 text-sm">이메일</p>
                    <input
                        value={customerInfo?.email}
                        disabled={true}
                        className="mt-1 px-4 w-full font-semibold rounded-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />

                    <div className="flex flex-row items-center justify-between mt-5">
                      <p className="text-sm mr-3">비밀번호 직접변경시 변경할 비밀번호 입력</p>

                      {/*<div className="flex flex-row items-center  rounded-md bg-gray-800 p-1 hover:bg-gray-700">*/}
                      {/*  <RiMailSendLine className="text-white w-4 shrink-0"/>*/}
                      {/*  <button*/}
                      {/*      type="button"*/}
                      {/*      onClick={() => setShowAddModal(true)}*/}
                      {/*      className="w-28 justify-center text-xs font-semibold text-white shadow-sm  sm:col-start-2 whitespace-nowrap"*/}
                      {/*  >*/}
                      {/*    임시 비밀번호 발급*/}
                      {/*  </button>*/}
                      {/*</div>*/}
                    </div>
                    <input
                        onFocus={(e) => e.target.select()}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        autoComplete="off"
                        className="mt-1 px-4 w-full font-semibold rounded-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <p className="mt-2 text-sm">비밀번호 직접변경시 "변경"입력 필수</p>
                    <input
                        disabled={newPassword === '*****' || newPassword === ''}
                        onChange={(e) => setPasswordChangeInput(e.target.value)}
                        placeholder="변경"
                        autoComplete="off"
                        className="mt-1 px-4 w-full font-semibold rounded-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>

                  <div className="mt-5 flex flex-row justify-center sm:mt-6 grid-cols-2 gap-3">
                    <button
                        onClick={editUserInfoFromAdmin}
                        type="button"
                        className="w-24 justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    >
                      수정
                    </button>
                    <button
                        type="button"
                        className="w-24 justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                        onClick={closeModal}
                    >
                      취소
                    </button>
                  </div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
  )
}
export default AllCustomerManagementModal;
