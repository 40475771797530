import {Fragment, useEffect, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {RiCloseLine} from "react-icons/ri";
import {getFetcher} from "../../utils/fetcher";
import {SEND_DIRECT_MESSAGE_TO_CUSTOMER} from "../../constants/api_constants";
import {toast} from "react-toastify";
import {accountInfo} from "../../constants/constants";

const SendDirectMessageModal = ({customerList, setSendDirectMessageModalOpen}) => {
  const [open, setOpen] = useState(true);
  const [messageContents, setMessageContents] = useState('');
  const [searchText, setSearchText] = useState('');
  // const [allCustomerList, setAllCustomerList] = useState(customerList); // 모든 고객 리스트
  const [filteredCustomerList, setFilteredCustomerList] = useState(customerList);
  const [selectedCustomerList, setSelectedCustomerList] = useState([]);

  const closeModal = () => {
    setOpen(false);
    setSendDirectMessageModalOpen(false);
  }

  useEffect(() => {
    let _searchText = searchText.toLowerCase().trim().replace(" ", "");
    // 검색어와 일치하는 리스트 반환
    if (_searchText === '') {
      setFilteredCustomerList(customerList);
    } else {
      setFilteredCustomerList(
          customerList.filter((store) =>
              store.storeName.toLowerCase().replace(" ", "").trim().includes(_searchText))
      );
    }
  }, [searchText, customerList]);

  const handleSelectCustomer = (userId) => {
    // <-- 체크박스 핸들링 -->
    setSelectedCustomerList((prevList) => {
      let _findItem = prevList.find((item) => item.userId === userId);
      if (_findItem !== undefined) {
        // 이미 선택되어있으면 빼기
        // return prevList.filter(item => item !== _findItem);
      } else {
        let _findItem = customerList.find((item) => item.userId === userId);

        if (_findItem !== undefined) {
          // 선택 안된 고객이면 추가
          return [...prevList, _findItem].sort((d1, d2) => {
            // 이름 정렬
            return d1.userName.localeCompare(d2.userName);
          });
        }
        return prevList;
      }
    });
  };

  const removeSelectCustomer = (store) => {
    // <-- 선택해제 -->
    setSelectedCustomerList((prevList) => prevList.filter(item => item.userId !== store.userId))
  }

  const handleSelectAll = () => {
    if (selectedCustomerList.length === customerList.length) {
      setSelectedCustomerList([]);
    } else {
      setSelectedCustomerList([...customerList]);
    }
  }

  const sendDirectMessageToCustomer = () => {
    if (selectedCustomerList.length === 0) {
      toast.warning("고객을 선택해 주세요.")
      return
    }
    let idList=[];

    for(let user of selectedCustomerList){
        if(idList.includes(user.userId))
          continue;
       idList.push(user.userId);
    }
    getFetcher().post(SEND_DIRECT_MESSAGE_TO_CUSTOMER, {
      user: accountInfo.makeJson(),
      userIdList: idList,
      senderDisplayName: "#storeName",
      message: messageContents,
      sendTime: new Date()
    }).then(res => {
      const {type, message} = res.data.header;
      if (type === 'success') {
        toast.success(message);
        // closeModal();
      } else {
        toast.success(message);
      }
    }).catch(err => console.log(err));

    // if (selectedCustomerList.length > 0) {
    //   neverLandDirectMessage.sendMessageToCustomers(selectedCustomerList, messageContents);
    //   toast.info("쪽지를 발송하였습니다.")
    // }
  }

  return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                    className="relative transform overflow-hidden rounded-lg bg-white mt-16 sm:mt-0 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[36rem] sm:p-6">

                  <p className="mt-2 mb-5 text-center text-xl font-semibold leading-6 text-gray-900"> 쪽지 보내기 </p>
                  <div className="flex flex-col sm:flex-row">

                    {/* 메세지 보내는 영역 */}
                    <div className="w-full md:w-1/2 bg-amber-200 px-5 flex flex-col h-full">
                      <p className="mt-3 text-center text-lg font-semibold leading-6 text-gray-900"> 메세지 입력 </p>

                      <div className="flex flex-col flex-grow mt-3">
                        <textarea value={messageContents}
                                  onChange={(e) => setMessageContents(e.target.value)}
                                  spellCheck="false"
                                  className="p-3 h-32 md:min-h-[18rem] flex-grow"
                        />

                        <div className="flex flex-row items-center justify-end my-2">
                          <button
                              onClick={sendDirectMessageToCustomer}
                              className="ml-2 inline-flex justify-center rounded-md bg-blue-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 whitespace-nowrap"
                          >
                            보내기
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* 메세지 보낼 고객 선택 */}
                    <div className="w-full md:w-1/2 px-5">
                      <p className="mt-3 text-center text-lg font-semibold leading-6 text-gray-900"> 고객 선택 </p>

                      <div className="flex flex-col justify-center mt-3">
                        <div className="flex flex-col">
                          <input
                              onChange={(e) => setSearchText(e.target.value)}
                              onFocus={(e) => e.target.select()}
                              placeholder="이름 검색"
                              spellCheck="false"
                              value={searchText}
                              className="block w-full pl-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                          />

                          <div className="flex flex-row items-center mt-2 cursor-pointer"
                               onClick={handleSelectAll}
                          >
                            <input type="checkbox"
                                   checked={selectedCustomerList.length === customerList.length}
                                   className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mr-2"
                            />
                            <p className="text-sm font-semibold text-blue-700"> 전체 선택 </p>
                          </div>
                        </div>

                        <div className="flex flex-row flex-wrap my-2">
                          {/*선택된 고객들*/}
                          {selectedCustomerList.length === customerList.length ?
                              <span
                                  className="my-1 mr-2 flex flex-row justify-center rounded-md bg-blue-300 font-semibold px-1.5 py-1 text-sm shadow-sm whitespace-nowrap items-center">
                                전체 고객({customerList.length} 명)
                              </span> :
                              selectedCustomerList.map((customer) => {
                                return (
                                    <button
                                        onClick={() => removeSelectCustomer(customer)}
                                        className="my-1 mr-2 flex flex-row justify-center rounded-md bg-yellow-300 font-semibold px-1.5 py-1 text-sm shadow-sm whitespace-nowrap items-center"
                                    >
                                      <p className="text-sm">{customer.userName}</p>
                                      <RiCloseLine className="ml-1 h-4 w-4 text-black"/>
                                    </button>
                                )
                              })}
                        </div>

                        <div
                            className="flex flex-col mt-1 min-h-[12.2rem] max-h-[12.2rem] overflow-y-scroll">
                          {/*전체 고객 리스트*/}
                          {filteredCustomerList.map((customer) => {
                            if (selectedCustomerList.find((item) => item.userId === customer.userId) !== undefined)
                              return <></>;
                            return (
                                <div className="flex flex-row items-center mb-2 cursor-pointer"
                                     onClick={() => handleSelectCustomer(customer.userId)}>
                                  <input type="checkbox"
                                         checked={selectedCustomerList.find((item) => item.userId === customer.userId) !== undefined}
                                         className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mr-2"
                                  />
                                  <p className="text-sm"> {customer.userName} </p> &nbsp;
                                  {customer.memoForStore && <p className="text-sm"> ({customer.memoForStore}) </p>}
                                </div>
                            )
                          })}
                        </div>

                        <div className="flex flex-row items-center justify-end mt-4">
                          <button
                              onClick={closeModal}
                              className="ml-2 inline-flex justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm whitespace-nowrap"
                          >
                            닫기
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
  )
}
export default SendDirectMessageModal;
