import Sidebar from "../../components/Sidebar";
import React, {useEffect, useState} from "react";
import AdminPageHeader from "../../components/Header/AdminPageHeader";
import {GAME_MANAGEMENT_TABLE_HEADER} from "../../constants/component_data";
import {game_management_table_body} from "../../utils/_dummyData";
import ListSelectBox from "../../components/ListSelectBox";
import neverLandUtils from "../../utils/NeverLandUtils";
import {getFetcher} from "../../utils/fetcher";
import {accountInfo} from "../../constants/constants";
import {
  ADMIN_GAME_MANAGEMENT_LIST_WITH_CURRENT_AMOUNT_API
} from "../../constants/api_constants";
import {getGameLogoImgURL} from "../../components/Card/GameCard";
import loading_img from "../../assets/images/icon-loading.png";
import {RiArrowDownSFill, RiArrowUpSFill, RiExpandUpDownFill, RiLockFill} from "react-icons/ri";
import GameManagementCard from "../../components/Card/GameManagementCard";
import GameManagementModal from "../../components/Modal/GameManagementModal";

const state = [
  {name: '상태전체'},
  {name: '발매전'},
  {name: '발매중'},
  {name: '발매종료'},
  {name: '게임종료'},
  {name: '결과정산'},
]

const category = [
  {name: '유형전체'},
  {name: '일반'},
  {name: '핸디캡'},
  {name: '언더오버'},
  {name: '승일패'},
  {name: '승오패'},
]

const gameinfo = {
  round: [
    {name: '현재 회차', value: 'current'},
  ],
  event: [
    {name: '종목전체'},
    {name: '축구', filter: 'SC'},
    {name: '농구', filter: 'BK'},
    {name: '배구', filter: 'VL'},
    {name: '야구', filter: 'BS'},
  ],
  league: [
    {name: '리그전체'},
    {name: 'KBL'},
    {name: 'KOVO남'},
    {name: '남축INTL'},
    {name: 'NBA'},
    {name: '축월드예'},
    {name: 'KBO'},
    {name: 'WKBL'},
    {name: 'KOVO여'},
    {name: 'U네이션'},
    {name: '유로예선'},
  ],
  amount: [
    {name: '금액전체'},
    {name: '배당금있음', value: 1},
    {name: '직접입력'},
    // {name: '1,000,000', value: 1000000},
    // {name: '1,500,000', value: 1500000},
  ]
}

const GameManagementPage = () => {
  const [selectedRound, setSelectedRound] = useState(gameinfo.round[0]); // 회차
  const [selectedState, setSelectedState] = useState(state[0]); // 상태
  const [selectedCategory, setSelectedCategory] = useState(category[0]); // 유형
  const [selectedEvent, setSelectedEvent] = useState(gameinfo.event[0]); // 종목
  const [selectedLeague, setSelectedLeague] = useState(gameinfo.league[0]); // 리그
  const [selectedAmount, setSelectedAmount] = useState(gameinfo.amount[0]); // 금액
  const [typingAmount, setTypingAmount] = useState(''); // 직접입력 금액
  const [currentAlignData, setCurrentAlignData] = useState({});
  const [showManagementModal, setShowManagementModal] = useState({isOpen: false, gameData: null});
  const [isOngoingShow, setIsOngoingShow] = useState(false); // 진행중인 경기만 보기
  const [isLoading, setIsLoading] = useState(null); // 로딩중
  const [hasAmount, setHasAmount] = useState(false); // 배당유무

  const [gameList, setGameList] = useState([]);
  const [refreshTime, setRefreshTime] = useState(new Date());

  useEffect(() => {
    if (selectedRound.value === isLoading)
      return;
    changeGameResult();
  }, [selectedRound]);

  const getGameRound = (roundData) => {
    // 회차정보
    let round = roundData.substring(7);
    return roundData.substring(5, 7) + "년도 " + ((round.startsWith('0') ? round.substring(1) : round)) + " 회차";
  }

  function _sortList(_list) {
    // 데이터 금액기준으로 정렬
    let temp;
    let unsorted = true;
    if (temp = currentAlignData["승배당"]) {
      if (temp === 'asc') {
        _list.sort((d1, d2) => {
          return d1.winCurrentAmount - d2.winCurrentAmount;
        });
        unsorted = false;
      }
      if (temp === 'desc') {
        _list.sort((d1, d2) => {
          return d2.winCurrentAmount - d1.winCurrentAmount;
        });
        unsorted = false;
      }
    }
    if (temp = currentAlignData["무배당/기준"]) {
      if (temp === 'asc') {
        _list.sort((d1, d2) => {
          return d1.drawCurrentAmount - d2.drawCurrentAmount;
        });
        unsorted = false;
      }
      if (temp === 'desc') {
        _list.sort((d1, d2) => {
          return d2.drawCurrentAmount - d1.drawCurrentAmount;
        });
        unsorted = false;
      }
    }
    if (temp = currentAlignData["원정배당"]) {
      if (temp === 'asc') {
        _list.sort((d1, d2) => {
          return d1.awayCurrentAmount - d2.awayCurrentAmount;
        });
        unsorted = false;
      }
      if (temp === 'desc') {
        _list.sort((d1, d2) => {
          return d2.awayCurrentAmount - d1.awayCurrentAmount;
        });
        unsorted = false;
      }
    }
    if (unsorted) {
      _list.sort((d1, d2) => {
        return d1.order_index - d2.order_index;
      });
      return _list;
    }
    return _list;
  }

  const changeGameResult = () => {
    setIsLoading(null);
    // 경기관리 데이터 가져오기
    getFetcher().get(accountInfo.makeGetRequest(ADMIN_GAME_MANAGEMENT_LIST_WITH_CURRENT_AMOUNT_API) + '&round=' + selectedRound.value)
        .then(res => {
          const {header, result, custom} = res.data;
          let _list = result.list;
          let _league = [];
          let temp;

          for (let item of _list) {
            if (!item.event.includes('/'))
              continue;
            temp = item.event.split('/')[1].trim();
            if (_league.find((item) => item.name === temp))
              continue;
            _league.push({name: temp});
          }
          _league.sort();
          _league.unshift({name: '리그전체'});
          gameinfo.league = _league;

          let order_index = 0
          for (let item of _list) {
            item.order_index = order_index++;
          }
          setGameList(_sortList(_list));

          let newSelection = _league.find((item) => item.name === selectedLeague.name);
          if (!newSelection) {
            setSelectedLeague(_league[0]);
          } else {
            setSelectedLeague(newSelection);
          }

          let currentRound = selectedRound;
          setRefreshTime(new Date());
          if (custom !== undefined && custom.items !== undefined && custom.items.length > 0) {
            let tempName;
            for (let item of custom.items) {
              // 데이터에서 회차 셀렉트 리스트 받아와서 설정
              if (item.key === 'rounds') {
                let newRoundList = [];
                newRoundList.push(gameinfo.round[0]);

                let index = 2;
                for (let round of item.value) {
                  tempName = getGameRound(round).trim();
                  if (tempName.length === 0) continue;
                  newRoundList.push({id: index++, name: tempName, value: round});
                }
                if (newRoundList.length > 0) {
                  gameinfo.round = newRoundList;
                  setSelectedRound(currentRound = newRoundList[1]);
                }
              }
            }
          }
          setIsLoading(currentRound.value);
        }).catch(err => {
      //toast.error(err.message);
      setGameList(game_management_table_body);
    });
  }

  function makeAlignData(name) {
    // 정렬
    let prevData = currentAlignData[name];
    if (prevData === undefined || prevData === "-") {
      currentAlignData[name] = "desc";
    } else if (prevData === "desc") {
      currentAlignData[name] = "asc";
    } else if (prevData === "asc") {
      currentAlignData[name] = "-";
    }
    let newAlignData = {...currentAlignData};
    setCurrentAlignData(newAlignData);
    setGameList(_sortList(gameList));
  }

  const onClickManagementButton = (gameData, selectedGameInfoCode) => {
    // <-- 모달에 게임데이터 넘기기 -->
    // 선택한 gameInfoCode와 같은 항목들 필터링
    const filteredData = gameList.filter(data => data.gameInfoCode === selectedGameInfoCode);

    // 필터링된 데이터 중 gameNo가 가장 작은 항목을 찾기
    const smallestGameNoData = filteredData.reduce((smallest, current) => {
      const smallestGameNo = parseInt(smallest.gameNo.replace(/[^\d]/g, ''), 10);
      const currentGameNo = parseInt(current.gameNo.replace(/[^\d]/g, ''), 10);
      return currentGameNo < smallestGameNo ? current : smallest;
    });

    // 가장 작은 항목의 게임스코어로 바꿔서 넘기기
    const {homeScore, awayScore, sumScore} = smallestGameNoData;
    const updatedData = {...gameData, homeScore, awayScore, sumScore};

    setShowManagementModal({
      isOpen: true,
      gameData: updatedData,
    });
  }

  return (
      <div>
        {showManagementModal.isOpen && <GameManagementModal setShowManagementModal={setShowManagementModal}
                                                            gameData={showManagementModal.gameData}/>}
        <Sidebar/>
        <div className="lg:pl-64">
          <AdminPageHeader/>
          <main>
            {isLoading === null ?
                <div className="flex flex-col justify-center items-center mt-80">
                  <img src={loading_img} className="h-16" alt="로딩 중 이미지"/>
                  <p className="text-indigo-900 font-semibold text-xl mt-10"> 데이터를 불러오는 중입니다.</p>
                </div>
                :
                <div>
                  {/* <-- 검색 조건 영역 --> */}
                  <div
                      className="sticky top-[4.6rem] lg:static bg-white flex flex-wrap flex-row justify-start px-2 lg:px-20 lg:mt-20 items-center">
                    <div className="flex flex-col md:flex-row">
                      <div className="flex flex-row flex-wrap">
                        <ListSelectBox defaultValue={selectedRound} listData={gameinfo.round}
                                       onChange={setSelectedRound}/>
                        <ListSelectBox listData={state} onChange={setSelectedState}/>
                        <ListSelectBox listData={category} onChange={setSelectedCategory}/>
                        <ListSelectBox listData={gameinfo.event} onChange={setSelectedEvent}/>
                        <ListSelectBox defaultValue={selectedLeague} listData={gameinfo.league}
                                       onChange={setSelectedLeague}/>
                        <ListSelectBox listData={gameinfo.amount} onChange={setSelectedAmount}/>
                      </div>
                      <div>
                        {selectedAmount.name === '직접입력' &&
                            <input
                                placeholder="금액 입력"
                                onChange={(e) => setTypingAmount(e.target.value)}
                                className="w-full lg:w-32 px-3 py-1.5 ring-1 ring-gray-300 rounded-3xl focus:ring-indigo-500 mr-2 mt-2 lg:mt-0"
                            />
                        }
                      </div>
                    </div>
                    <div className="flex flex-row items-center flex-wrap my-3 lg:my-0">
                      <button onClick={changeGameResult}
                              className="whitespace-nowrap rounded-lg px-3 py-1 bg-blue-700 text-sm text-white hover:bg-blue-600 ml-3 flex flex-row items-center"
                      >
                        <p>갱신 &nbsp; : &nbsp;</p>
                        <p className="text-xs">{neverLandUtils.utils.getDisplayTimeText(refreshTime)}</p>
                      </button>

                      <div className="flex flex-row justify-center ring-2 ring-gray-900 rounded-lg ml-3">
                        <button
                            onClick={() => setIsOngoingShow(false)}
                            type="button"
                            className={neverLandUtils.classNames(isOngoingShow ? "bg-gray-100 text-gray-900" : "bg-gray-900 text-white", "whitespace-nowrap rounded-lg px-1.5 py-1 text-xs font-semibold shadow-sm")}
                        >
                          전체보기
                        </button>
                        <button
                            onClick={() => setIsOngoingShow(true)}
                            type="button"
                            className={neverLandUtils.classNames(isOngoingShow ? "bg-gray-900 text-white" : "bg-gray-100 text-gray-900", "whitespace-nowrap rounded-lg px-1.5 py-1 text-xs font-semibold shadow-sm")}
                        >
                          진행중
                        </button>
                      </div>
                      {/*<div className="mt-3 lg:mt-0 flex h-6 items-center text-center font-semibold">*/}
                      {/*  <input*/}
                      {/*      onClick={(e) => setHasAmount(e.target.checked)}*/}
                      {/*      type="checkbox"*/}
                      {/*      checked={hasAmount}*/}
                      {/*      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mr-2"*/}
                      {/*  />*/}
                      {/*  <span>배당유무</span>*/}
                      {/*</div>*/}
                    </div>
                  </div>

                  {/* <-- 테이블 영역 --> */}
                  <div className="h-full border-t border-white/10 py-3 sm:py-6 hidden xl:block">
                    <div className="sm:min-h-[41rem]">
                      <table className="min-w-full">
                        <thead className="sticky top-20 lg:top-14">
                        <tr>
                          {GAME_MANAGEMENT_TABLE_HEADER.map((data) => {
                            return (
                                <th scope="col" key={data.name}
                                    className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-white lg:table-cell bg-stone-900 border-t">
                                  <div className="flex items-center justify-center cursor-pointer"
                                       onClick={() => makeAlignData(data.name)}>
                                    <p>{data.name}</p>
                                    {data.useOrder ? (currentAlignData[data.name] !== undefined && currentAlignData[data.name] !== '-' ?
                                            (currentAlignData[data.name] === "desc" ?
                                                    <RiArrowDownSFill className="h-5 w-5 text-yellow-500 ml-1"/>
                                                    : <RiArrowUpSFill className="h-5 w-5 text-sky-500 ml-1"/>
                                            ) : <RiExpandUpDownFill className="h-4 w-4 text-gray-400 ml-1"/>
                                    ) : <></>
                                    }
                                  </div>
                                </th>
                            );
                          })}
                        </tr>
                        </thead>

                        <tbody>
                        {gameList.map((data, index) => {
                          // 수정부분 생기면 모바일 버전 화면(GameManagementCard)에도 같이 수정하기
                          let isFiltered = false;
                          if (selectedLeague.name !== "리그전체") {
                            if (!data.event.includes("/" + selectedLeague.name)) {
                              isFiltered = true;
                            }
                          }
                          if (selectedEvent.name !== "종목전체") {
                            if (!data.event.includes(selectedEvent.filter + "/")) {
                              isFiltered = true;
                            }
                          }
                          if (selectedCategory.name !== "유형전체") {
                            if (!data.category.includes(selectedCategory.name)) {
                              isFiltered = true;
                            }
                          }
                          if (selectedState.name !== "상태전체") {
                            if (!data.state.includes(selectedState.name)) {
                              isFiltered = true;
                            }
                          }
                          if (hasAmount) {
                            if (data.winCurrentAmount === 0 && data.drawCurrentAmount === 0 && data.awayCurrentAmount === 0) {
                              isFiltered = true;
                            }
                          }
                          if (selectedAmount.name !== "금액전체") {
                            if (data.winCurrentAmount < selectedAmount.value && data.drawCurrentAmount < selectedAmount.value && data.awayCurrentAmount < selectedAmount.value) {
                              isFiltered = true;
                            }
                          }
                          if (selectedAmount.name === "직접입력") {
                            if (data.winCurrentAmount < typingAmount && data.drawCurrentAmount < typingAmount && data.awayCurrentAmount < typingAmount) {
                              isFiltered = true;
                            }
                          }

                          if (isOngoingShow && data.gameResult !== '-') { // 진행중에 토글이면서 결과값이 안나온것만 필터
                            isFiltered = true;
                          }

                          if (isFiltered)
                            return null;

                          let category = data.event.substring(0, 2);

                          return (
                              <React.Fragment key={data.round}>
                                <tr key={data.round}
                                    className={neverLandUtils.classNames((data.winRate === 1 && data.awayRate === 1) ? "bg-yellow-100" : ""
                                        , "border-b border-stone-300 whitespace-nowrap text-sm hover:bg-blue-50 text-center")}
                                >
                                  <td>{data.gameNo}</td>
                                  <td>
                                    <div className="flex flex-row items-center justify-center">
                                      <img src={getGameLogoImgURL(category)}
                                           className={neverLandUtils.classNames(category === 'BS' ? "h-6 w-6" : "h-5 w-5", "mr-1")}/>
                                      {data.event}
                                    </div>
                                  </td>
                                  <td>{data.gameDate.substring(5, 16)}</td>
                                  <td>{data.category}</td>
                                  <td>{data.state}</td>
                                  <td>{data.team}</td>
                                  <td>
                                    <div className="flex justify-center mt-1 items-center">
                                      <div
                                          className={neverLandUtils.classNames(data.gameResult === 'win' ? "ring-2 bg-amber-300 ring-amber-400" : (data.gameResult === 'reject' ? "ring-2 bg-rose-700 ring-rose-800 text-white" : "bg-white ring-1 ring-gray-400")
                                              , "flex flex-row items-center justify-center font-semibold w-20 rounded-md py-1 text-gray-900 ring-inset")}>
                                        {data.winAdminLocked && <RiLockFill title="관리자 잠금"
                                                                            className="h-5 w-auto text-red-300 mr-1.5"/>}
                                        {data.winPublicLocked && <RiLockFill title="공식 잠금"
                                                                             className="h-5 w-auto text-black mr-1.5"/>}
                                        <p>{data.winRate}</p>
                                      </div>
                                    </div>

                                    <div>
                                      {data.winFixedAmount > 0 ?
                                          <p className="py-0.5 font-semibold text-xs text-gray-400">{Number(data.winFixedAmount).toLocaleString()}</p>
                                          : <p>&nbsp;</p>
                                      }
                                    </div>

                                    <div>
                                      {data.winCurrentAmount > 0 ?
                                          <p className="py-0.5 font-semibold text-blue-600">{Number(data.winCurrentAmount).toLocaleString()}</p>
                                          : <p>&nbsp;</p>
                                      }
                                    </div>
                                  </td>

                                  <td>
                                    <div className="flex flex-row justify-center mt-1">
                                      <div
                                          className={neverLandUtils.classNames(data.gameResult === 'draw' ? "ring-2 bg-amber-300 ring-amber-400" : (data.gameResult === 'reject' ? "ring-2 bg-rose-700 ring-rose-800 text-white" : "bg-white ring-1 ring-gray-400")
                                              , "flex flex-row items-center justify-center font-semibold w-20 rounded-md py-1 text-gray-900 ring-inset")}>
                                        {data.drawAdminLocked &&
                                            <RiLockFill title="관리자 잠금" className="h-5 w-auto text-red-300 mr-1.5"/>}
                                        {data.drawPublicLocked &&
                                            <RiLockFill title="공식 잠금" className="h-5 w-auto text-black mr-1.5"/>}
                                        <p>{data.drawRate}</p>
                                      </div>

                                      <div
                                          className="bg-white text-teal-600 font-semibold w-20 rounded-md py-1 ring-1 ring-inset ring-gray-400 ml-1">
                                        {data.criteria}
                                      </div>
                                    </div>

                                    <div>
                                      {data.drawFixedAmount > 0 ?
                                          <p className="py-0.5 font-semibold text-xs text-gray-400">{Number(data.drawFixedAmount).toLocaleString()}</p>
                                          : <p>&nbsp;</p>
                                      }
                                    </div>
                                    <div>
                                      {data.drawCurrentAmount > 0 ?
                                          <p className="py-0.5 font-semibold text-blue-600">{Number(data.drawCurrentAmount).toLocaleString()}</p>
                                          : <p>&nbsp;</p>
                                      }
                                    </div>
                                  </td>

                                  <td>
                                    <div className="flex justify-center mt-1">
                                      <div
                                          className={neverLandUtils.classNames(data.gameResult === 'away' ? "ring-2 bg-amber-300 ring-amber-400" : (data.gameResult === 'reject' ? "ring-2 bg-rose-700 ring-rose-800 text-white" : "bg-white ring-1 ring-gray-400")
                                              , "flex flex-row items-center justify-center font-semibold w-20 rounded-md py-1 text-gray-900 ring-inset")}>
                                        {data.awayAdminLocked && <RiLockFill title="관리자 잠금"
                                                                             className="h-5 w-auto text-red-300 mr-1.5"/>}
                                        {data.awayPublicLocked && <RiLockFill title="공식 잠금"
                                                                              className="h-5 w-auto text-black mr-1.5"/>}
                                        <p>{data.awayRate}</p>
                                      </div>
                                    </div>

                                    <div>
                                      {data.awayFixedAmount > 0 ?
                                          <p className="py-0.5 font-semibold text-xs text-gray-400">{Number(data.awayFixedAmount).toLocaleString()}</p>
                                          : <p>&nbsp;</p>}
                                    </div>
                                    <div>
                                      {data.awayCurrentAmount > 0 ?
                                          <p className="py-0.5 font-semibold text-blue-600">{Number(data.awayCurrentAmount).toLocaleString()}</p>
                                          : <p>&nbsp;</p>}
                                    </div>
                                  </td>

                                  <td>
                                    <div className="flex justify-center mb-9">
                                      <p className="bg-white ring-1 ring-gray-400 font-semibold w-16 rounded-md py-1 text-gray-900 ring-inset">
                                        {data.homeScore}
                                      </p>
                                    </div>

                                    {/*{(data.sumScore && data.sumScore !== '-' && data.category === "언더오버") ?*/}
                                    {/*    <div className="text-center font-semibold mt-1">*/}
                                    {/*      {data.sumScore > data.criteria ?*/}
                                    {/*          <p className="text-green-600">스코어 : {data.sumScore}</p>*/}
                                    {/*          : <p className="text-red-600">스코어 : {data.sumScore}</p>*/}
                                    {/*      }*/}
                                    {/*    </div>*/}
                                    {/*    :*/}
                                    {/*    <div className="mb-10"></div>*/}
                                    {/*}*/}
                                  </td>

                                  <td>
                                    <div className="flex justify-center mb-9">
                                      <p className="bg-white ring-1 ring-gray-400 font-semibold w-16 rounded-md py-1 text-gray-900 ring-inset">
                                        {data.awayScore}
                                      </p>
                                    </div>
                                  </td>

                                  <td>
                                    {accountInfo.getAdminLevel() === 'super' &&
                                        <button
                                            className="mt-1 rounded-lg px-3 py-1 bg-blue-700 text-white hover:bg-blue-600 mr-2"
                                            onClick={() => onClickManagementButton(data, data.gameInfoCode)}
                                        >
                                          관리
                                        </button>
                                    }
                                  </td>
                                </tr>
                              </React.Fragment>
                          )
                        })}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* <-- 화면 작을 때 카드형식 --> */}
                  <div className="xl:hidden flex justify-center">
                    <GameManagementCard gameList={gameList} setGameList={setGameList} _sortList={_sortList}
                                        selectedLeague={selectedLeague} selectedEvent={selectedEvent}
                                        selectedCategory={selectedCategory} selectedState={selectedState}
                                        selectedAmount={selectedAmount} isOngoingShow={isOngoingShow}
                                        hasAmount={hasAmount} typingAmount={typingAmount}
                    />
                  </div>
                </div>}
          </main>
        </div>
      </div>
  )
}
export default GameManagementPage;
