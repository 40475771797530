import {REPORT_UPPER_TABLE_HEADER} from "../../constants/component_data";
import React from "react";

const ReportUpperTable = ({upperTableBodyData, tableHeader}) => {
  const conversionValues = Object.values(upperTableBodyData); //객체의 값들만 모아 배열로 반환

  return (
      <table className="min-w-full divide-y divide-stone-300 ">
        <thead>
        <tr>
          {tableHeader.map((data) => {
            return (
                <th scope="col"
                    key={data.name}
                    className="py-2 text-center text-sm font-semibold text-white lg:table-cell bg-stone-900 whitespace-nowrap">
                  {data.name}
                </th>
            );
          })}
        </tr>
        </thead>
        <tbody className="divide-y divide-stone-300 bg-white">
        <tr>
          {conversionValues.map((data, index) => (
              <td key={index}
                  className="bg-white py-2 pl-4 pr-3 text-center text-sm font-medium text-stone-900 sm:pl-4 border-b border-stone-900">
                {data.toLocaleString()}
              </td>
          ))}
        </tr>
        </tbody>
      </table>
  )
}
export default ReportUpperTable;