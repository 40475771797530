import neverLandUtils from "../utils/NeverLandUtils";
import neverLandGameData from "../utils/NeverLandGameData";

export const USER_INFO = 'ui*'
export const USER_CONFIG = 'uc'

let _thisConfig = null;

function _getResourcePath(_url) {
  let currentUrl = window.location.href;

  if (currentUrl.includes('.com')) {
    const newUrl = new URL(_url);
    newUrl.hostname = currentUrl.replace('http://', '').replace('https://', '').split("/")[0]; // 도메인 부분만 대체

    _url = newUrl.href;
  }
  return _url;
}

class MyAccountInfo {
  //로컬스토리지 저장되어있는 정보 가져오기
  isLogin() {
    return neverLandUtils.commons.load(USER_INFO)?.userId;
  }

  isStore() {
    let currentInfo = neverLandUtils.commons.load(USER_INFO);
    if (currentInfo)
      return currentInfo.isStore;
    return false;
  }

  isAdmin() {
    let currentInfo = neverLandUtils.commons.load(USER_INFO);
    if (currentInfo?.userTag?.includes("#admin"))
      return currentInfo.isAdmin;
    return false;
  }

  getAdminLevel() {
    let currentInfo = neverLandUtils.commons.load(USER_INFO);
    if (currentInfo?.userTag?.includes("#admin"))
      return currentInfo.adminLevel || 'normal';
    return 'normal';
  }

  accountId() {
    return neverLandUtils.commons.load(USER_INFO)?.userId || -1;
  }

  accountNickName() {
    return neverLandUtils.commons.load(USER_INFO)?.nickName || -1;
  }

  accountSessionCode() {
    return neverLandUtils.commons.load(USER_INFO)?.sessionCode || "none";
  }

  getStoreCode() {
    let addData = neverLandUtils.commons.load(USER_INFO)?.additionalData || [];
    if (addData.length === 0)
      return '?';

    for (let data of addData) {
      if (data.name === 'storeCode') {
        return data.value;
      }
    }
    return '?';
  }

  getDefaultStore() {
    return neverLandUtils.commons.load(USER_INFO)?.defaultStore;
  }

  makeGetRequest(api) {
    return api + `?userId=${this.accountId()}&sessionCode=${this.accountSessionCode()}`;
  }

  makeJson() {
    return neverLandUtils.commons.load(USER_INFO);
  }


  login(userInfo) {
    // if(Environment.isTest) {
    if (userInfo.userTag) {
      if (userInfo.userTag.includes('#store')) {
        userInfo.isStore = true;
      }
      if (userInfo.userTag.includes('#admin')) {
        userInfo.isAdmin = true;
        let index = userInfo.userTag.indexOf('#admin:');
        if (index > 5) {
          let level = userInfo.userTag.substring(index + 7);
          userInfo.adminLevel = level.split('#')[0].trim();
        } else {
          userInfo.adminLevel = "normal";
        }
      }


    }


    if (userInfo.additionalData && userInfo.additionalData.length > 0) {
      for (let data of userInfo.additionalData) {
        if (data.name === 'myDefaultStoreCode') {
          userInfo.defaultStore = data.value;
        }
        if (data.name === 'myDefaultStoreState') {
          userInfo.isDefaultStoreConfirmed = data.value === "confirmed";
        }
      }
    }
    neverLandUtils.commons.save(USER_INFO, userInfo);

    neverLandGameData.reloadGameData();
  }

  getProfileImage() {
    let id = accountInfo.accountId();

    if (id === -1) {
      return _getResourcePath("http://teamneverland.com/resources/img/user-profiles/00001.png");
    }
    id = id % 3;
    if (id === 0) {
      return _getResourcePath("http://teamneverland.com/resources/img/user-profiles/00002.png");
    }
    if (id === 1) {
      return _getResourcePath("http://teamneverland.com/resources/img/user-profiles/00003.png");
    }
    if (id === 2) {
      return _getResourcePath("http://teamneverland.com/resources/img/user-profiles/00004.png");
    }
    return _getResourcePath("https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80");
  }


  activateUsePrinting() {
    neverLandUtils.commons.save("activateUsePrinting", true);
  }

  usePrinting() {
    return neverLandUtils.commons.load("activateUsePrinting") === true;
  }


  getUserConfig() {
    if (_thisConfig === null) {
      _thisConfig = neverLandUtils.commons.load(USER_CONFIG);

      if (_thisConfig === null) {
        _thisConfig = {
          options: {},
          isOn: (key, defaultValue) => {
            if (_thisConfig) {
              return _thisConfig.options !== undefined && _thisConfig.options[key] !== undefined ? _thisConfig.options[key] : defaultValue;
            }
            return defaultValue
          },
          setOptionOn: (key, newValue) => {
            if (_thisConfig) {
              if (_thisConfig.options === undefined) _thisConfig.options = {};
              _thisConfig.options[key] = newValue;
              this.storeUserConfig();

            }
            return newValue;
          }
        };
        this.storeUserConfig();
      } else {
        _thisConfig.isOn = (key, defaultValue) => {
          if (_thisConfig) {
            return _thisConfig.options !== undefined && _thisConfig.options[key] !== undefined ? _thisConfig.options[key] : defaultValue;
          }
          return defaultValue
        };
        _thisConfig.setOptionOn = (key, newValue) => {
          if (_thisConfig) {
            if (_thisConfig.options === undefined) _thisConfig.options = {};
            _thisConfig.options[key] = newValue;
            this.storeUserConfig();

          }
          return newValue;
        };
      }
    }
    // TODO : DB에서 회원설정 생성해서 백엔드 RESTFUL로 정보 가져오기가 필요할까? 차후 고민하기 (로그인할때?)
    return _thisConfig;
  }

  updateDefaultStoreCode(storeCode) {
    let userInfo = neverLandUtils.commons.load(USER_INFO);
    userInfo.defaultStore = storeCode;
    neverLandUtils.commons.save(USER_INFO, userInfo);
  }

  isDefaultStoreConfirmed() {
    return neverLandUtils.commons.load(USER_INFO).isDefaultStoreConfirmed;
  }

  storeUserConfig() {
    neverLandUtils.commons.save(USER_CONFIG, _thisConfig);
  }

  getAdditionalData(key, defaultValue) {
    let userInfo = neverLandUtils.commons.load(USER_INFO);
    if (userInfo.additionalData && userInfo.additionalData.length > 0) {
      for (let data of userInfo.additionalData) {
        if (data.name === key) {
          return data.value;
        }
      }
    }
    return defaultValue;
  }

  isExtServiceUsable(serviceName) {
    let storeLevel = this.getAdditionalData('storeLevel', '0');

    if (storeLevel === '0' || storeLevel === '1' || storeLevel === 'null' || storeLevel === null)
      return false;
    return true;
  }

  getExtServiceName(serviceName) {
    if (serviceName === 'bona')
      return '보나스포츠(제휴업체)';
    return '알수없음';
  }

  extServiceAccount(serviceName) {
    let accountName = this.getAdditionalData(serviceName + ".accountName", '보나이메일/TEST');
    return '가입완료(ID:' + accountName + ')';
  }

  saveLastNavigation(path) {
    // <-- 마지막 페이지 위치 저장 -->
    if (path) {
      if (path.includes("excel_load"))
        return;
      if (path.includes("/resources/"))
        return;
    }
    neverLandUtils.commons.save("last_path", path);
  }

  loadLastNavigation() {
    // <-- 마지막 페이지 위치 가져오기 -->
    return neverLandUtils.commons.load("last_path");
  }

  getAlignDataList(key) {
    let value = neverLandUtils.commons.load("alignData");

    if (!value)
      return {};
    value = value[key];
    if (value)
      return value;
    return {};
  }

  saveAlignData(key, alignData) {
    let value = neverLandUtils.commons.load("alignData");
    if (!value) {
      value = {};
    }
    value[key] = alignData;

    neverLandUtils.commons.save("alignData", value);
  }

  getStoreLevel() {
    return this.getAdditionalData("storeLevel", -1);
  }

  isMonitoringAdminMode() {
    return neverLandUtils.commons.has("**ma");
  }

  saveMonitoringAdmin() {
    neverLandUtils.commons.save("**ma", neverLandUtils.commons.load(USER_INFO));
  }

  rollbackMonitoringAdmin() {
    let admin = neverLandUtils.commons.load("**ma");
    neverLandUtils.commons.save("**ma", null);
    this.login(admin);
  }

  isSpecial() {
    let user = neverLandUtils.commons.load(USER_INFO);
    if (user) {
      if (user.userLevel === null) return false;
      return user.userLevel.includes('special');
    }
    return false;
  }

  isDeveloper() {
    let user = neverLandUtils.commons.load(USER_INFO);
    if (user) {
      if (user.userLevel === null) return false;
      return user.userLevel.includes('dev');
    }
    return false;
  }
}

const image_resource_urls = {
  // 텍스트 로고
  'textLogoBlack': "http://teamneverland.com/resources/img/logo/plogo01.png",
  'textLogoBlack_animate': "http://teamneverland.com/resources/img/logo/plogo01a.gif",
  'textLogoGray': "http://teamneverland.com/resources/img/logo/plogo_gray.png",
  'textLogoBlue': "http://teamneverland.com/resources/img/logo/plogo02.png",
  'textLogoWhite': "http://teamneverland.com/resources/img/logo/plogo03.png",
  // 고래 이미지 로고
  'whaleLogoBlue': "http://teamneverland.com/resources/img/logo/logo_middle_blue.png",
  'whaleLogoWhite': "http://teamneverland.com/resources/img/logo/logo_middle_white.png",
  'whaleLogoMix': "http://teamneverland.com/resources/img/logo/logo_middle_mix.png",
  // 티켓 사이드 텍스트
  'ticketSide': "http://teamneverland.com/resources/img/ticket/ticket_side.png",
  'ticketSideGray': "http://teamneverland.com/resources/img/ticket/ticket_side_expired.png",
  'ticketSideOrange': "http://teamneverland.com/resources/img/ticket/ticket_side_orange.png",
  // 티켓 배경 이미지
  'ticketNormal': "http://teamneverland.com/resources/img/ticket/ticket_normal_02.png",
  'ticketWin': "http://teamneverland.com/resources/img/ticket/ticket_win.png",
  'ticketLose': "http://teamneverland.com/resources/img/ticket/ticket_lose.png",
  'ticketExpired': "http://teamneverland.com/resources/img/ticket/ticket_expired.png",
  'ticketOrange': "http://teamneverland.com/resources/img/ticket/ticket_normal_orange.png",
};

export function getLogoImage(shape) {
  let currentUrl = window.location.href;
  let _url = image_resource_urls[shape];

  if (currentUrl.includes('.com')) {
    const newUrl = new URL(_url);
    newUrl.hostname = currentUrl.replace('http://', '').replace('https://', '').split("/")[0]; // 도메인 부분만 대체

    _url = newUrl.href;
  }

  if (_url !== undefined) {
    return _url;
  }
}

export const today = new Date();
export const month = today.getMonth();
export const day = today.getDate();
export const yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1);

export const lastWeek = new Date(today);
lastWeek.setDate(today.getDate() - 7);

export const lastMonth = new Date(today);
lastMonth.setMonth(today.getMonth() - 1);

export const MOBYDICK_VERSION_URL = "/resources/current/version.txt";
export const MOBYDICK_ADMIN_TEST_LIST_URL = "/resources/documents/admin_test_list.txt"

export const accountInfo = new MyAccountInfo();
