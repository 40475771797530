import {Fragment} from 'react'
import {Transition} from '@headlessui/react'
import {XMarkIcon} from '@heroicons/react/20/solid'

const TicketNotification = ({showNotificationJson, onClose}) => {

  return (
      <>
        <div className="inset-0 flex items-end justify-center sm:items-start py-4">
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
            <Transition
                show={showNotificationJson.isVisible}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
              <div
                  className="absolute pointer-events-auto w-full max-w-sm rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
                <div
                    className="w-full max-w-sm rounded-lg bg-white shadow-md ring-1 ring-gray-600 ring-opacity-5">
                  <div className="p-2">
                    <div className="flex items-start">
                      <div className="ml-3 w-0 flex-1 pt-0.5 mt-1 text-sm text-gray-600">
                        <p>
                          ※ <span
                            className="text-red-500">{showNotificationJson.data.homeTeam.split(' ')[0].trim() + " vs " + showNotificationJson.data.awayTeam}</span>의 <br/>
                          배당률 또는 사전에 주어진 조건이 변경되었습니다.
                        </p>
                      </div>

                      <div className="ml-4 flex flex-shrink-0" onClick={onClose}>
                        <button
                            type="button"
                            className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          <span className="sr-only">Close</span>
                          <XMarkIcon className="h-5 w-5"/>
                        </button>
                      </div>
                    </div>

                    {/*테이블 본문 내용*/}
                    <div className="flex justify-center mt-3 text-sm">
                      <table className="border-y border-gray-400">
                        <thead className="bg-gray-100 divide-y divide-gray-300">
                        <tr className="border-b border-gray-400">
                          <th className="py-2 px-4 border-r">변경</th>
                          <th className="px-3 border-r">사전조건</th>
                          <th className="px-3 border-r">예상</th>
                          <th className="px-3 border-r">배당률</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr className="border-b border-gray-400">
                          <td className="py-1.5 px-5 border-r">현재</td>
                          <td className="px-3 border-r">{showNotificationJson.data.extraTextNew?showNotificationJson.data.extraTextNew:showNotificationJson.data.extraText}</td>
                          <td className="px-3 border-r">{showNotificationJson.data.prediction}</td>
                          <td className={
                              showNotificationJson.data.rateNew>showNotificationJson.data.rate?"px-3 border-r text-blue-600":"px-3 border-r text-red-600"}>
                                {showNotificationJson.data.rateNew>0?showNotificationJson.data.rateNew:showNotificationJson.data.rate}</td>
                        </tr>

                        <tr>
                          <td className="py-1.5 px-5 border-r">등록 시점</td>
                          <td className="px-3 border-r">{showNotificationJson.data.extraText}</td>
                          <td className="px-3 border-r">{showNotificationJson.data.prediction}</td>
                          <td className="px-3 border-r">{showNotificationJson.data.rate}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>

          </div>
        </div>
      </>
  )
}
export default TicketNotification;