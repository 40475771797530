import {Fragment, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'

const ChangeGameStateCancelModal = ({changeGameStateCancel, setRequestCancelModalOpen}) => {
  const [open, setOpen] = useState(true);
  const [description, setDescription] = useState('');

  const closeModal = () => {
    setOpen(false);
    setRequestCancelModalOpen(false);
  }

  return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                    className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <h3 className="text-xl font-semibold leading-6 text-red-600"> 취소 사유 입력 </h3>
                      <div className="mt-2 max-w-xl text-sm text-gray-500">
                        <p>취소 사유를 입력하세요.</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row justify-center mt-5 sm:mt-6">
                    <div className="sm:max-w-xs">
                      <input
                          onChange={(e) => setDescription(e.target.value)}
                          spellCheck="false"
                          value={description}
                          className="block w-full pl-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                      />
                    </div>

                    <div className="flex flex-row">
                      <button
                          onClick={() => {
                            closeModal();
                            changeGameStateCancel(description);
                          }}
                          className="whitespace-nowrap mt-3 inline-flex w-full items-center justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:mt-0 sm:w-auto"
                      >
                        의뢰취소
                      </button>
                      <button
                          onClick={closeModal}
                          type="submit"
                          className="whitespace-nowrap mt-3 inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-semibold text-gray-500 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 sm:ml-3 sm:mt-0 sm:w-auto"
                      >
                        닫기
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
  )
}
export default ChangeGameStateCancelModal;
