import {Link} from "react-router-dom";
import {HEADERS} from "../../constants/component_data";
import NeverlandNotification from "../NeverlandNotification";
import {accountInfo} from "../../constants/constants";
import React from "react";
import neverLandUtils from "../../utils/NeverLandUtils";


const MyPageHeader = () => {
  return (
      <div className="lg:fixed top-0 w-full border-b bg-stone-900 shadow-md z-30">
        <nav className="flex overflow-x-auto py-4">
          <ul
              role="list"
              className="flex min-w-full flex-none gap-x-10 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8"
          >
            {HEADERS.myInfoHeaderNavigation.map((item) => (
                <li key={item.name}>
                  <Link to={item.link}>
                    <div className={neverLandUtils.classNames(item.current ? 'text-indigo-400' : ' hover:text-indigo-400','flex flex-row')}>
                      <p className="mr-1.5">{item.name}</p>
                      {item.notifyKey &&
                          <NeverlandNotification programKey={'mobydick'} notifyKey={item.notifyKey}
                                                 accountKey={accountInfo.accountId()}/>
                      }
                    </div>
                  </Link>
                </li>
            ))}
          </ul>
        </nav>
      </div>
  )
}
export default MyPageHeader;